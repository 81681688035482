import styles from '../Preview/Preview.module.scss';
import Popup from '../Popup';
import closeJob from '../../../assets/images/closeOrg.svg';
import { useState } from 'react';
import { useEffect } from 'react';

const Preview = (props) => {
    const { isOpen, isClose, children, selectedPreviewFile, title, previewFileKey } = props;
    const [fileType, setFileType] = useState("")
    useEffect(() => {
        if (previewFileKey.match(/\.(jpeg|jpg|gif|png)$/) != null) {
            setFileType("Image")
        } else if (previewFileKey.match(/\.(pdf)$/) != null) {
            setFileType("pdf")
        } else {
            setFileType("video")
        }
        //setIsImage(previewFileKey.match(/\.(jpeg|jpg|gif|png)$/) != null)
    }, [fileType])
    return <Popup isOpen={isOpen} isClose={isClose} children={children}>
        <div className={styles.aspirFill}>
            <h3>{title}<span style={{ width: title.length + 10 + "%" }}></span></h3>
            <img src={closeJob} onClick={isClose} alt='CloseIcon' />
            <div className={styles.previewStyle}>
                {fileType === 'video' && <video width="320" height="240" controls autoPlay="true">
                    <source src={selectedPreviewFile} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>}
                {fileType === 'Image' && <img className={styles.image} src={selectedPreviewFile}></img>}
                {fileType === 'pdf' && <object data={selectedPreviewFile} type="application/pdf" width="300" height="200">
                    alt : <a href={selectedPreviewFile}>test.pdf</a>
                </object>}
            </div>
        </div>
    </Popup>
}

export default Preview;