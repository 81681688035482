import styles from './Aspiration.module.scss';
import Popup from '../Popup';
import closeJob from '../../../assets/images/closeOrg.svg';
import { useEffect, useState } from 'react';
import { getVisionTagsLists } from '../../../util/common';
import { selectedCandidateVision } from '../../../store/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { showLoader, hideLoader } from "../../../util/helper";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const AspireVisionPopup = (props) => {
    const { t } = useTranslation('common');
    const { isOpen, isClose, children, setVisionss } = props;

    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    const [visions, setVisions] = useState([]);
    const [visionsDup, setVisionsDup] = useState([]);
    const [isupdate, setIsupdate] = useState(false);
    const dispatch = useDispatch();
    const { candidateVision } = useSelector(state => state)
    const [selectedVision, setSelectedVision] = useState([]);
    const [useEffectCalled, setuseEffectcalled] = useState(false);

    const getBioList = async () => {
        try {
            showLoader();
            const response = await getVisionTagsLists();
            let bioArray = []
            response.map((result) => {
                bioArray.push(result.Vision_tags)
            })
            setVisions(bioArray);
            setVisionsDup(bioArray);
            hideLoader();
        } catch (e) {
            setVisions([]);
            setVisionsDup([]);
            hideLoader();
        }
    }
    useEffect(() => {
        if (!useEffectCalled) {
            // selectedCandidateBios(cvBioEncrypted)
            let getBios = JSON.parse(JSON.stringify(selectedVision));

            if (candidateVision && candidateVision.length !== 0) {
                getBios.push(...candidateVision);
            }
            //else {
            //     getBios.push(...cvVision);
            // }
            setSelectedVision(getBios);
            selectedCandidateVision(getBios)
            setuseEffectcalled(true)
        }

        if (visions && visions.length === 0) {
            getBioList();
        }

    }, [isupdate])

    // const selectBio = (val) => {
    //     let getBios = JSON.parse(JSON.stringify(selectedVision));
    //     getBios.push(val);

    //     setSelectedVision([...new Set(getBios)])
    // }
    const selectBio = (val) => {
        let getBios = JSON.parse(JSON.stringify(selectedVision));
    
        // Check if the limit of 1 vision is reached
        if (getBios.length < 1) {
            getBios.push(val);
            setSelectedVision([...new Set(getBios)]);
        } else {
            toast.error(`You can only add maximum  1 Vision..`);
        }

    }

    const doneBio = () => {
        var intersections = selectedVision.filter(e => selectedVision.indexOf(e) !== -1);
        dispatch(selectedCandidateVision(intersections));
        setVisionss(intersections);
        isClose();
    }

    const deleteBio = (item) => {
        let getBio = JSON.parse(JSON.stringify(selectedVision));
        var index = getBio.indexOf(item);
        if (index !== -1) {
            getBio.splice(index, 1);
        }
        dispatch(selectedCandidateVision(getBio));
        setSelectedVision(getBio);
        setVisionss(getBio);
        setIsupdate(!isupdate)
    }

    const searchFilter = (value) => {
        if (value.length !== 0) {
            const filterArray = JSON.parse(JSON.stringify(visionsDup));

            let searchArray = [];
            filterArray.map((ele) => {
                if (ele.toLowerCase().includes(value.toLowerCase())) {
                    searchArray.push(ele)
                }
            });
            setVisions(searchArray)
        } else {
            setVisions(visionsDup)
        }
    }


    const cancelpopup = () => {
        dispatch(selectedCandidateVision([]));
        isClose();
    }

    return <Popup isOpen={isOpen} isClose={isClose} children={children}>
        <div className={styles.aspirFill}>
            <h3>{t('aspireVisionPopup.title')} <span></span></h3>
            <img src={closeJob} onClick={() => cancelpopup()} alt='CloseImg' />
            <div className={styles.FormGroup}>
                <input className={styles.formControl} type="text" placeholder="Search…" onChange={e => searchFilter(e.target.value)} />
            </div>

            <div className={styles.yourVisi}>

                <div className={styles.spaciList}>
                    {visions.map((result, index) => {
                        if (selectedVision.includes(result)) {
                            return <span key={`vision${index}`} onClick={() => deleteBio(result)} style={{ border: "3px solid #ff6600" }}>{result}</span>
                        } else {
                            return <span key={`vision${index}`} onClick={() => selectBio(result)}>{result}</span>
                        }
                    })}
                </div>
                <button className={styles.doneButton} onClick={() => doneBio()}>{t('aspireVisionPopup.done')}</button>
            </div>
        </div>
    </Popup>
}

export default AspireVisionPopup;