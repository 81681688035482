import styles from './Aspiration.module.scss';
import Popup from '../Popup';
import closeJob from '../../../assets/images/close.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const JobPositionPopup = (props) => {
    const { t } = useTranslation('common');
    const { isOpen, isClose, children, specialization, setSpecializationData, positionRef } = props;

    const [selectedSpecialization, setSeletedSpecialization] = useState([]);
    const [update, setUpdate] = useState(false);
    const [callOnce, setCallOnce] = useState(false);
    useEffect(() => {
        if (!callOnce) {
            setSeletedSpecialization(specialization);
            setCallOnce(true)
        }
    }, [selectedSpecialization, update])
    const closePopup = () => {
        const selectedCount = selectedSpecialization.filter(spec => spec.isSelected).length;
    
        if (selectedCount >= 2 && selectedCount <= 5) {
            setSpecializationData(selectedSpecialization);
            isClose();
        } else {
            toast.error('Select between 2 and 5 specializations.');
        }
    };


    const cancelpopup = () => {
        positionRef.current.value = "";
        setSpecializationData([]);
        isClose();

    }

    const selectHandler = (index, from) => {
        const selectedCount = selectedSpecialization.filter(spec => spec.isSelected).length;
    
        if (from === 'add' && selectedCount >= 5) {
            toast.warning('You can select maximum 5 specializations.');
        } else {
            selectedSpecialization[index].isSelected = from === 'add' ? true : false;
            setSeletedSpecialization(selectedSpecialization);
            setUpdate(!update);
        }
    };

    const SpecilaizationList = () => {
        return <>{selectedSpecialization.map(({ value, isSelected }, index) => {
            if (isSelected) {
                return <span key={`specilaliz${index}`} style={{ border: "3px solid #ff6600" }} onClick={() => selectHandler(index, "remove")}>{value}</span>
            } else {
                return <span key={`specilaliz${index}`} onClick={() => selectHandler(index, "add")}>{value}</span>
            }
        })}
        </>
    }

    return <Popup isOpen={isOpen} isClose={isClose} children={children}>
        <div className={styles.aspirFill}>
            <img src={closeJob} onClick={() => cancelpopup()} alt='CloseImg' />
            <div className={styles.yourBio}>
                <lable>{t('SpecilaizationPopup.title')}</lable>
                {(selectedSpecialization && selectedSpecialization.length !== 0) && <div className={styles.spaciList}>
                    <SpecilaizationList />
                    {/* <span>Improve performance<img src={closeJob} onClick={() => { }} alt='' /></span>
                    <span>Earn promotions</span>
                    <span>Rewarding career</span>
                    <span>Certifications</span>
                    <span>Advance level</span> */}
                </div>}
            </div>
            <div className={styles.saveNext}>
                <button className={styles.next} onClick={() => closePopup()}>{t('SpecilaizationPopup.button')} </button>
            </div>
        </div>
    </Popup>
}

export default JobPositionPopup;