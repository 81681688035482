import PubSub from 'pubsub-js';
import moment from 'moment-timezone';

export const showLoader = () => {
    PubSub.publish('loader', true);
}

export const hideLoader = () => {
    PubSub.publish('loader', false);
}

export const setCookie = (name, value, options = {}) => {

    options = {
        path: '/',
        // add other defaults here if necessary
        ...options
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
}

export const getCookie = (name) => {
    // eslint-disable-next-line
    let matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const clearCookie = (name) => {
    setCookie(name, "", {
        'max-age': -1
    });
}

export const getUtcTime = (d) => {
    return moment(d, 'HH:mm:ss').utc().format("HH:mm:ss");
}

export const getLocalTime = (d) => {
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData)
    let zone = userData?.timeZone ?? 'Asia/Kolkata';
    return moment.utc(d, 'HH:mm:ss').tz(zone).format('HH:mm:ss');
}

export const getLocalTimeFormatted = (d) => {
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData)
    let zone = userData?.timeZone ?? 'Asia/Kolkata';
    let time = moment.utc(d, 'HH:mm:ss').tz(zone).format('hh:mm a')
    return time;
}

export const getLocalDateTimeFormatted = (d) => {
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData)
    let zone = userData?.timeZone ?? 'Asia/Kolkata';
    let time = moment.utc(d).tz(zone).format('Do MMM YYYY hh:mm a')
    return time;
}

export const checkIsInterviewTimePassed = (date, time) => {
    if (moment().isAfter(moment(date + ' ' + getLocalTime(time)))) {
        return true;
    } else {
        return false;
    }
}
export const getTimeDifference = (startTime, endTime) => {
    let mins = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("mm");
    let secs = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("ss")
    return moment(endTime).diff(moment(startTime), 'hours') + " Hrs " + mins + " Mns " + secs + " Secs";
}