import styles from './Aspiration.module.scss'
import faceBookIcon from "../../assets/images/Icon-facebook.png";
import faceBookIconActive from "../../assets/images/active-Icon-facebook.png";
import { useEffect, useState } from 'react';
import AspireBioPopup from '../popups/Aspiration/AspireBioPopup';
import AspireVisionPopup from '../popups/Aspiration/AspireVisionPopup';
import AspireLanguagePopup from '../popups/Aspiration/AspireLanguagePopup';
import AspireCongratsPopup from '../popups/Aspiration/AspireCongratsPopup';
import AspireSkillsPopup from '../popups/Aspiration/AspireSkillsPopup';
import arrowIcon from '../../assets/images/greaterThanIcon.png';
import { useNavigate } from 'react-router-dom';
import { selectedCandidatelanguage, selectedCandidateBios, selectedCandidateVision, selectedCandidateSkills } from '../../store/reducer'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { candidateOnBoard } from "../../services/Api/candidate";
import { constructParams } from '../../util/common';
import { getCookie, setCookie, clearCookie } from '../../util/helper';
import cellHelp from '../../assets/images/needHelp.png';


const Aspiration = (props) => {
    const { t } = useTranslation('common');
    const { setProgressCount, setShowProgressBar } = props;
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    console.log(userData)
    const { language, cvBioEncrypted, cvVision, skills } = userData;
    let langSplit = [];
    if (language && language.includes(",")) {
        langSplit = language.split(",");
    } else {
        langSplit = [language]
    }
    const [openBio, setOpenBio] = useState(false);
    const [openVision, setOpenVision] = useState(false);
    const [openLanguage, setOpenLanguage] = useState(false);
    const [openCongrats, setOpenCongrats] = useState(false);
    const [openSkills, setOpenSkills] = useState(false);
    const [callOnce, setCallOnce] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const totalProgress = 100;
    const totalFields = 3;
    const calculateProgress = Math.round(totalProgress / totalFields);
    let progressbarSize = 0;
    const { candidateVision, candidateBio, candidateSkills, candidatelanguages } = useSelector(state => state)

    useEffect(() => {
        if (!callOnce) {
            dispatch(selectedCandidatelanguage(candidatelanguages && candidatelanguages.length !== 0 ? candidatelanguages : langSplit));
            dispatch(selectedCandidateBios(candidateBio && candidateBio.length !== 0 ? candidateBio : cvBioEncrypted));
            dispatch(selectedCandidateVision(candidateVision && candidateVision.length !== 0 ? candidateVision : cvVision));
            dispatch(selectedCandidateSkills(candidateSkills && candidateSkills.length !== 0 ? candidateSkills : skills));
            if ((langSplit && langSplit.length !== 0) || (language && language.length !== 0)) {
                progressbarSize = progressbarSize + calculateProgress;
            }
            if (cvBioEncrypted && cvBioEncrypted.length !== 0) {
                progressbarSize = progressbarSize + calculateProgress;
            }
            if (cvVision && cvVision.length !== 0) {
                progressbarSize = progressbarSize + calculateProgress;
            }
            setCallOnce(true);
        }
        setProgressCount(100);
        setShowProgressBar(true);
    }, [])

    const gotoPreviewPage = async () => {
        try {
            if (getCookie('candidateId')) {
                const dubprofileData = JSON.parse(JSON.stringify(userData));
                const reqObj = Object.assign(dubprofileData, {});
                localStorage.setItem("userData", JSON.stringify(reqObj));
                if (reqObj._id) {
                    delete reqObj._id;
                }
                let params = {
                    id: getCookie("candidateId")
                }
                let reqParams = await constructParams(reqObj);
                // reqParams.campaignSourceURL = "whatsappbot";
                const {
                    data: {
                        data, message
                    }
                } = await candidateOnBoard(reqParams, params);
                var now = new Date();
                now.setTime(now.getTime() + 8 * 3600 * 1000); //expire time 8 hours
                let cookieOptions = {
                    expires: now //seconds to expire
                }
                setCookie("token", data.refreshToken, cookieOptions);
                clearCookie("candidateId");
                clearCookie("newUserMobileNo");
                // toast.success(message ? message : "Updated successfully")
                //dispatch(setProfileData(submitObj));
                localStorage.setItem("userData", JSON.stringify(reqParams));
            }
            navigate('/aspirationFill')
        } catch (e) {
            console.log("AspireError------>", e)
        }

    }

    return (
        <div className="page slide-page">
            <div className={styles.TitleHead}>
                <h3 dangerouslySetInnerHTML={{ __html: t('aspiration.title') }}></h3>
            </div>

            <div className={styles.formInputAre}>
                <div className={styles.AspirationList}>
                    <ul>
                        <li onClick={() => setOpenBio(true)}>{t('aspiration.yourBio')} <strong style={{ float: "right" }}>
                            <span>
                                <img alt='arrow' src={arrowIcon} style={{ height: "13px", width: "13px" }} />
                            </span>
                        </strong>
                        </li>
                        <li onClick={() => setOpenVision(true)}>{t('aspiration.yourVision')} <strong style={{ float: "right" }}>
                            <span>
                                <img alt='arrow' src={arrowIcon} style={{ height: "13px", width: "13px" }} />
                            </span>
                        </strong>
                        </li>
                        <li onClick={() => setOpenLanguage(true)}>{t('aspiration.yourLanguage')} <strong style={{ float: "right" }}>
                            <span>
                                <img alt='arrow' src={arrowIcon} style={{ height: "13px", width: "13px" }} />
                            </span>
                        </strong>
                        </li>
                        <li onClick={() => setOpenSkills(true)}>{t('aspiration.yourSkills')} <strong style={{ float: "right" }}>
                            <span>
                                <img alt='arrow' src={arrowIcon} style={{ height: "13px", width: "13px" }} />
                            </span>
                        </strong>
                        </li>
                    </ul>
                </div>
                <div className={styles.faceBtn} style={{ display: "none" }}>
                    <button className={styles.facebookBtn}><img alt='facebook' src={faceBookIcon} />Login with Facebook</button>
                </div>
            </div>

            {openBio && <AspireBioPopup isOpen={openBio} isClose={() => setOpenBio(false)} />}
            {openSkills && <AspireSkillsPopup isOpen={openSkills} isClose={() => setOpenSkills(false)} />}
            {openVision && <AspireVisionPopup isOpen={openVision} isClose={() => setOpenVision(false)} />}
            {openLanguage && <AspireLanguagePopup isOpen={openLanguage} isClose={() => setOpenLanguage(false)} />}
            {openCongrats && <AspireCongratsPopup isOpen={openCongrats} isClose={() => setOpenCongrats(false)} />}

            <div className={styles.aspirFill} >
                <div className={styles.faceBtnActive} style={{ display: "none" }}>
                    <button className={styles.facebookBtnActive}><img alt='facebook' src={faceBookIconActive} />{t('aspiration.facebookbtn')}</button>
                </div>
            </div>

            <div className={styles.saveNext}>
                <button className={styles.next} onClick={() => gotoPreviewPage()}>{t('aspiration.preview')} </button>
                <button onClick={() => setOpenCongrats(true)} className={styles.skipButton} >{t('common.skip')} </button>
            </div>
            <div className={styles.Back}>
                <button className={styles.next} onClick={() => navigate('/projects')}>{t('common.back')}</button>
            </div>

            <div className={styles.newHelp}>
                    <div className={styles.helpCall}>
                    <a href="tel:8595141564">
                        <img  src={cellHelp}/>
                    </a>
                    </div>
                </div>
        </div>)
}

export default Aspiration;