import styles from './Project.module.scss'
// import '../../App.css'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { setCandidateProjects } from '../../store/reducer';
import { useRef } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import cellHelp from '../../assets/images/needHelp.png';

const Project = (props) => {
    const { t } = useTranslation('common');
    const { setProgressCount, setShowProgressBar } = props;
    const { candidateProjectDetails, selectedProjectIndex } = useSelector(state => state);
    const navigate = useNavigate();
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    const { projectName, description, startDate, endDate } = selectedProjectIndex === "" ? {} : candidateProjectDetails[parseInt(selectedProjectIndex)];
    const [userProjectName, setUserProjectName] = useState("");
    const [userProjectDesc, setUserProjectDesc] = useState("");
    const [userProjectStartDate, setUserProjectStartDate] = useState("");
    const [userProjectEndDate, setUserProjectEndDate] = useState("");
    const dispatch = useDispatch();
    const totalProgress = 100;
    const totalFields = 4;
    const calculateProgress = Math.round(totalProgress / totalFields);
    let progressbarSize = 0;
    const [localProgress, setlocalProgress] = useState(progressbarSize);
    const startDateRef = useRef();
    const endDateRef = useRef();

    useEffect(() => {
        if (selectedProjectIndex !== "") {
            if (projectName) {
                progressbarSize = progressbarSize + calculateProgress;
                setUserProjectName(projectName)
            }
            if (description) {
                progressbarSize = progressbarSize + calculateProgress;
                setUserProjectDesc(description)
            }
            if (startDate) {
                progressbarSize = progressbarSize + calculateProgress;
                setUserProjectStartDate(startDate)
            }
            if (endDate) {
                progressbarSize = progressbarSize + calculateProgress;
                setUserProjectEndDate(endDate)
            }
            setProgressCount(90);
        }
        setProgressCount(90);
        setShowProgressBar(true)
    }, [])

    const submitHandler = () => {
        if (projectName !== userProjectName || description !== userProjectDesc ||
            startDate !== userProjectStartDate || endDate !== userProjectEndDate) {
            if (userProjectName.trim() !== "" && userProjectDesc.trim() !== "" &&
                userProjectStartDate.trim() !== "" && userProjectEndDate.trim() !== "") {
                const submitObj = {
                    projectName: userProjectName,
                    description: userProjectDesc,
                    startDate: userProjectStartDate,
                    endDate: userProjectEndDate,
                    update: true
                }
                console.log('submitObj',submitObj)
                dispatch(setCandidateProjects(submitObj));
                navigate('/projects')
            } else {
                toast.error("Please fill all the fields");
            }

        } else {
            navigate('/projects')
        }

    }

    const onBlurHandler = (name) => {
        if (name === "projectName" && userProjectName) {
            progressbarSize = localProgress + calculateProgress;
            setlocalProgress(progressbarSize);
        }
        if (name === "projectSdate" && userProjectStartDate) {
            progressbarSize = localProgress + calculateProgress;
            setlocalProgress(progressbarSize)
        }
        if (name === "projectEdate" && userProjectEndDate) {
            progressbarSize = localProgress + calculateProgress;
            setlocalProgress(progressbarSize)
        }
        if (name === "description" && userProjectDesc) {
            progressbarSize = localProgress + calculateProgress;
            setlocalProgress(progressbarSize)
        }
        setProgressCount(progressbarSize);
    }
    return (
        <div className="proAdd">
            {/* <img alt='LeftArrow' src={leftArrow} /> */}
            <div className={styles.TitleHead}>
                <h3 dangerouslySetInnerHTML={{ __html: t('addProject.title') }}></h3>
            </div>
            <div className={styles.formInputAre}>
                <div className={styles.FormGroup}>
                    <label>{t('addProject.projectName')}</label>
                    <input className={styles.formControl} type="text" value={userProjectName} onBlur={() => onBlurHandler("projectName")} onChange={e => setUserProjectName(e.target.value)} placeholder="Your Project Name" />
                </div>
                <div className={styles.genderWidth}>
                    <div className={styles.FormGroup}>
                        <label>{t('addProject.projectStartDate')}</label>
                        <input className={styles.formControl} type="date" value={moment(userProjectStartDate).format("YYYY-MM-DD")}
                            onBlur={() => onBlurHandler("projectSdate")} onChange={e => setUserProjectStartDate(e.target.value)}
                            placeholder="mm/yy" ref={startDateRef} onClick={() => startDateRef.current.showPicker()} />
                    </div>
                </div>
                <div className={styles.dobN}>
                    <div className={styles.FormGroup}>
                        <label style={{ display: "block" }}>{t('addProject.projectEndDate')}</label>
                        <input className={styles.formControl} type="date" value={moment(userProjectEndDate).format("YYYY-MM-DD")}
                            onBlur={() => onBlurHandler("projectEdate")} onChange={e => setUserProjectEndDate(e.target.value)}
                            placeholder="mm/yy" ref={endDateRef} onClick={() => endDateRef.current.showPicker()} />
                    </div>
                </div>
                <div className={styles.FormGroup}>
                    <label>{t('addProject.projectDesc')}</label>
                    <textarea className={styles.formControl} type="text" value={userProjectDesc} onChange={e => setUserProjectDesc(e.target.value)} onBlur={() => onBlurHandler("description")} placeholder="Your project’s specification"></textarea>
                </div>
            </div>
            <div className={styles.saveNext}>
                <button className={styles.addProBtn} onClick={() => submitHandler()}>{t('addProject.addprojectdetails')}</button>
            </div>
            <div className={styles.Back}>
                <button className={styles.next} onClick={() => navigate('/projects')}>{t('common.back')}</button>
            </div>

            <div className={styles.newHelp}>
                    <div className={styles.helpCall}>
                    <a href="tel:8595141564">
                        <img  src={cellHelp}/>
                    </a>
                    </div>
                </div>
        </div>
    )
}

export default Project;