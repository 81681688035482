import { createSlice } from '@reduxjs/toolkit';


export const myCv = createSlice({
    name: 'todos',
    initialState: {
        test: "updating....", todos: [], userData: {},
        profileData: {}, expDetails: [], selectedEmpDetails: "", achieveMentDetails: [],
        selectedAchieveIndex: "", selectedProjectIndex: "", selectedExperienceIndex: "", candidateProjectDetails: [],
        candidatelanguages: [], candidateBio: [], candidateVision: [], candidateSkills: [], newUserMobileNo: "", campignSource: "", invitedBy:""
    },
    reducers: {
        addTodo: (state, action) => {
            const todo = {
                id: Math.floor(Math.random() * 100) + 1,
                text: action.payload,
            };
            state.todos.push(todo);
        },
        loginData: (state, action) => {
            //state.userData = action.payload
            Object.assign(state.userData, action.payload)
        },
        setProfileData: (state, action) => {
            //state.profileData = action.payload
            Object.assign(state.profileData, action.payload)
        },

        setExperianceDetails: (state, action) => {
            try {
                const { update } = action.payload;
                if (!update) {
                    state.expDetails = action.payload.expDetails
                } else {
                    let isPresent = state.expDetails.some(function (el) {
                        return el.lastCompany === action.payload.lastCompany &&
                            el.industry && action.payload.industry &&
                            el.lastPosition === action.payload.lastPosition && el.fromMonth === action.payload.fromMonth &&
                            el.toMonth === action.payload.toMonth && el.lastSalary === action.payload.lastSalary &&
                            el.description === action.payload.description
                    });
                    if (state.selectedEmpDetails !== "") {
                        let Obj = {};
                        Obj.lastCompany = action.payload.lastCompany;
                        Obj.industry = action.payload.industry;
                        Obj.lastPosition = action.payload.lastPosition;
                        Obj.fromMonth = action.payload.fromMonth;
                        Obj.toMonth = action.payload.toMonth;
                        Obj.description = action.payload.description;
                        Obj.lastSalary = action.payload.lastSalary;
                        Obj.stillWork = action.payload.stillWork;
                        state.expDetails[state.selectedEmpDetails] = Obj;
                    } else {
                        state.expDetails.push(action.payload);
                    }
                }
            } catch (e) {
                console.log("ExperienceError->", e)
            }
        },
        setAchieveMentDetails: (state, action) => {
            try {
                const { update } = action.payload;
                if (!update) {
                    state.achieveMentDetails = action.payload.achieveDetails
                } else {
                    if (state.selectedAchieveIndex !== "") {
                        let Obj = {};
                        Obj.title = action.payload.title;
                        Obj.description = action.payload.description;
                        Obj.date = action.payload.date;
                        state.achieveMentDetails[state.selectedAchieveIndex] = Obj;
                    } else {
                        state.achieveMentDetails.push(action.payload);

                    }
                }

                // let result = state.achieveMentDetails.reduce((unique, o) => {
                //     if (!unique.some(obj => obj.label === o.label && obj.value === o.value)) {
                //         unique.push(o);
                //     }
                //     return unique;
                // })
                // state.achieveMentDetails = result;
            } catch (e) {
                console.log("AchieveError->", e)
            }

        },
        setCandidateProjects: (state, action) => {
            try {
                const { update } = action.payload;

                if (!update) {
                    state.candidateProjectDetails = action.payload.projectDetails
                } else {

                    // let isPresent = state.candidateProjectDetails.some(function (el) {
                    //     return el.projectName === action.payload.projectName &&
                    //         el.description && action.payload.description && el.startDate === action.payload.startDate
                    //         && el.endDate === action.payload.endDate
                    // });
                    // if (isPresent) {
                    //     let Obj = {};
                    //     Obj.projectName = action.payload.projectName;
                    //     Obj.description = action.payload.description;
                    //     Obj.startDate = action.payload.startDate;
                    //     Obj.endDate = action.payload.endDate;
                    //     state.candidateProjectDetails[state.selectedProjectIndex] = Obj;
                    // } else {
                    //     state.candidateProjectDetails.push(action.payload);

                    // }
                    if (state.selectedProjectIndex !== "") {
                        let Obj = {};
                        Obj.projectName = action.payload.projectName;
                        Obj.description = action.payload.description;
                        Obj.startDate = action.payload.startDate;
                        Obj.endDate = action.payload.endDate;
                        state.candidateProjectDetails[state.selectedProjectIndex] = Obj;
                    } else {
                        state.candidateProjectDetails.push(action.payload);

                    }
                }
            } catch (e) {
                console.log("ProjectError->", e)
            }
        },
        selectedEmpDetails: (state, action) => {
            state.selectedEmpDetails = action.payload;
        },
        selectedAchieveIndex: (state, action) => {
            state.selectedAchieveIndex = action.payload;
        },
        selectedCandidateProjectIndex: (state, action) => {
            state.selectedProjectIndex = action.payload;
        },
        selectedCandidatelanguage: (state, action) => {
            state.candidatelanguages = action.payload;
        },
        selectedCandidateSkills: (state, action) => {
            state.candidateSkills = action.payload;
        },
        selectedCandidateBios: (state, action) => {
            state.candidateBio = action.payload;
        },
        selectedCandidateVision: (state, action) => {
            state.candidateVision = action.payload;
        },
        newUserPhoneNo: (state, action) => {
            state.newUserMobileNo = action.payload;
        },
        deleteEmpDetails: (state, action) => {
            const exisitValues = JSON.parse(JSON.stringify(state.expDetails))
            exisitValues.splice(action.payload, 1);
            state.expDetails = exisitValues;
        },
        setCampignSource: (state, action) => {
            state.campignSource = action.payload
        },
        setInvitedBy:(state, action) => {
                state.invitedBy = action.payload
        }


    }
});


// this is for dispatch
export const { addTodo, loginData, setProfileData, setExperianceDetails, setAchieveMentDetails,
    selectedCandidatelanguage, selectedCandidateBios, selectedAchieveIndex, selectedEmpDetails, selectedCandidateSkills,
    deleteEmpDetails, setCandidateProjects, selectedCandidateProjectIndex, selectedCandidateVision, newUserPhoneNo, setCampignSource, setInvitedBy } = myCv.actions;
export const initialState = myCv.getInitialState();

// this is for configureStore
export default myCv.reducer;