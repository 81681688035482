import React, { useEffect, useRef, useState } from "react";
import styles from "../DigitalResume/DigitalResumeFirst.module.scss";
import logoImg from '../../assets/images/logoColor.svg';
import changesBtn from '../../assets/images/editImage.svg';
import inviteBtn from '../../assets/images/inviteBtn.png';
import shareBtn from '../../assets/images/shareBtn.png';
import buyPdf from '../../assets/images/buyImage.svg';
import downloadCv200 from '../../assets/images/downloadCv200.png';
import downloadCv20 from '../../assets/images/downloadCv20.png';
import offerCv from '../../assets/images/offerNew.png';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import DigitalCV from "../DigitalCV/DigitalCV";
import { hideLoader, showLoader, setCookie, getCookie } from "../../util/helper";
import { useDispatch, useSelector } from "react-redux";
import SharePopup from '../popups/SharePopup/SharePopup';
import domtoimage from 'dom-to-image';
import { callUploadApi } from '../../util/common';
import { createPDF, pdfArrayToBlob, mergePDF } from "pdf-actions";
import { toast } from 'react-toastify';
import { getProfile, createEmployerSubscription, subscriptionTransactionEnd } from '../../services/Api/candidate';
import { setProfileData } from '../../store/reducer';
import InvitePopup from "../popups/InvitePopup/InvitePopup";

import { exportPdf  } from '../FixedPdfLayout/FixedPDFLayout';  // Import the exportPdf function from FixedPDFLayout

const CandidateResume = (props) => {
    const { setShowProgressBar } = props
    const { profileData } = useSelector(state => state);
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    const { experienceDetails, profilePicture, fullName, refreshToken, projectDetails, achievementDetails,
        cvBioEncrypted, cvVision, desiredDesignation, educationDetails, aadhaarMobile, whatsappMobile,  yearsOfExperience } = userData || profileData;

    const [openShare, setOpenShare] = useState(false);
    const [openInvite, setInviteShare] = useState(false);
    const [showResumeFirst, setShowResumeFirst] = useState(true);
    const [showResumeSecond, setShowResumeSecond] = useState(false);
    const [resumePdf, setResumePdf] = useState([]);
    const [isupdate, setIsupdate] = useState(false);
    const [resumeUrl, setResumeUrl] = useState(`${window.location.protocol}//${window.location.host}/digitalcv?q=${getCookie('token')}`);
    const [inviteUrl, setInviteUrl] = useState(`Hey 👋, \n\nI've just made my FREE Digital CV on Jobsgaar. \n\nYou can create yours too using the link below and earn ₹500 by sharing it with friends. \n\nLink:- https://mycv.jobsgaar.com/login?invitedBy=${getCookie('id')}`);
    const [hideEdit, setHideEdit] = useState(false);
    const [paymentData, setPaymentData] = useState({});
    const [isPaymentClosed, setIsPaymentClosed] = useState(false);
    const [showSecondPage, setShowSecondPage] = useState(0)
    const [callOnce, setCallOnce] = useState(false)
    const [fileUploadResult, setfileUploadResult] = useState({});

    const pdfRef = useRef();
    const dispatch = useDispatch();
    let doc = new jsPDF('p', 'mm');
    const docMobile = new jsPDF('p', 'mm', [297, 210]);
    const [loading, setLoading] = useState(true);  
    const callProfileInfo = async (query) => {
        try {
          let now = new Date();
          now.setTime(now.getTime() + 8 * 3600 * 1000); // Expire time 8 hours
          let cookieOptions = {
            expires: now,
          };
          setCookie("token", query, cookieOptions);
    
          let candidateData = await getProfileData();
          
    
          return candidateData;
        } catch (error) {
          toast.error("Invalid User, Please login again");
          window.location.href = '/login';
          throw error;
        }
      };
    
      const getProfileData = async () => {
        try {
            showLoader();
            let response = await getProfile();
            let {
                data: {
                    data
                }
            } = response;
    
            hideLoader();
            console.log('getProfileData response:', data);
            return data;
        } catch (error) {
            hideLoader();
            throw error;
        }
    }
    
      useEffect(() => {
     
        const fetchData = async () => {
            let candidateData
          try {
            const query = new URLSearchParams(window.location.search);
    
            if (query.get('q')) {
                setHideEdit(true);

                try {
                     candidateData = await callProfileInfo(query.get('q'));
                    dispatch(setProfileData(candidateData));
                  
                    localStorage.setItem("userData", JSON.stringify(candidateData));
                    setResumeUrl(`http://${window.location.host}/digitalcv?q=${query}`);
                    let IsSecondPage = showSecondPage;

                    if ((candidateData.cvBioEncrypted && candidateData.cvBioEncrypted.length !== 0) || 
                        (candidateData.cvVision && candidateData.cvVision.length !== 0)) {
                        IsSecondPage += 1;
                    }
                    if (candidateData.desiredDesignation) {
                        IsSecondPage += 1;
                    }
                    if (candidateData.educationDetails && candidateData.educationDetails.length !== 0 && 
                        candidateData.educationDetails.length >= 1) {
                        IsSecondPage += 1;
                    }
                    if (candidateData.experienceDetails && candidateData.experienceDetails.length > 2 && 
                        candidateData.yearsOfExperience > 0) {
                        IsSecondPage += 1;
                    }
                    if (candidateData.achievementDetails && candidateData.achievementDetails.length !== 0 && 
                        candidateData.achievementDetails.length >= 1 && candidateData.yearsOfExperience > 0) {
                        IsSecondPage += 1;
                    }

                    if (candidateData.projectDetails && candidateData.projectDetails.length !== 0 && 
                        candidateData.projectDetails.length >= 1 && candidateData.yearsOfExperience > 0) {
                        IsSecondPage += 1;
                    }

                    // Update showSecondPage state
                    setShowSecondPage(IsSecondPage);

                } catch (e) {
                    toast.error("Invalid User, Please login again");
                    window.location.href = '/login';
                    return;
                }
            }
                    
            if (!query.get('q')) {
            let IsSecondPage = showSecondPage;
            
    
            if ((cvBioEncrypted && cvBioEncrypted.length !== 0) || (cvVision && cvVision.length !== 0)) {
              IsSecondPage += 1;
            }
            if (desiredDesignation) {
              IsSecondPage += 1;
            }
            if (educationDetails && educationDetails.length !== 0 && educationDetails.length >= 1) {
              IsSecondPage += 1;
            }
            if (experienceDetails && experienceDetails.length > 2 && yearsOfExperience > 0) {
              IsSecondPage += 1;
            }
            if (achievementDetails && achievementDetails.length !== 0 && achievementDetails.length >= 1 && yearsOfExperience > 0) {
              IsSecondPage += 1;
            }
    
            if (projectDetails && projectDetails.length !== 0 && projectDetails.length >= 1 && yearsOfExperience > 0) {
              IsSecondPage += 1;
            }
    
            setShowSecondPage(IsSecondPage);
        }
            // Handle file upload result
            if (Object.keys(fileUploadResult).length !== 0) {
              const { fileKey, fileUrl } = fileUploadResult;
              // Handle file upload logic...
              setfileUploadResult({});
              createSubscription();
            }
    
            setLoading(false);
          } catch (error) {
            console.error("Error in fetchData:", error);
            // Handle errors here...
          }
        };
    
        fetchData();
      }, [ isupdate, fileUploadResult]);
    
    
//     const callProfileInfo = (query) => {
//         return new Promise(async (resolve, reject) => {
//             try {
//                 let now = new Date();
//                 now.setTime(now.getTime() + 8 * 3600 * 1000); //expire time 8 hours
//                 let cookieOptions = {
//                     expires: now //seconds to expire
//                 }
//                 setCookie("token", query, cookieOptions);
    
//                 let candidateData = await getProfileData();
//                 console.log('candidateData', candidateData);
//                 dispatch(setProfileData(candidateData));
//                 localStorage.setItem("userData", JSON.stringify(candidateData));
//                 setResumeUrl(`http://${window.location.host}/digitalcv?q=${query}`);
    
//                 resolve(candidateData); // Resolve the promise with candidateData
//             } catch (e) {
//                 toast.error("Invalid User, Please login again");
//                 window.location.href = '/login';
//                 reject(e); // Reject the promise with the error
//             }
//         });
//     };
    

//     const getProfileData = async () => {
//     try {
//         showLoader();
//         let response = await getProfile();
//         let {
//             data: {
//                 data
//             }
//         } = response;

//         hideLoader();
//         return data;

//     } catch (error) {
//         hideLoader();
//         throw error; // rethrow the error to propagate it up the call stack
//     }
// }
    async function generate() {
        var doc = new jsPDF();

        doc.setFontSize(40);
        doc.text("Octonyan loves jsPDF", 35, 25);
        //doc.addImage("examples/images/Octonyan.jpg", "JPEG", 15, 40, 180, 180);
        doc.rect(10, 30, 190, 10);
        doc.save("test.pdf")

    }

    const Download = async () => {
        try {
            // generate();
            // return;
            setShowResumeFirst(true);
            let checkNext = document.getElementById("btn_next");
            if (showSecondPage >= 4) {
                setShowResumeSecond(true);
            } else {
                setShowResumeSecond(false);
            }
            showLoader();
            setTimeout(async () => {
                await convertHTMLToPDF();
                //const { fileKey, fileUrl } = await convertHTMLToPDF()
                //downloadURI(fileUrl, "test")
                // createSubscription()
                //hideLoader();
                setIsupdate(!isupdate);
            }, 1000);
        } catch (e) {
            toast.error("We are sorry for inconvenience")
        }
    }
   

    const detectMob = () => {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    }

    const createSubscription = async () => {
        console.log(userData?._id)
        try {
            showLoader();
            let subscriptionData = {
                empId: userData?._id,
                fullName: userData?.fullName,
                mobileNumber: whatsappMobile ? whatsappMobile : aadhaarMobile,
                city: userData?.city,
                fullAddress: userData?.city,
                amount: userData?.campaignSourceURL.includes('careerCentre') ? 1 : 200,
                subscriptionType: "Download",
                currency: 'INR',
                paidBy: 'candidate'

            }
            // console.log("test------------>", test)
            // console.log("DownloadFile------------>", fileUploadResult)
            // return;
            const {
                data: {
                    data,
                    /**message*/
                }
            } = await createEmployerSubscription(subscriptionData);
            let checkoutData = {
                orderId: data?.orderId,
                token: data?.transactionToken,
                amount: subscriptionData.amount,
                planStatus: "Download"
            }
            setPaymentData(checkoutData);
            paytmCheckout(checkoutData);
            hideLoader();

        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }

    const paytmCheckout = (data) => {
        const { orderId, token, amount, planStatus } = data;
        var config = {
            "root": "",
            "flow": "DEFAULT",
            "data": {
                "orderId": orderId, /* update order id */
                "token": token, /* update token value */
                "tokenType": "TXN_TOKEN",
                "amount": amount /* update amount */
            },
            merchant: {
                redirect: false
            },
            "handler": {
                "notifyMerchant": function (eventName, data) {
                    console.log("notifyMerchant handler function called");
                    console.log("eventName => ", eventName);
                    console.log("data => ", data);
                    if (eventName === "APP_CLOSED") {
                        setShowResumeFirst(true);
                        setShowResumeSecond(false);
                        setIsPaymentClosed(true);
                    } else if (eventName === "SESSION_EXPIRED") {
                        createSubscription(paymentData?.jobId, paymentData?.planStatus);
                    }
                },
                transactionStatus: function (data) {
                    transactionEnd(data, planStatus);
                    window.Paytm.CheckoutJS.close();
                    console.log("payment status ", data);
                }
            }
        };
        console.log('paytmData', config)
        if (window.Paytm && window.Paytm.CheckoutJS) {
            // initialze configuration using init method
            window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
                // after successfully updating configuration, invoke JS Checkout
                window.Paytm.CheckoutJS.invoke();
            }).catch(function onError(error) {
                console.log("error => ", error);
            });
        }
    }

    const transactionEnd = async (transactionData, planStatus) => {
        try {
            showLoader();
            const { ORDERID, CURRENCY, TXNAMOUNT, TXNID, BANKTXNID, TXNDATE, RESPCODE, RESPMSG, PAYMENTMODE, STATUS, GATEWAYNAME, BANKNAME } = transactionData;
            const { _id, fullName, whatsappMobile, city, fullAddress, aadhaarMobile, email } = userData;

            const { fileKey, fileUrl } = fileUploadResult;

            let subscriptionEndData = {
                candidateID: _id,
                Name: fullName,
                MobileNumber: whatsappMobile ? whatsappMobile : aadhaarMobile,
                City: city,
                Address: fullAddress ? fullAddress : email,
                OrderID: ORDERID,
                PaymentMethod: 'PPI',
                Currency: CURRENCY,
                Amount: TXNAMOUNT,
                SubscriptionType: planStatus,
                GatewayTransactionID: TXNID,
                BankTransactionID: BANKTXNID,
                TransactionDate: TXNDATE,
                TransactionStatus: RESPCODE,
                Comments: RESPMSG,
                PaymentMode: PAYMENTMODE,
                GatewayStatus: STATUS,
                GatewayResponseCode: RESPCODE,
                GatewayResponseMessage: RESPMSG,
                GatewayName: GATEWAYNAME,
                BankName: BANKNAME ? BANKNAME : PAYMENTMODE,
                paidBy: "candidate",
                pdfFileNameOnS3Bucket: fileKey
            }
            const {
                data: {
                    /**data,
                    message*/
                }
            } = await subscriptionTransactionEnd(subscriptionEndData);
            downloadURI(fileUrl, fullName + "_CV.pdf")
            setfileUploadResult({})
            hideLoader();
            if (RESPCODE === '01') {
                toast.success(RESPMSG);
                // if (detectMob()) {
                //     toast.success("Thanks for download, Please check download folder");
                // }
            } else {
                toast.error(RESPMSG);
            }
            //convertHTMLToPDF();
            // setIsChoosePlan(false);
            // setIsJobPosted(true);

        } catch (error) {
            const data = error;
            hideLoader();
            toast.error((data && data.message) ? data.message : "Failed");
        }
    }

    const convertHTMLToPDF = async () => {
        let result = "";
        if (detectMob()) {
            result = await exportPdf('page1-content', 1);
            if (showSecondPage >= 4) {
                result = await exportPdf('page2-content', 2);
            }
            // result = await exportPdf(generateHtmlContent(), 'page1-content', 1);
            // if (showSecondPage >= 4) {
            //     result = await exportPdf(generateHtmlContent(), 'page-content', 2);
            // }
        } else {

            result = await printDocument('page1-content', 1, doc);
            if (showSecondPage >= 4) {
                result = await printDocument('page2-content', 2, doc);
            }
        }
        return result;
    }

    const exportPdf = async (divId, pageNumber) => {
        const div = document.getElementById(divId);
        const options = {
            background: 'white', height: 1000, width: 820, quality: 1
        };
        const dataUrl = await domtoimage.toPng(div);


        var imgWidth = 210;
        var pageHeight = 280;


        const imgProps = docMobile.getImageProperties(dataUrl);
        const pdfWidth = docMobile.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        const pdfHeightnew = docMobile.internal.pageSize.getHeight();


        let position = 0;
        if (window.innerWidth > 400) {
            position = 0;
        } else {
            if (window.innerWidth < 350) {
                position = 22;
            } else {
                position = 8;
            }
        }
        if (pageNumber === 1) {
            //  docMobile.addImage(dataUrl, 'jpeg', 0, 20, pdfWidth + 15, pdfHeight + 20, undefined, 'FAST');
            docMobile.addImage(dataUrl, 'jpeg', 0, 0, imgWidth, pageHeight, undefined, 'MEDIUM');
            let checkNext = document.getElementById("btn_next");
            if (!checkNext) {
                //  docMobile.save(`${fullName}_CV.pdf`);
                setShowResumeFirst(true);
                setShowResumeSecond(false);
                let blobArray = resumePdf;
                blobArray.push(docMobile.output('blob'));
                setResumePdf(blobArray);
                //docMobile.save(`${fullName}_CV.pdf`);
                return uploadPdfToServer(blobArray)
            }
        } else {
            docMobile.addPage();
            docMobile.addImage(dataUrl, 'jpeg', 0, 0, imgWidth, pdfHeight, undefined, 'MEDIUM');
            //docMobile.addImage(dataUrl, 'jpeg', 0, 0, pdfWidth, pdfHeightnew, undefined, 'FAST');
        }

        if (pageNumber === 2) {
            // docMobile.save(`${fullName}_CV.pdf`);
            setShowResumeFirst(true);
            setShowResumeSecond(false);
            let blobArray = resumePdf;
            blobArray.push(docMobile.output('blob'));
            setResumePdf(blobArray);
            return uploadPdfToServer(blobArray)
            //docMobile.save(`${fullName}_CV.pdf`);
        }
       
    }


    const printDocument = async (divId, pageNumber, doc) => {
        const input = document.getElementById(divId);
        input.setAttribute("style", "margin-top:100px")
        const canvas = await html2canvas(input, { dpi: 400, letterRendering: true, useCORS: true, allowTaint: false })
        canvas.setAttribute("style", "margin-top:100px")
        //.then((canvas) => {
        let imgData = canvas.toDataURL('image/png');
        let imgWidth = 210;
        let pageHeight = 280;
        let imgHeight = pageHeight * imgWidth / canvas.width;
        let heightLeft = imgHeight;
        //  window.jsPDF = window.jspdf.jsPDF;
        //    let doc = new jsPDF('p', 'mm');
        let checkNext = document.getElementById("btn_next");
        let position = 0;
        if (!checkNext) {
            position = 5;
        } else {
            position = 15;
        }


        const imgProps = doc.getImageProperties(imgData);
        const pdfWidth = doc.internal.pageSize.getWidth();
        let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        if (pageNumber === 1) {
            // doc.addImage(imgData, 'jpeg', 0, position, pdfWidth, pdfHeight, undefined, 'FAST');
            doc.addImage(imgData, 'jpeg', 0, position, imgWidth, pageHeight, undefined, 'FAST');
            let checkNext = document.getElementById("btn_next");
            if (!checkNext) {
                setShowResumeFirst(true);
                setShowResumeSecond(false);
                let blobArray = resumePdf;
                blobArray.push(doc.output('blob'));
                setResumePdf(blobArray);
                //doc.save(`${fullName}_CV.pdf`);
                return await uploadPdfToServer(blobArray)
                // doc.save(`${fullName}_CV.pdf`);
                // setShowResumeFirst(true);
                // setShowResumeSecond(false);
            }
        } else {
            doc.addPage();
            
            doc.addImage(imgData, 'jpeg', 0, position, pdfWidth, 280, undefined, 'FAST');
            //doc.addImage(imgData, 'jpeg', 0, position, imgWidth, pageHeight, undefined, 'FAST');
        }

        if (pageNumber === 2) {
            
            setShowResumeFirst(true);
            setShowResumeSecond(false);
            let blobArray = resumePdf;
            blobArray.push(doc.output('blob'));
            setResumePdf(blobArray);
            return await uploadPdfToServer(blobArray)
            //doc.save(`${fullName}_CV.pdf`);
        }
       
    }

    const uploadPdfToServer = async (files) => {
        try {
            let value = [];
            for (let i = 0; i < files.length; i++) {
                value.push(await createPDF.PDFDocumentFromFile(files[i]))
            }
            const mergedPDFDocument = await mergePDF(value);
            const mergedPdfFile = await mergedPDFDocument.save();
            const pdfBlob = pdfArrayToBlob(mergedPdfFile);
            const result = await callUploadApi(pdfBlob);
            setfileUploadResult(result);
            //downloadURI(result.fileUrl, "test.pdf")
            // setResumePdf([]);
            // setResumeUrl(result.fileUrl);
            // setOpenShare(true)
            hideLoader();
            return result;
        } catch (e) {
            setfileUploadResult({});
            hideLoader();
            return ""
        }
    }

    // const uploadPdfToServer = async (files) => {
    //     let value = [];
    //     for (let i = 0; i < files.length; i++) {
    //         value.push(await createPDF.PDFDocumentFromFile(files[i]))
    //     }
    //     const mergedPDFDocument = await mergePDF(value);
    //     const mergedPdfFile = await mergedPDFDocument.save();
    //     const pdfBlob = pdfArrayToBlob(mergedPdfFile);
    //     const result = await callUploadApi(pdfBlob);
    //     // downloadURI(result.fileUrl, "test.pdf")
    //     setResumePdf([]);
    //     setResumeUrl(result.fileUrl);
    //     setOpenShare(true)
    //     hideLoader();
    // }

    const downloadURI = (uri, name) => {
        if (detectMob()) {
            window.open(uri, "_blank")
        } else {
            let linkTag = document.createElement("a");
            linkTag.download = name;
            linkTag.href = uri;
            document.body.appendChild(linkTag);
            linkTag.click();
            document.body.removeChild(linkTag);
            linkTag.remove();
        }

        // var a = document.createElement('a');
        // a.href = uri;
        // a.download = name;

        // a.click();
    }

    // const generatePDF = async () => {
    //     // Choose the element that your content will be rendered to.
    //     const element = document.getElementById('page1-content');
    //     var HTML_Width = element.offsetWidth;
    //     var HTML_Height = element.offsetHeight;
    //     var top_left_margin = 15;
    //     var PDF_Width = HTML_Width + (top_left_margin * 2);
    //     var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    //     var canvas_image_width = HTML_Width;
    //     var canvas_image_height = HTML_Height;

    //     var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

    //     let canvas = await html2canvas(element, { dpi: 300, letterRendering: true, useCORS: true, allowTaint: false })
    //     var imgData = canvas.toDataURL("image/jpeg", 1.0);
    //     var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
    //     pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
    //     for (var i = 1; i <= totalPDFPages; i++) {
    //         pdf.addPage(PDF_Width, PDF_Height);
    //         pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
    //     }
    //     pdf.save("Your_PDF_Name.pdf");
    //     //$(".html-content").hide();
    //     //});
    // }
    if (loading) {
        showLoader();
        return <div>Loading...</div>;
    }
    return (
        <div className={styles.dashbardData}>
            {openShare && <SharePopup isOpen={openShare} isClose={() => setOpenShare(false)} resumeUrl={resumeUrl} />}
            {openInvite && <InvitePopup isOpen={openInvite} isClose={() => setInviteShare(false)} resumeUrl={inviteUrl} />}
            <div className={styles.dashboardNav}>
                <div className={styles.logoHeading}>
                    <img src={logoImg} alt="Logo" />
                    <div className={styles.sideBtns}>
                        <h1>Digital CV</h1>
                        {!hideEdit && <button className={styles.btnEdit} onClick={() => window.location.href = '/resumeedit'}> <img src={changesBtn} /> Edit</button>}
                    </div>
                </div>
                <div className={styles.resumeData}>
                    <DigitalCV showResumeFirst={showResumeFirst} showResumeSecond={showResumeSecond} showSecondPage={showSecondPage} pdfRef={pdfRef} />
                    {showSecondPage >= 4 && <div className={styles.btnScroll}>
                        <button className={styles.btnStyle} onClick={() => { setShowResumeFirst(true); setShowResumeSecond(false) }}>Previous</button>
                        <button id="btn_next" className={styles.btnStyle} onClick={() => { setShowResumeSecond(true); setShowResumeFirst(false) }}>Next</button>
                    </div>}
                </div>
                
                <div className={styles.btnTwo}>
                    {!hideEdit && <button onClick={() => setOpenShare(true)} className={styles.shareButton}><img alt="Share" src={shareBtn} /> </button>}
                    {!hideEdit && <button onClick={() => setInviteShare(true)} className={styles.inviteButton}><img alt="invite" src={inviteBtn} /></button>}
                    {/* <button onClick={() => Download()} className={styles.buyButtun}><img src={downloadCv} /></button> */}
                    <button className={styles.buyButtun} onClick={() => Download()}>
                    {userData?.campaignSourceURL.includes('careerCentre') ? (
                        <>
                        <img className={styles.offerImage} src={offerCv} alt="Offer CV" />
                        <img src={downloadCv20} alt="Download CV" />
                        </>
                    ) : (
                        <img src={downloadCv200} alt="Download CV" />
                    )}
                    </button>
                </div>
            </div>

        </div >
    )
}

export default CandidateResume;