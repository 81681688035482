import { axiosInstance } from "./axiosInstance";
import { endPoints } from "./endPoints";

export const candidateRegister = (data) => {
    return axiosInstance({ method: "post", url: endPoints.candidate.register, data });
};

export const candidateOnBoard = (data, params = {}) => {
    return axiosInstance({ method: "put", url: endPoints.candidate.onBoard, data, params });
};

export const candidateLogin = (data, params = {}) => {
    return axiosInstance({ method: "post", url: endPoints.common.login, data, params });
}

export const requestOtpForLogin = (data) => {
    return axiosInstance({ method: "post", url: endPoints.otp.requestOtpforLogin, data });
};

export const candidateUpdateProfile = (data) => {
    return axiosInstance({ method: "put", url: endPoints.candidate.updateProfile, data });
};

export const verifyOtpForLogin = (data) => {
    return axiosInstance({ method: "post", url: endPoints.otp.verifyOtpForLogin, data });
};

export const getProfile = (params = {}) => {
    return axiosInstance({ method: "get", url: endPoints.candidate.getProfile, params });
};

export const getSignedUrl = (params = {}) => {
    return axiosInstance({
        method: "get",
        url: `${endPoints.common.getSignedUrl}/${params.id}`
    });
};

export const fileUpload = (data) => {
    return axiosInstance({ method: "post", url: endPoints.fileUpload.upload, data });
};

export const getEducationList = (params = {}) => {
    return axiosInstance({
        method: "get",
        url: endPoints.common.getEducationList,
        params
    });
};

export const getPositionList = (params = {}) => {
    const URL = params ? endPoints.common.getPositionList + "?educationSort" + params : endPoints.common.getPositionList;
    return axiosInstance({
        method: "get",
        url: URL,
        params
    });
};

export const getExperienceList = (params = {}) => {
    return axiosInstance({
        method: "get",
        url: endPoints.common.getExperienceList,
        params
    });
};

export const getSpecializationList = (params = {}) => {
    return axiosInstance({
        method: "get",
        url: endPoints.common.getSpecializationList,
        params
    });
};

export const getCity = (data = {}) => {
    return axiosInstance({
        method: "get",
        url: endPoints.common.cityListByState,
        data
    });
};

export const getIndustryList = (params = {}) => {
    return axiosInstance({
        method: "get",
        url: endPoints.common.getIndustryList,
        params
    });
};

export const getBioTags = (params = {}) => {
    return axiosInstance({
        method: "get",
        url: endPoints.common.getBioTags,
        params
    });
};

export const getVisionTags = (params = {}) => {
    return axiosInstance({
        method: "get",
        url: endPoints.common.getVisionTages,
        params
    });
};

export const getSkillsList = (params = {}) => {
    return axiosInstance({
        method: "get",
        url: endPoints.common.getSkills,
        params
    });
    //return { data: { data: ["test1", "test2", "test3"] } }
};

export const createEmployerSubscription = (data) => {
    return axiosInstance({
        method: "post",
        url: endPoints.subscription.createEmployerSubscription,
        data
    });
};

export const subscriptionTransactionEnd = (data) => {
    return axiosInstance({
        method: "post",
        url: endPoints.subscription.subscriptionTransactionEnd,
        data
    });
};

export const createUser = (data) => {
    return axiosInstance({
        method: "post",
        url: endPoints.candidate.register,
        data
    });
};

export const updateCampignSource = (data) => {
    return axiosInstance({
        method: "put",
        url: endPoints.common.tempOnboard,
        data
    });
};

