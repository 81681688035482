import React, { useState, useEffect, useRef } from 'react';
import styles from './DesiredJob.module.scss';
import { getPositionList, getCity, getExperienceList } from '../../services/Api/candidate';
import JobPositionPopup from '../popups/Aspiration/JobPositionPopup';
import { getSpecializationLists } from '../../util/common';
import closeJob from '../../assets/images/closetran.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setProfileData } from '../../store/reducer';
import { callcandidateProfileUpdate } from '../../util/common';
import { showLoader, hideLoader, getCookie } from '../../util/helper';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { customStyles } from "../../util/common";
import cellHelp from '../../assets/images/needHelp.png';



const DesiredJob = (props) => {
    const { t, i18n } = useTranslation('common');
    const { setProgressCount, setShowProgressBar } = props;
    const { profileData } = useSelector(state => state);
    const navigate = useNavigate();
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    const { city, state, desiredDesignation, desiredSalary, candidateExperience, specialization, isStudent } = userData || profileData;
    const [positionData, setPositionData] = useState([]);
    const [openPositionPopup, setOpenPositionPopup] = useState(false);
    const [specializationData, setSpecializationData] = useState([]);
    const [update, setUpdate] = useState(false);
    const [salaryData, setSalaryData] = useState([]);
    const [selectedSalary, setSelectedSalary] = useState("");
    const [cityData, setCityData] = useState([]);
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [selectedJobPosition, setSelectedJobPosition] = useState("");
    const [experienceData, setExperienceData] = useState("");
    const [selectedExperience, setSelectedExperience] = useState("Fresher");
    const [selectedYearOfExp, setselectedYearOfExp] = useState("0");
    const [positionError, setPositionError] = useState("");
    const [cityError, setCityError] = useState("");
    const [salaryError, setSalaryError] = useState("");
    const [experienceError, setExperienceError] = useState("");
    const [callOnce, setCallOnce] = useState(false);
    const [issStudent, setIsStudent] = useState(false);
    const [selectedPostionKey, setSelectedPositionKey] = useState(0)
    const [selectedPostionUId, setSelectedPositionUid] = useState(0)
    const dispatch = useDispatch();

    const totalProgress = 100;
    const totalFields = 6;
    const calculateProgress = Math.round(totalProgress / totalFields);
    const positionRef = useRef();
    const studentRef = useRef();


    useEffect(() => {
        if (!callOnce) {
            let progressbarSize = 0;
            getJobPosition();
            getSalaryData();
            getCityList();
            getJobExperience();
            if (desiredDesignation) {
                setSelectedJobPosition(desiredDesignation);
                progressbarSize = progressbarSize + calculateProgress;
            }
            if (desiredSalary) {
                setSelectedSalary(desiredSalary);
                progressbarSize = progressbarSize + calculateProgress;
            }
            if (city) {
                setSelectedCity(city);
                progressbarSize = progressbarSize + calculateProgress;
            }
            if (state) {
                setSelectedState(state);
                progressbarSize = progressbarSize + calculateProgress;
            }
            if (candidateExperience) {
                setSelectedExperience(candidateExperience);
                progressbarSize = progressbarSize + calculateProgress;
            }
            if (specialization && specialization.length !== 0) {
                let specializValues = [];
                specialization.map((result) => {
                    specializValues.push({ isSelected: true, value: result, label: result })
                })
                setSpecializationData(specializValues)
                progressbarSize = progressbarSize + calculateProgress;
            }
            setIsStudent(isStudent)
            setProgressCount(45)
            setCallOnce(true);
            setShowProgressBar(true);

            if (isStudent) {
                studentRef.current.checked = true;
                setIsStudent(true);
            } else {
                studentRef.current.checked = false;
                setIsStudent(false)
            }
        }
    }, [update])
    const getCityList = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getCity();
            setCityData(
                data && data.length > 0 && data.map(({ stateName, districtName }) => {
                    let data = districtName.map((item) => { return { label: item, value: item, state: stateName } });
                    return {
                        label: stateName,
                        options: data
                    }
                })
            );
        } catch (error) {
            setCityData([])
        }
    }
    const getSalaryData = () => {
        let data = [];
        let startingSalary = 5000;
        for (let i = startingSalary; i <= 150000; i += 500) {
            let obj = {
                label: i,
                value: i
            }
            data.push(obj);
        }
        setSalaryData(data);

    }
    const getJobPosition = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getPositionList();
            setPositionData(
                data && data.length > 0 && data.map(({ position, positionId, positionUid }) => {
                    return {
                        label: position,
                        value: positionId,
                        positionUid
                    }
                })
            );
        } catch (error) {
        }
    }

    const getJobExperience = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getExperienceList();
            setExperienceData(
                data && data.length > 0 && data.map(({ experience, experienceYears }) => {
                    return {
                        label: experience,
                        value: experienceYears
                    }
                })
            );
        } catch (error) {
        }
    }

    const changehandler = async (e) => {
        e.preventDefault();
        setPositionError("");
        const value = e.target.value.split('-');
        var ele = document.getElementById("jobPosition");
        var text = ele.options[ele.selectedIndex].text;
        setSelectedJobPosition(text)
        const results = await getSpecializationLists(value[0]);
        setSpecializationData(
            results && results[0] && results[0].specialization && results[0].specialization.length > 0 && results[0].specialization.map((item) => {

                return {
                    label: item,
                    value: item,
                    isSelected: false
                }
            })
        );
        setOpenPositionPopup(!openPositionPopup)
        setUpdate(!update)
    }

    const salaryHandler = (e) => {
        setSalaryError("")
        setSelectedSalary(e.value)
    }

    const selectHandler = (index) => {
        specializationData[index].isSelected = false;
        setSpecializationData(specializationData);
        setUpdate(!update)
        const checkSpecialization = specializationData.filter((val) => val.isSelected === true);
        if (checkSpecialization.length === 0) {
            document.getElementById("jobPosition").value = "";
            setSpecializationData([]);
            setSelectedJobPosition("");
            positionRef.current.value = "";
        }
    }

    const cityChangeHandler = (e) => {
        //  const cityAndState = e.target.value.split("-")
        setCityError("");
        setSelectedState(e.state);
        setSelectedCity(e.label)
    }
    const experienceChangeHandler = (e) => {
        const getYears = experienceData.filter((val) => {
            return val.label === e.target.value
        })
        //return
        const value = e.target.value;
        setExperienceError("");
        setSelectedExperience(value);
        setselectedYearOfExp((value && getYears[0].value) ? getYears[0].value : "0")
    }

    const validation = async () => {

        if (selectedJobPosition === "") {
            return setPositionError("Please select your job");
        }

        if (selectedSalary === "") {
            return setSalaryError("Please select your salary")
        }
        if (selectedCity === "") {
            return setCityError("Please select your city")
        }
        if (!issStudent && selectedExperience === "") {
            return setExperienceError("Please select your experience")
        }
        return true
    }

    const saveNext = async () => {
        try {
            const checkSpecialization = specializationData.filter((val) => val.isSelected === true);
            if (selectedJobPosition !== "" && checkSpecialization.length === 0) {
                // setOpenPositionPopup(true)
                //positionRef.current.value = "";
                //positionRef.current.focus();
                //positionRef.current.click();
                const results = await getSpecializationLists(selectedPostionKey);
                setSpecializationData(
                    results && results[0] && results[0].specialization && results[0].specialization.length > 0 && results[0].specialization.map((item) => {

                        return {
                            label: item,
                            value: item,
                            isSelected: false
                        }
                    })
                );
                setOpenPositionPopup(!openPositionPopup)
                return false;
            }
            const isValidated = await validation();
            if (userData !== profileData && isValidated) {
                const spacializationArray = [];
                specializationData.map((result) => {
                    if (result.isSelected) {
                        spacializationArray.push(result.value)
                    }
                })
                const submitObj = {
                    desiredSalary: selectedSalary,
                    city: selectedCity,
                    state: selectedState,
                    desiredDesignation: selectedJobPosition,
                    specialization: spacializationArray,
                    candidateExperience: issStudent ? "Fresher" : selectedExperience,
                    isStudent: issStudent,
                    yearsOfExperience: issStudent ? "0" : selectedYearOfExp,
                    designationId: selectedPostionKey,
                    designationUid: selectedPostionUId
                }
                const dubprofileData = JSON.parse(JSON.stringify(userData));
                const reqObj = Object.assign(dubprofileData, submitObj);
                localStorage.setItem("userData", JSON.stringify(reqObj));
                if (reqObj._id) {
                    delete reqObj._id;
                }
                dispatch(setProfileData(submitObj));
                if (!getCookie("newUserMobileNo")) {
                    await callcandidateProfileUpdate(reqObj);
                }
                hideLoader();
            }
            navigate('/employmentStatus')
            // if (isValidated) {
            //     issStudent ? navigate('/achievements') :
            //         navigate('/employmentStatus')
            // }

        } catch (e) {

        }
    }

    const positionHandler = async (e) => {
        const value = e.target.value;
        const filteredArray = positionData.filter(ele => ele.label === value);
        let key = "";
        let postionUid = "";
        setPositionError("");
        if (filteredArray.length !== 0) {
            key = filteredArray[0].value;
            postionUid = filteredArray[0].positionUid;
            setSelectedPositionKey(key);
            setSelectedPositionUid(postionUid);
            setSelectedJobPosition(value)
            showLoader();
            const results = await getSpecializationLists(key);
            setSpecializationData(
                results && results[0] && results[0].specialization && results[0].specialization.length > 0 && results[0].specialization.map((item) => {


                    return {
                        label: item,
                        value: item,
                        isSelected: false
                    }
                })
            );
            hideLoader();
            setOpenPositionPopup(!openPositionPopup)
            setUpdate(!update)
        }
        else {
            setSpecializationData([]);
            setSelectedJobPosition(value)

        }
    }


    return (
        <div>
            {openPositionPopup && <JobPositionPopup isOpen={openPositionPopup} isClose={() => setOpenPositionPopup(!openPositionPopup)}
                specialization={specializationData} setSpecializationData={setSpecializationData} positionRef={positionRef} />}
            <form onSubmit={e => e.preventDefault()}>
                <div className="page slide-page">
                    <div className={styles.TitleHead}>
                        <h3 dangerouslySetInnerHTML={{ __html: t('DesiredJob.title') }}></h3>
                    </div>

                    <div className={styles.formInputAre}>
                        <div className={styles.FormGroup}>
                            <label>{t('DesiredJob.positionLabel')}</label>
                            <input list="jobPosition" ref={positionRef} onChange={(e) => positionHandler(e)} className={styles.formControl} value={selectedJobPosition} />
                            <datalist id="jobPosition">
                                <option value="">{t('DesiredJob.positionChoose')} </option>
                                {positionData.map((result, index) => {
                                    if (result.label === selectedJobPosition) {
                                        return <option key={`position${index}`} style={{ backgroundColor: "#ff6600" }} selected value={result.label.trim()} >{result.label.trim()}</option>
                                    } else {
                                        return <option key={`position${index}`} value={result.label.trim()} >{result.label.trim()}</option>
                                    }
                                }
                                )}
                            </datalist>
                            {/* <select id="jobPosition" className={styles.formControl} onChange={(e) => changehandler(e)} defaultValue={selectedJobPosition}>
                                <option value="">Choose your Position </option>
                                {positionData.map((result, index) => {
                                    if (result.label === selectedJobPosition) {
                                        return <option key={`position${index}`} style={{ backgroundColor: "#ff6600" }} selected value={`${result.value}-${result.positionUid}`} >{result.label}</option>
                                    } else {
                                        return <option key={`position${index}`} value={`${result.value}-${result.positionUid}`} >{result.label}</option>
                                    }
                                }
                                )}
                            </select> */}
                            {positionError && <span className="errorMessage">{positionError}</span>}
                        </div>
                        {(specializationData && specializationData.length !== 0) && <div className={styles.FormGroup}>
                            <div className={styles.specialisationNew}>
                                <label>{t('DesiredJob.specializeLabel')}</label>
                                <div className={styles.spaciList}>
                                    {specializationData.map(({ isSelected, value }, index) => {

                                        return isSelected && <span key={`specilization${index}`} style={{ position: "relative" }}>{value}
                                            <img alt="Close" src={closeJob} onClick={() => selectHandler(index, "remove")} style={{ position: "absolute", top: "-7px", right: "-8px" }} />
                                        </span>
                                    })}
                                </div>
                            </div>
                        </div>}
                        <div className={styles.checkBoxGroup}>
                            <div className={styles.FormGroup}>
                                <h3>{t('DesiredJob.collegeLabel')}</h3>
                                <div className={styles.newSelect}>
                                    <ul>
                                        <li>
                                            <label style={{ fontSize: "14px" }}>{t('DesiredJob.student')}</label>
                                            <label className={styles.checkbox}>
                                                <input className={styles.checkboxInput} type="checkbox" onClick={e => setIsStudent(!issStudent)} ref={studentRef} />
                                                <span className={styles.checkboxInner}></span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className={styles.FormGroup}>
                            <label>{t('DesiredJob.salaryLabel')}<span style={{ color: "#ff6600", fontWeight: 500 }}></span></label>
                            {/* <select className={styles.formControl} onChange={(e) => salaryHandler(e)} value={selectedSalary}>
                                <option value="">{t('DesiredJob.salaryChoose')} </option>
                                {salaryData.map(({ value }, index) => {
                                    if (desiredSalary === value) {
                                        return <option key={`salary${index}`} style={{ backgroundColor: "#ff6600" }} selected value={value}>₹ {value}</option>
                                    } else {
                                        return <option key={`salary${index}`} value={value}>₹ {value}</option>
                                    }
                                })}
                            </select> */}
                            <Select value={{ label: selectedSalary ? selectedSalary : t('DesiredJob.salaryChoose'), value: selectedSalary ? selectedSalary : "" }}
                                styles={customStyles}
                                onChange={(e) => salaryHandler(e)}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                options={salaryData} />
                            {salaryError && <span className="errorMessage">{salaryError}</span>}
                        </div>
                        <div className={styles.FormGroup}>
                            <label>{t('DesiredJob.cityLabel')}</label>
                            {/* <select className={styles.formControl} onChange={(e) => cityChangeHandler(e)}>
                                <option value="">{t('DesiredJob.cityChoose')}</option>
                                {cityData.map(({ label, options }) => {
                                    return <optgroup label={label}>
                                        {options.map((item, index) => {
                                            if (selectedCity === item.label) {
                                                return <option key={`city${index}`} style={{ backgroundColor: "#ff6600" }} selected value={`${item.label}-${item.value}`}>{item.label}</option>
                                            } else {
                                                return <option key={`city${index}`} value={`${label}-${item.value}`}>{item.label}</option>
                                            }
                                        }
                                        )}
                                    </optgroup>
                                })}
                            </select> */}
                            <Select value={{ label: selectedCity ? selectedCity : t('DesiredJob.cityChoose'), value: selectedCity ? selectedCity : "", state: selectedState ? selectedState : "" }}
                                styles={customStyles}
                                onChange={(e) => cityChangeHandler(e)}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                options={cityData} />
                            {cityError && <span className="errorMessage">{cityError}</span>}
                        </div>
                        {!issStudent && <div className={styles.FormGroup}>
                            <label>{t('DesiredJob.experienceLabl')}</label>
                            <select className={styles.formControl} value={selectedExperience} onChange={(e) => experienceChangeHandler(e)}>
                                <option value="">{t('DesiredJob.chooseExperience')}</option>
                                {experienceData.length !== 0 && experienceData.map((item, index) => {
                                    if (selectedExperience === item.label) {
                                        return <option key={`experience${index}`} style={{ backgroundColor: "#ff6600" }} selected value={item.label}>{item.label}</option>
                                    } else {
                                        return <option key={`experience${index}`} value={item.label}>{item.label}</option>
                                    }
                                })}
                            </select>
                            {experienceError && <span className="errorMessage">{experienceError}</span>}
                        </div>}

                    </div>

                    {/* <div>Are you in school or college</div>
                    <ul>
                        <li>
                            <label style={{ color: "black" }}>I’m just a Student</label>
                            <label className={styles.checkbox}>
                                <input className={styles.checkboxInput} type="checkbox" name="dl" onClick={e => setIsStudent(!issStudent)} ref={studentRef} />
                                <span className={styles.checkboxInner}></span>
                            </label>
                        </li>
                    </ul> */}

                    <div className={styles.saveNext}>
                        <button className={styles.next} onClick={() => saveNext()}>{t('common.save')} </button>
                    </div>
                    <div className={styles.Back}>
                        <button className={styles.next} onClick={() => navigate('/resumeedit')}>{t('common.back')}</button>
                    </div>
                </div>
                <div className={styles.newHelp}>
                    <div className={styles.helpCall}>
                    <a href="tel:8595141564">
                        <img  src={cellHelp}/>
                    </a>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default DesiredJob;