import style from './ResumeFirst.module.scss';
import { showLoader, hideLoader } from '../../util/helper'
import { useState } from 'react';
import { useEffect } from 'react';
import { callSignedAPi } from "../../util/common";
import moment from "moment";

const ResumeFirst = () => {
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);

    const { fullName, desiredDesignation, profilePicture, email, whatsappMobile, gender,
        birthDate, language, bikeOwner, drivingLicense, carOwner, cvBioEncrypted, cvVision,
        desiredSalary, specialization, educationDetails, experienceDetails, yearsOfExperience } = userData || {};
    const [profilePicURL, setProfilePicURL] = useState("");
    let langSplit = [language];
    if (language && language.includes(",")) {
        langSplit = language.split(",");
    }

    useEffect(() => {
        getSignedApi();
    }, [])

    const getSignedApi = async () => {
        try {
            showLoader();
            let params = {
                id: profilePicture
            }
            const result = await callSignedAPi(params);
            if (result) {
                setProfilePicURL(result)
            }
            hideLoader();
        } catch (e) {
            setProfilePicURL('')
            hideLoader();
        }
    }
    return (
        <div>

            <section className={style.resume}>
                <div className={style.resume_top}>
                    <div className={style.rtop_left}>
                        <p className={style.name}>{fullName}</p>
                        <p className={style.role}>{desiredDesignation}</p>
                    </div>
                    <div className={style.rtop_right}>
                        <p>{email}</p>
                        <p>+91 {whatsappMobile}</p>
                    </div>
                    <div className={style.r_profile_pic}>
                        <img src={profilePicURL} alt="profile_pic" />
                    </div>
                </div>
                {/* <div className={style.resume_center}>
                    <h2>About ME</h2>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorem accusamus a incidunt architecto ut voluptates suscipit earum fuga pariatur dolores autem quibusdam mollitia, ea perspiciatis perferendis tenetur provident excepturi laudantium.</p>
                </div> */}
                <div className={style.resume_bottom}>
                    <div className={style.rbottom_left}>
                        <div className={style.rb_skills}>
                            <h2>Contact</h2>
                            <ul>
                                <li>
                                    <p><i className={style.fa_solid}></i></p>
                                    <p>{email}</p>
                                </li>
                                <li>
                                    <p><i className={style.fa_solid}></i></p>
                                    <p>{whatsappMobile}</p>
                                </li>
                                <li>
                                    <p><i className={style.fa_solid}></i></p>
                                    <p>{gender}</p>
                                </li>
                                <li>
                                    <p><i className={style.fa_solid}></i></p>
                                    <p>{moment(birthDate).format("YYYY-MM-DD")}</p>
                                </li>
                                <li>
                                    <p><i className={style.fa_solid}></i></p>
                                    <p>Photoshop Editing</p>
                                </li>
                            </ul>
                        </div>
                        <div className={style.rb_hobbies}>
                            <h2>SKILLS</h2>
                            <ul>
                                <li>
                                    <p><i className={style.fa_solid}></i></p>
                                    <p>Football</p>
                                </li>
                            </ul>
                        </div>
                        <div className={style.rb_hobbies}>
                            <h2>LANGUAGES</h2>
                            <ul>
                                {langSplit.map((result) => {
                                    return <li>
                                        <p><i className={style.fa_solid}></i></p>
                                        <p>{result}</p>
                                    </li>
                                })}

                            </ul>
                        </div>
                        <div className={style.rb_hobbies}>
                            <h2>DRIVING INFORMATION </h2>
                            <ul>
                                {drivingLicense && <li>
                                    <p><i className={style.fa_solid}></i></p>
                                    <p>I have Driving License</p>
                                </li>}
                                {carOwner && <li>
                                    <p><i className={style.fa_solid}></i></p>
                                    <p>I have Car</p>
                                </li>}
                                {bikeOwner && <li>
                                    <p><i className={style.fa_solid}></i></p>
                                    <p>I have Bike/Scooty</p>
                                </li>}
                            </ul>
                        </div>
                    </div>
                    <div className={style.rbottom_right}>
                        <div className={style.r_education}>
                            <h2>BIO & VISION</h2>
                            <div>
                                <p>{cvBioEncrypted.toString()}</p>
                            </div>
                            <br></br>
                            <div>
                                <p>{cvVision.toString()}</p>
                            </div>
                        </div>
                        <div className="r_jobs">
                            <h2>DESIRED JOB</h2>
                            <ul>
                                <li>
                                    <div className={style.r_ed_left}>
                                        <p>Position</p>
                                    </div>
                                    <div className={style.r_ed_right}>
                                        <p>{desiredDesignation}</p>
                                    </div>
                                </li>
                                <li>
                                    <div className={style.r_ed_left}>
                                        <p>Specilaization</p>
                                    </div>
                                    <div className={style.r_ed_right}>
                                        {specialization.map((result) => {
                                            return <p>{result.value}</p>
                                        })}
                                    </div>
                                </li>
                                <li>
                                    <div className={style.r_ed_left}>
                                        <p>Desired Salary(By Month)</p>
                                    </div>
                                    <div className={style.r_ed_right}>
                                        <p>{desiredSalary}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="r_jobs">
                            <h2>EDUCATION</h2>
                            <ul>
                                {educationDetails.length !== 0 && educationDetails.map((result, index) => {
                                    const { educationTitle, branch, degree } = result;
                                    return <div key={index}>
                                        <li>
                                            <div className={style.r_ed_left}>
                                                <p>Studied</p>
                                            </div>
                                            <div className={style.r_ed_right}>
                                                <p>{educationTitle}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className={style.r_ed_left}>
                                                <p>Degree</p>
                                            </div>
                                            <div className={style.r_ed_right}>
                                                <p>{degree}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className={style.r_ed_left}>
                                                <p>Branch</p>
                                            </div>
                                            <div className={style.r_ed_right}>
                                                <p>{branch}</p>
                                            </div>
                                        </li>
                                    </div>
                                })}

                            </ul>
                        </div>
                        <div className="r_jobs">
                            <h2>WORKING EXPERIENCE {yearsOfExperience} Years</h2>
                            <ul>
                                {experienceDetails.length !== 0 && yearsOfExperience !== "0" && experienceDetails.map((result, index) => {
                                    const { lastCompany, industry, lastPosition, fromMonth, toMonth } = result;
                                    return <div key={index}>
                                        <li>
                                            <div className={style.r_ed_left}>
                                                <p>{industry}</p>
                                                <p>{lastCompany}</p>
                                            </div>
                                            <div className={style.r_ed_right}>
                                                <p>{fromMonth}</p>
                                                <p>{toMonth}</p>
                                            </div>
                                        </li>
                                    </div>
                                })}

                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ResumeFirst;