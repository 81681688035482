import './App.scss';
import RouterComponent from './routes/index';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import PubSub from 'pubsub-js';
import { useTranslation } from "react-i18next";


function App(props) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchLoader();
  }, []);

  const fetchLoader = () => {
    const loaderResponse = PubSub.subscribe('loader', (msg, data) => {
      setLoading(data)
    });
  }
  return (
    <div className="App" >
      {loading && <div className="loader">
        <TailSpin color="#FF6600" height={80} width={80} />
      </div>}
      <RouterComponent {...props} />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='dark'
      />

    </div >
  );
}

export default App;
