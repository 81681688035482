import React, { useEffect, useState } from "react";
import styles from "./Profile.module.scss";
import removeDelet from "../../assets/images/deletRemove.svg"
import checkIcon from "../../assets/images/check.png";
import deleteIcon from "../../assets/images/del.png";
import profileImg from "../../assets/images/idproff.png";
import UploadImg from "../../assets/images/uploadImg.png";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import FacebookLogin from '@greatsumini/react-facebook-login';
import { callSignedAPi, callUploadApi, callcandidateProfileUpdate, constructParams } from '../../util/common';
import { showLoader, hideLoader, getCookie, setCookie } from '../../util/helper';
import { newUserPhoneNo, setProfileData } from '../../store/reducer';
import { useNavigate } from 'react-router-dom';
import { useRef } from "react";
import { toast } from 'react-toastify';
import Confirmation from '../popups/Confirmation/Confirmation';
import Preview from '../popups/Preview/Preview';
import { useTranslation } from 'react-i18next';
// import HelpCall from '../HelpCall/HelpCall';
import { createUser, getProfile, candidateOnboard } from "../../services/Api/candidate";
import cellHelp from '../../assets/images/needHelp.png';


const Profiles = (props) => {
    const { t, i18n } = useTranslation('common');
    const { setProgressCount, setShowProgressBar } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    const { profileData, newUserMobileNo, campignSource, invitedBy } = useSelector(state => state);
    const { fullName, gender, birthDate, email, aadhaarMobile, aadhaarProof, personalVideo, resumeDocument, profilePicture } = userData || profileData;
    const [candidateName, setCandidateName] = useState(fullName ? fullName : "");
    const [candidateDOB, setCandidateDOB] = useState(birthDate ? birthDate : "");
    const [candidateGender, setCandidateGender] = useState(gender ? gender : "Male");
    const [candidateEmail, setCandidateEmail] = useState(email ? email : "");
    const [candidateAlterNo, setCandidateAlterNo] = useState(aadhaarMobile ? aadhaarMobile : "");
    const [candidateIdProof, setCandidateIdProof] = useState("");
    const [candidateIdProofKey, setCandidateIdProofKey] = useState("");
    const [candidateShortVideo, setCandidateShortVideo] = useState("");
    const [candidateShortVideoKey, setCandidateShortVideoKey] = useState("");
    const [candidateCVProof, setCandidateCVProof] = useState("");
    const [candidateCVProofKey, setCandidateCVProofKey] = useState("");
    const [candidateprofilePic, setCandidateprofilePic] = useState("");
    const [candidateprofilePicKey, setCandidateprofilePicKey] = useState("");
    const [dobError, setdobError] = useState("");
    const [emailError, setemailError] = useState("");
    const [mobileError, setmobileError] = useState("");
    const [nameError, setnameError] = useState("");
    const [calledOnce, setCalledOnce] = useState(false);
    const [isupdate, setIsUpdate] = useState(false);
    const [selectedFileName, setSelectedFileName] = useState("");
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [openPreview, setOpenPreview] = useState(false);
    const [selectedPreviewFile, setOpenPreviewFile] = useState(false);
    const [previewFileKey, setPreviewFileKey] = useState("");
    const [title, setTitle] = useState("");
    const totalProgress = 100;
    const totalFields = 9;
    const calculateProgress = Math.round(totalProgress / totalFields);

    const dobRef = useRef();

    useEffect(() => {
        if (!calledOnce) {
            let progressbarSize = 0;
            if (aadhaarProof) {
                progressbarSize = progressbarSize + calculateProgress;
                setCandidateIdProofKey(aadhaarProof);
                getSignedApi("idProof", aadhaarProof);
            }
            if (personalVideo) {
                setCandidateShortVideoKey(personalVideo);
                getSignedApi("shortVideo", personalVideo);
                progressbarSize = progressbarSize + calculateProgress;
            }
            if (resumeDocument) {
                setCandidateCVProofKey(resumeDocument);
                getSignedApi("cvProof", resumeDocument);
                progressbarSize = progressbarSize + calculateProgress;
            }
            if (profilePicture) {
                setCandidateprofilePicKey(profilePicture);
                getSignedApi("profilePic", profilePicture);
                progressbarSize = progressbarSize + calculateProgress;
            }
            if (candidateGender) {
                setCandidateGender(candidateGender)
                progressbarSize = progressbarSize + calculateProgress;
            }
            if (email) {
                progressbarSize = progressbarSize + calculateProgress;
            }
            if (fullName) {
                progressbarSize = progressbarSize + calculateProgress;
            }
            if (birthDate) {
                progressbarSize = progressbarSize + calculateProgress;
            }
            if (gender) {
                progressbarSize = progressbarSize + calculateProgress;
            }

            if (candidateAlterNo === "" && newUserMobileNo) {
                setCandidateAlterNo(newUserMobileNo)
            }
            // if (candidateGender) {
            //     setCandidateGender(candidateGender)
            // }
            setCalledOnce(true);
            setProgressCount(60);
            setShowProgressBar(true);
            if (getCookie("lang")) {
                i18n.changeLanguage(getCookie("lang"))
            }
        }
    }, [candidateIdProofKey, candidateIdProofKey, candidateCVProofKey, candidateprofilePicKey, isupdate, openConfirmation]);

    const updateState = async (result, from) => {

        if (result) {
            if (from === 'idProof') {
                setCandidateIdProof(result);
            }
            if (from === 'shortVideo') {
                setCandidateShortVideo(result);
            }
            if (from === 'cvProof') {

                setCandidateCVProof(result);
            }
            if (from === 'profilePic') {
                setCandidateprofilePic(result);
            }
        }
    }
    const getSignedApi = async (from, proof) => {
        try {
            showLoader();
            let params = {
                id: proof
            }
            const result = await callSignedAPi(params);
            if (result) {
                await updateState(result, from);
            }
            hideLoader();
        } catch (e) {
            updateState("", from)
            hideLoader();
        }
    }


    // const facebookSuccess = (res) => {
    //     const { accessToken, userID } = res;
    //     console.log("facebookSuccess---------------->", res)
    // }

    // const facebookFail = (res) => {
    //     console.log("facebookFail---------------->", res)
    // }

    // const facebookProfileSuccess = (res) => {
    //     console.log("facebookProfileSuccess---------------->", res)
    //     const { name, email, picture } = res;
    //     const { data } = picture;
    //     if (name) { setCandidateName(name) }
    //     if (email) { setCandidateEmail(email) }
    //     if (data.url) {
    //         //setCandidateprofilePic(data.url)
    //         fetch(data.url)
    //             .then(res => res.blob()) // Gets the response and returns it as a blob
    //             .then(async (blob) => {
    //                 const file = new File([blob], name, {
    //                     type: "image/jpg",
    //                 });
    //                 const result = await callUploadApi(file)
    //                 setCandidateprofilePic(result.fileUrl);
    //                 setCandidateprofilePicKey(result.fileKey)
    //             });
    //     }
    // }

    const fileUploadHandler = async (res) => {
        res.preventDefault();
        if (res.target.files) {
            showLoader();
            try {
                const files = res.target.files[0];
                const result = await callUploadApi(files);
                if (result.fileKey) {
                    if (res.target.id === 'id_proof') {
                        setCandidateIdProof(result.fileUrl);
                        setCandidateIdProofKey(result.fileKey)
                    }
                    if (res.target.id === 'short_video') {
                        setCandidateShortVideo(result.fileUrl);
                        setCandidateShortVideoKey(result.fileKey);
                    }
                    if (res.target.id === 'cv_proof') {
                        setCandidateCVProof(result.fileUrl);
                        setCandidateCVProofKey(result.fileKey);
                    }
                    if (res.target.id === 'profile_pic') {
                        setCandidateprofilePic(result.fileUrl);
                        setCandidateprofilePicKey(result.fileKey);
                    }
                    //}
                    setIsUpdate(!isupdate)
                    hideLoader();
                }
            } catch (e) {
                hideLoader();
            }

        }

    }

    const preViewFiles = async (fileName, title, fileKey) => {
        // window.open(fileName, 'MyWindow', 'width=600,height=300');
        // return false;
        setOpenPreviewFile(fileName);
        setPreviewFileKey(fileKey);
        setTitle(title);
        setOpenPreview(true)
    }

    const callConfirmation = (name) => {
        setSelectedFileName(name);
        setOpenConfirmation(true)
    }

    const deleteUploadedFile = (name) => {
        if (name === "idProof") {
            setCandidateIdProof("");
            setCandidateIdProofKey("")
        }
        if (name === "shortVideo") {
            setCandidateShortVideo("");
            setCandidateShortVideoKey("");
        }
        if (name === "cvProof") {
            setCandidateCVProof("");
            setCandidateCVProofKey("");
        }
        setIsUpdate(!isupdate)
    }

    const getProfileData = async () => {
        try {
            showLoader();
            let {
                data: {
                    data
                }
            } = await getProfile();

            hideLoader();
            return data;

        } catch (error) {
            hideLoader();
        }
    }


    const saveNext = async () => {
        try {
            if (candidateName.trim() === "") {
                return setnameError("please enter your name")
            }
            if (candidateDOB === "") {
                return setdobError("Please enter DOB")
            }
            if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(candidateEmail.trim())) {
                return setemailError("Please enter valid email")
            }
            if (!/^[6-9]\d{9}$/.test(candidateAlterNo)) {
                return setmobileError("please enter valid mobile number")
            }

            let candidateId = "";

            showLoader();
            if (getCookie("newUserMobileNo")) {
                const userObj = {
                    language: getCookie('lang') === 'hi' ? "Hindi" : "English",
                    fullName: candidateName.trim(),
                    whatsappMobile: candidateAlterNo,
                    gender: candidateGender
                }
                // let {
                //     data: {
                //         data
                //     }
                // } = await createUser(userObj);
                candidateId = getCookie("candidateId");
                //setCookie("candidateId", data._id);
                // if (data && !data._id) {
                //     toast.error("Please try again later");
                //     return false;
                // }
            }

            setTimeout(async () => {
                const submitObj = {
                    fullName: candidateName.trim(),
                    birthDate: candidateDOB,
                    gender: candidateGender,
                    email: candidateEmail.trim(),
                    aadhaarMobile: candidateAlterNo.trim(),
                    aadhaarProof: candidateIdProofKey,
                    personalVideo: candidateShortVideoKey,
                    resumeDocument: candidateCVProofKey,
                    profilePicture: candidateprofilePicKey,
                    GPSCoordinates: { lat: 0, lng: 0 },
                    campaignSourceURL: campignSource ? campignSource : "myCv",
                    invitedBy: invitedBy ? invitedBy : "",
                }
                if (getCookie("newUserMobileNo")) {
                    submitObj._id = getCookie("candidateId")
                    if (getCookie('lang') === 'en') {
                        submitObj.language = "English";
                    } else {
                        submitObj.language = "Hindi";
                    }
                }
                const dubprofileData = userData ? JSON.parse(JSON.stringify(userData)) : {};
                const reqObj = Object.assign(dubprofileData, submitObj);
                localStorage.setItem("userData", JSON.stringify(reqObj));
                if (reqObj._id) {
                    delete reqObj._id;
                }
                dispatch(setProfileData(submitObj));
                if (!getCookie("newUserMobileNo")) {
                    await callcandidateProfileUpdate(reqObj);
                }
                
                hideLoader();
                navigate("/achievements")
            }, 1000);

            //window.location.href = "/education"
        } catch (e) {
            console.log("E--------------->", e)
            const { data } = e || {};
            toast.error((data && data.message) ? data.message : "We are sorry for inconvenience");
            hideLoader();
        }
    }

    const setDOB = (e) => {
        setdobError("")
        setCandidateDOB(new moment(e.target.value, "YYYY-MM-DDTHH:mm").utc().format())
    }

    const setEmail = (e) => {
        setemailError("");
        setCandidateEmail(e.target.value.trim())
    }

    const testfacebook = () => {
        window.FB.ui(
            {
                display: 'popup',
                method: 'share',
                href: 'https://developers.facebook.com/docs/'
            },
            function (response) { console.log("resss-------------->", response) }
        );
    }
    const inputChangeHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === "CandidateName") {
            setnameError("");
            setCandidateName(value)
        }
        if (name === "alterNumber") {
            setmobileError("");
            setCandidateAlterNo(value)
        }
    }
    return (
        <form onSubmit={e => e.preventDefault()}>
            {openConfirmation && <Confirmation isOpen={openConfirmation} isClose={() => setOpenConfirmation(false)} deleteUploadedFile={deleteUploadedFile} selectedFileName={selectedFileName} />}
            {openPreview && <Preview isOpen={openPreview} isClose={() => setOpenPreview(false)} selectedPreviewFile={selectedPreviewFile} title={title} previewFileKey={previewFileKey} />}
            <div className={`page ${styles.slide_page}`}>
                <div className={styles.TitleHead}>
                    <h3 dangerouslySetInnerHTML={{ __html: t("ProfilePage.title") }}></h3>
                    <div className={styles.colTing}>
                        <div className={styles.fileUpload} id="file-upload1">
                            <div className={styles.imageBox}>
                                <input id="profile_pic" accept=".png, .jpg, .jpeg" type="file" name="fileToUpload" style={{ display: "none" }}
                                    onChange={(file) => fileUploadHandler(file)} />
                                <p><img alt='ProfileImg' src={candidateprofilePic ? candidateprofilePic : UploadImg} onClick={() => document.getElementById("profile_pic").click()} />{t('ProfilePage.profilePic')}</p>
                                <img src="" alt="test" />
                            </div>
                            <div className={styles.controls} style={{ display: "none" }}>
                                <input type="file" name="contact_image_1" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.formInputAre}>

                    <div className={styles.FormGroup}>
                        <label>{t("ProfilePage.namelabel")}</label>
                        <input className={styles.formControl} type="text" name="CandidateName" autoComplete="off" onChange={e => inputChangeHandler(e)} placeholder="Enter your full name" value={candidateName} />
                        {nameError && <span className="errorMessage">{nameError}</span>}
                    </div>
                    <div className={styles.genderWidth}>
                        <div className={styles.FormGroup}>
                            <label className="label">{t("ProfilePage.genderLabel")}</label>
                            <select className={styles.formControl} value={candidateGender} onChange={e => setCandidateGender(e.target.value)}>
                                <option>Male</option>
                                <option>Female</option>
                                <option>Other</option>
                            </select>
                        </div>
                    </div>
                    <div className={styles.dobN}>
                        <div className={styles.FormGroup}>
                            <label className="label">{t("ProfilePage.dobLabel")}</label>
                            <input className={styles.formControl} type="date" placeholder="Your birth date" value={moment(candidateDOB).format("YYYY-MM-DD")}
                                onChange={(res) => setDOB(res)} ref={dobRef} onClick={() => dobRef.current.showPicker()} />
                            {dobError && <span className="errorMessage">{dobError}</span>}
                        </div>
                    </div>
                    <div className={styles.FormGroup}>
                        <label>{t("ProfilePage.emailLabel")}</label>
                        <input className={styles.formControl} type="text" placeholder="Enter your email" onChange={(e) => setEmail(e)} value={candidateEmail.trim()} />
                        {emailError && <span className="errorMessage">{emailError}</span>}
                    </div>
                    <div className={styles.FormGroup}>
                        <label>{t("ProfilePage.alterMobile")}</label>
                        <input className={styles.formControl} type="number" maxLength="10" placeholder="Enter your other phone number"
                            name="alterNumber" value={candidateAlterNo} onChange={(e) => inputChangeHandler(e)} pattern="/^-?\d+\.?\d*$/" />
                        {mobileError && <span className="errorMessage">{mobileError}</span>}
                    </div>

                    <div className={styles.document}>
                        <div className={styles.FormGroup}>
                            <label>{t("ProfilePage.uploadLabel")}</label>

                            <ul>
                                <li>
                                    {candidateIdProof && <img alt='DeleteIcon' src={removeDelet} className={styles.deletRem} onClick={() => callConfirmation("idProof")} />}
                                    <div className={styles.fileUploadWrap} onClick={() => document.getElementById("id_proof").click()}>
                                        {candidateIdProof && <img alt='CheckIcon' src={checkIcon} className={styles.checkIconCss} />}
                                        <img alt='IdProof' src={profileImg} />
                                        <input id="id_proof" accept=".png, .jpg, .jpeg" type={candidateIdProof ? "button" : "file"} name="fileToUpload" style={{ display: "none" }}
                                            onChange={(file) => fileUploadHandler(file)} onClick={() => candidateIdProof ? preViewFiles(candidateIdProof, "ID PROOF", candidateIdProofKey) : undefined} />
                                        <p className={styles.fileName}>{candidateIdProofKey ? candidateIdProofKey : t('ProfilePage.idProof')} </p>
                                        <span>Aadhar/Pan/Voter/DL</span>
                                    </div>
                                </li>
                                <li>
                                    {candidateShortVideo && <img alt='DeleteIcon' src={removeDelet} className={styles.deletRem} onClick={() => callConfirmation("shortVideo")} />}
                                    <div className={styles.fileUploadWrap} style={{ margin: "0px 5px 0px" }} onClick={() => document.getElementById("short_video").click()}>
                                        {candidateShortVideo && <img alt='CheckIcon' src={checkIcon} className={styles.checkIconCss} />}
                                        <img alt='ShortVideo' src={profileImg} />
                                        <input id="short_video" accept="video/*" type={candidateShortVideo ? "button" : "file"} name="fileToUpload" style={{ display: "none" }}
                                            onChange={(file) => fileUploadHandler(file)} onClick={() => candidateShortVideo ? preViewFiles(candidateShortVideo, "SHORT VIDEO", candidateShortVideoKey) : undefined} />
                                        <p className={styles.fileName}>{candidateShortVideoKey ? candidateShortVideoKey : t('ProfilePage.shortVideo')}</p>
                                        <span>Upto 1 min.</span>
                                    </div>
                                </li>
                                <li>
                                    {candidateCVProof && <img alt='DeleteIcon' src={removeDelet} className={styles.deletRem} onClick={() => callConfirmation("cvProof")} />}
                                    <div className={styles.fileUploadWrap} onClick={() => document.getElementById("cv_proof").click()}>
                                        {candidateCVProof && <img alt='CheckIcon' src={checkIcon} className={styles.checkIconCss} />}
                                        <img alt='CVProof' src={profileImg} />
                                        <input id="cv_proof" accept="image/*,.pdf" type={candidateCVProof ? "button" : "file"} name="fileToUpload" style={{ display: "none" }}
                                            onChange={(file) => fileUploadHandler(file)} onClick={() => candidateCVProof ? preViewFiles(candidateCVProof, "CV PROOF", candidateCVProofKey) : undefined} />
                                        <p className={styles.fileName}>{candidateCVProofKey ? candidateCVProofKey : t('ProfilePage.resume')}</p>
                                        <span>.pdf / .jpeg</span>
                                    </div>
                                </li>
                            </ul>

                        </div>
                    </div>

                </div>


                <div className={styles.saveNext}>
                    <button className={styles.next} onClick={() => saveNext()}>{t("common.save")}</button>
                </div>
                <div className={styles.Back}>
                    <button className={styles.next} onClick={() => navigate('/resumeedit')}>{t("common.back")}</button>
                </div>
                {/* <div className={styles.faceBtn}>
                    <FacebookLogin
                        scope='public_profile, email'
                        className={styles.facebookBtn}
                        appId="708306884633894"
                        onSuccess={(response) => facebookSuccess(response)}
                        onFail={(error) => facebookFail(error)}
                        onProfileSuccess={(response) => facebookProfileSuccess(response)}
                    />
                </div> */}
            </div>
            <div className={styles.newHelp}>
                    <div className={styles.helpCall}>
                    <a href="tel:8595141564">
                        <img  src={cellHelp}/>
                    </a>
                    </div>
                </div>
        </form>
        
    )
}

export default Profiles;