import { useEffect, useState } from 'react';
import styles from './EditResume.module.scss'
import arrowIcon from '../../assets/images/greaterThanIcon.png';
import persImge from '../../assets/images/proEdi.svg';
import eduImge from '../../assets/images/eduEd.svg';
import desirImge from '../../assets/images/desiEdit.svg';
import worExp from '../../assets/images/workExp.svg';
import proExp from '../../assets/images/proEdit.svg';
import perExp from '../../assets/images/persEdit.svg';
import carrExp from '../../assets/images/careEdi.svg';
import signOut from '../../assets/images/signOutIImage.svg';

import { useNavigate } from 'react-router-dom';
import SignOut from '../popups/SignOut/SignOut';
import { getCookie } from '../../util/helper';
import { useTranslation } from 'react-i18next';
import cellHelp from '../../assets/images/needHelp.png';



const EditResume = (props) => {
    const { t, i18n } = useTranslation('common');
    const { setShowProgressBar } = props;
    const navigate = useNavigate();
    const [showSignout, setShowSignout] = useState(false)
    useEffect(() => {
        setShowProgressBar(false);
        if (getCookie("lang")) {
            i18n.changeLanguage(getCookie("lang"))
        }
    }, [showSignout])

    const openSignout = () => {
        setShowSignout(true)
    }
    return (
        <div className="page slide-page">
            {showSignout && <SignOut isOpen={showSignout} isClose={() => setShowSignout(false)} />}
            <div className={styles.TitleHead}>
                <h3>{t("EditResume.title")}</h3>
            </div>

            <div className={styles.formInputAre}>
                <div className={styles.AspirationList}>
                    <ul>
                        <li onClick={() => navigate('/profiles')}>
                            <h3><img alt="Personal" src={persImge} />{t("EditResume.personalInfo")}</h3>
                            <strong style={{ float: "right" }}>
                                <span>
                                    <img alt="ArrowIcon" src={arrowIcon} style={{ height: "13px", width: "13px" }} />
                                </span>
                            </strong>
                        </li>
                        <li onClick={() => navigate('/education')}>
                            <h3><img src={eduImge} alt="" />{t("EditResume.educationDetails")}</h3>
                            <strong style={{ float: "right" }}>
                                <span>
                                    <img alt="ArrowIcon" src={arrowIcon} style={{ height: "13px", width: "13px" }} />
                                </span>
                            </strong>
                        </li>
                        <li onClick={() => navigate('/desiredjob')}>
                            <h3><img src={desirImge} alt="" />{t("EditResume.desiredJob")}</h3>
                            <strong style={{ float: "right" }}>
                                <span>
                                    <img alt="ArrowIcon" src={arrowIcon} style={{ height: "13px", width: "13px" }} />
                                </span>
                            </strong>
                        </li>
                        <li onClick={() => navigate('/employmentStatus')}>
                            <h3><img src={worExp} alt="" />{t("EditResume.workExperience")}</h3>
                            <strong style={{ float: "right" }}>
                                <span>
                                    <img alt="ArrowIcon" src={arrowIcon} style={{ height: "13px", width: "13px" }} />
                                </span>
                            </strong>
                        </li>
                        <li onClick={() => navigate('/achievements')}>
                            <h3><img src={proExp} alt="" />{t("EditResume.professionAchievement")}</h3>
                            <strong style={{ float: "right" }}>
                                <span>
                                    <img alt="ArrowIcon" src={arrowIcon} style={{ height: "13px", width: "13px" }} />
                                </span>
                            </strong>
                        </li>
                        <li onClick={() => navigate('/projects')}>
                            <h3><img src={perExp} alt="" />{t("EditResume.personalProject")}</h3>
                            <strong style={{ float: "right" }}>
                                <span>
                                    <img alt="ArrowIcon" src={arrowIcon} style={{ height: "13px", width: "13px" }} />
                                </span>
                            </strong>
                        </li>
                        <li onClick={() => navigate('/aspirationFill')}>
                            <h3><img src={carrExp} alt="" />{t("EditResume.carrierAspire")}</h3>
                            <strong style={{ float: "right" }}>
                                <span>
                                    <img alt="ArrowIcon" src={arrowIcon} style={{ height: "13px", width: "13px" }} />
                                </span>
                            </strong>
                        </li>
                        <li onClick={() => openSignout()}>
                            <h3><img src={signOut} alt="" />{t("EditResume.signOut")}</h3>
                            <strong style={{ float: "right" }}>
                                <span>
                                    <img alt="ArrowIcon" src={arrowIcon} style={{ height: "13px", width: "13px" }} />
                                </span>
                            </strong>
                        </li>
                    </ul>
                </div>
            </div>

            <div className={styles.saveNext}>
                <button className={styles.next} onClick={() => navigate("/digitalcv")}>Go back to my CV </button>
                {/* <button className={styles.skipButton} onClick={() => navigate("/profiles")}> Skip </button> */}
            </div>
            <div className={styles.newHelp}>
                    <div className={styles.helpCall}>
                    <a href="tel:8595141564">
                        <img  src={cellHelp}/>
                    </a>
                    </div>
                </div>
            

        </div >)
}

export default EditResume;