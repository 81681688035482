import React from "react";
import styles from "./DigitalResumeFirst.module.scss";
import logoImg from '../../assets/images/logoColor.svg';
import proIcon from '../../assets/images/proIcon.svg'
import achiIcon from '../../assets/images/achiIcon.svg'
import { useSelector } from "react-redux";



const DigitalResumeSecond = () => {
    const { profileData } = useSelector(state => state);
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    const { projectDetails, achievementDetails, experienceDetails } = userData || profileData;
    // let langSplit = [language];
    // if (language && language.includes(",")) {
    //     langSplit = language.split(",");
    // }
       // Function to get remaining experiences beyond the first two
       const getRemainingExperiences = () => {
        if (experienceDetails && experienceDetails.length > 2) {
            return experienceDetails.slice(2); // Returns experiences starting from the third one
        }
        return [];
    }
    const detectMob = () => {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    }
    const calculateNumAdditionalDivs = () => {
        let additionalDivs = 0;
        const projectCount = projectDetails?.length || 0;
        const achievementCount = achievementDetails?.length || 0;
        const totalWorkExperience = getRemainingExperiences()?.length || 0;
        if (totalWorkExperience === 0) {
            additionalDivs += 6;  // Add 3 divs when remaining work experience is 0
        }
        if (totalWorkExperience === 1) {
           return 14;
        }
        if (totalWorkExperience === 2) {
            return 11;
        }
        if (totalWorkExperience === 3) {
            return 8  ;
        }

        if (totalWorkExperience === 4) {
            return 6  ;
        }
        if (totalWorkExperience === 5) {
            return 3  ;
        }
        if (totalWorkExperience === 6) {
            return 3  ;
        }
        if (totalWorkExperience === 7) {
            return 2  ;
        }
        
        
        // if (projectCount === 0 && achievementCount === 0) {
        //     return 10 + additionalDivs;  // Add 10 divs if both project and achievement are 0
        // } else if (projectCount === 1 && achievementCount === 0) {
        //     return 7 + additionalDivs;   // Add 7 divs if project is 1 and achievement is 0
        // } else if (projectCount === 0 && achievementCount === 1) {
        //     return 7 + additionalDivs;   // Add 7 divs if project is 0 and achievement is 1
        // } else if (projectCount === 1 && achievementCount === 1 && getRemainingExperiences().length === 0) {
        //     return 4 + additionalDivs;   // Add 4 divs if project is 1 and achievement is 1
        // } 
        // else if (projectCount === 1 && achievementCount === 1 && getRemainingExperiences().length === 3) {
        //     return 0 + additionalDivs;   // Add 4 divs if project is 1 and achievement is 1
        // } 
        // else if (projectCount === 1 && achievementCount === 1 && getRemainingExperiences().length === 2) {
        //     return 1 + additionalDivs;   // Add 4 divs if project is 1 and achievement is 1
        // } 
        // else if (projectCount === 2 && achievementCount === 0) {
        //     return 4 + additionalDivs;   // Add 5 divs if project is 2 and achievement is 0
        // } else if (projectCount === 0 && achievementCount === 2) {
        //     return 3 + additionalDivs;   // Add 5 divs if project is 0 and achievement is 2
        // } else if (projectCount === 2 && achievementCount === 1) {
        //     return 2 + additionalDivs;   // Add 3 divs if project is 2 and achievement is 1
        // } else if (projectCount === 1 && achievementCount === 2) {
        //     return 0 + additionalDivs;   // Add 3 divs if project is 1 and achievement is 2
        // } else if (projectCount === 2 && achievementCount === 2) {
        //     return 1 + additionalDivs;   // Add 2 divs if project is 2 and achievement is 2
        // } else {
        //     return 0;   // No additional divs in other cases
        // }
    };
    
    // Function to generate additional divs
    const generateAdditionalDivs = () => {
        const numAdditionalDivs = calculateNumAdditionalDivs();
        const additionalDivs = [];
    
        for (let i = 0; i < numAdditionalDivs; i++) {
            additionalDivs.push(
                <div key={`additionalDiv${i}`} className={styles.achiveWork}>
                    <div className={styles.detailContent}>
                        <div className={styles.timelineBlock}>
                            {/* Placeholder content */}
                        </div>
                    </div>
                </div>
            );
        }
    
        return additionalDivs;
    };

    return (
        <div className={styles.dashbardData} id="page2-content">
            
            <div className={styles.dashboardNav}>
                <div className={styles.resumeData}>
                    <div className={styles.resumeContent}>
                        <div className={styles.tableData}>
                            <div className={styles.profileData}>
                                <div className={styles.employeeDetail}>
                                    <div className={styles.leftDetail} style={{ borderRadius: "24px 8px 8px 24px" }}>
                                    {((projectDetails && projectDetails.length !== 0) && (experienceDetails && experienceDetails.length !== 0)) && <div className={styles.proWork}>
                                            <h3><img src={proIcon} alt="" />Projects</h3>
                                            {projectDetails.map((results) => {
                                                const { projectName, description, startDate, endDate } = results;
                                                return <div className={styles.detailContent}>
                                                    <div className={styles.timelineBlock}>
                                                        <h1>{projectName}</h1>
                                                        <time>{startDate} - {endDate}</time>
                                                        <p>{description}</p>
                                                    </div>
                                                </div>
                                            })}
                                        </div>}


                                        {((achievementDetails && achievementDetails.length !== 0)  && (experienceDetails && experienceDetails.length !== 0)) && <div className={styles.achiveWork}>
                                            <h3><img src={achiIcon} alt="" />Achievement</h3>
                                            {achievementDetails.map((results) => {
                                                const { title, description, date } = results;
                                                return <div className={styles.detailContent}>
                                                    <div className={styles.timelineBlock}>
                                                        <h1>{title}</h1>
                                                        <time>{date}</time>
                                                        <p>{description}</p>
                                                    </div>
                                                </div>
                                            })}
                                        </div>}
                                    </div>
                                    <div className={styles.rightDetail}>

                                        {/* Display remaining experiences */}
                                        {(getRemainingExperiences().length !== 0) && (
                                            <div className={styles.workExp}>
                                                <h3><img src={proIcon} alt="" />Work Experience</h3>
                                                {getRemainingExperiences().map((result, index) => {
                                                    const { lastCompany, industry, lastPosition, lastSalary, fromMonth, toMonth } = result;
                                                    return (
                                                        <div className={styles.detailContent} key={index}>
                                                            <div className={styles.timelineBlock}>
                                                                <h1>{lastPosition}</h1>
                                                                <time>{fromMonth} - {toMonth}</time>
                                                                <p>{lastCompany}</p>
                                                                <p>{lastSalary}</p>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}

                                       
                                       
                                    <div className={styles.decDetail}>
                                        <h3><img src={achiIcon} alt="" />Declaration</h3>
                                        <div className={styles.detailContent}>
                                            <div className={styles.timelineBlock}>
                                                <p>I Hereby certify that the above given detail are true and correct to the best of my knowledge and belief</p>
                                                <time>Place: </time>
                                                <p>Date:</p>
                                            </div>
                                        </div>
                                    </div>   
                                    {generateAdditionalDivs()}   
                                        
                                    </div>
                                    
                                    <div className={styles.poweredBy}>
                                        <h3>CV Powered by <img src={logoImg} alt="" /></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DigitalResumeSecond;