export const candidateLanguages = [
    "English",
    "Asami",
    "Bengali",
    "Gujarati",
    "Hindi",
    "Kannada",
    "Malayalam",
    "Marathi",
    "Odia",
    "Punjabi",
    "Tamil",
    "Telugu",
    "Urdu",
    "Sanskrit",
    "Konkani",
    "Nepali",
    "Bodo",
    "Kashmiri",
    "Maithili",
    "Santali",
    "Sindhi"
]