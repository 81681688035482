import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ResumeFirst from './ResumeFirst';
import ResumeSecond from './ResumeSecond';
import DigitalResumeFirst from '../DigitalResume/DigitalResumeFirst';
import DigitalResumeSecond from '../DigitalResume/DigitalResumeSecond'
import styles from './ResumeFirst.module.scss'
// import HelpCall from '../HelpCall/HelpCall';
import { useSelector } from "react-redux";

const DigitalCV = (props) => {
    const { showResumeFirst, showResumeSecond, showSecondPage, pdfRef } = props;
    const { profileData } = useSelector(state => state);
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    const { projectDetails, achievementDetails,yearsOfExperience } = userData || profileData;
    return (
        // <Carousel showArrows={true} showThumbs={false}>
        <div id="divToPrint" className={styles.newBook} ref={pdfRef}>
            <div style={{ display: showResumeFirst ? "block" : "none" }} id="resumeFirst">
                <DigitalResumeFirst />
            </div>
            {showSecondPage >= 4  && <div style={{ display: showResumeSecond ? "block" : "none" }} id="resumeSecond">
                <DigitalResumeSecond />
            </div>}
            {/* <HelpCall/> */}
        </div>
        // </Carousel>
    )
}

export default DigitalCV;