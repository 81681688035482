import styles from './Aspiration.module.scss';
import Popup from '../Popup';
import closeJob from '../../../assets/images/closeOrg.svg';
import congratsImage from '../../../assets/images/congImage.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AspireCongratsPopup = (props) => {
    const { t } = useTranslation('common');
    const { isOpen, isClose, children } = props;
    const navigate = useNavigate();

    const redirect = () => {
        var now = new Date();
        now.setTime(now.getTime() + 8 * 3600 * 1000); //expire time 8 hours
        let cookieOptions = {
            expires: now //seconds to expire
        }
        navigate('/digitalcv')
    }
    return <Popup isOpen={isOpen} isClose={isClose} children={children}>
        <div className={styles.congraPop}>
            <h3 dangerouslySetInnerHTML={{ __html: t('congratsPopup.title') }}></h3>
            <img src={closeJob} onClick={isClose} alt='CloseImg' />

            <div className={styles.congratsView}>
                <img src={congratsImage} alt="Congrats" />
                <button className={styles.doneButton} onClick={() => redirect()}>{t('congratsPopup.showMyCV')}</button>
            </div>
        </div>
    </Popup>
}

export default AspireCongratsPopup;