import styles from './Aspiration.module.scss';
import faceBookIconActive from "../../assets/images/active-Icon-facebook.png";
import { useDispatch, useSelector } from 'react-redux';
import AspireCongratsPopup from '../popups/Aspiration/AspireCongratsPopup';
import { useState, useEffect } from 'react';
import { setProfileData } from '../../store/reducer';
import { callcandidateProfileUpdate } from '../../util/common';
import { showLoader, hideLoader, getCookie, setCookie, clearCookie } from '../../util/helper';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { candidateOnBoard } from "../../services/Api/candidate";
import { constructParams } from '../../util/common';
import Tooltip from '@mui/material/Tooltip';
import AspireBioPopup from '../popups/Aspiration/AspireBioPopup';
import AspireVisionPopup from '../popups/Aspiration/AspireVisionPopup';
import AspireLanguagePopup from '../popups/Aspiration/AspireLanguagePopup';
import AspireSkillsPopup from '../popups/Aspiration/AspireSkillsPopup';
import { selectedCandidatelanguage, selectedCandidateBios, selectedCandidateVision, selectedCandidateSkills } from '../../store/reducer';
import cellHelp from '../../assets/images/needHelp.png';

const AspirationFill = (props) => {
    const { t } = useTranslation('common');
    const { setShowProgressBar } = props;
    const { candidatelanguages, candidateBio, candidateVision, candidateSkills } = useSelector(state => state);
    let userData = JSON.parse(localStorage.getItem('userData') || '{}');
    console.log(userData);
    const [openCongrats, setOpenCongrats] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { candidateKnownLanguages, cvBioEncrypted, cvVision, skills } = userData;
    const [bios, setBios] = useState([]);
    const [visions, setVisions] = useState([]);
    const [langs, setLangs] = useState([]);
    const [stateskills, setStateSkills] = useState([]);

    const [openBio, setOpenBio] = useState(false);
    const [openVision, setOpenVision] = useState(false);
    const [openLanguage, setOpenLanguage] = useState(false);
    const [openSkills, setOpenSkills] = useState(false);
    const [callOnce, setCallOnce] = useState(false);
    const [isupdate, setIsUpdate] = useState(false);

    const totalProgress = 100;
    const totalFields = 3;
    const calculateProgress = Math.round(totalProgress / totalFields);
    let progressbarSize = 0;

    useEffect(() => {
        if (!callOnce) {
            dispatch(selectedCandidatelanguage(candidatelanguages && candidatelanguages.length !== 0 ? candidatelanguages : candidateKnownLanguages));
            dispatch(selectedCandidateBios(candidateBio && candidateBio.length !== 0 ? candidateBio : cvBioEncrypted));
            dispatch(selectedCandidateVision(candidateVision && candidateVision.length !== 0 ? candidateVision : cvVision));
            dispatch(selectedCandidateSkills(candidateSkills && candidateSkills.length !== 0 ? candidateSkills : skills));
            setCallOnce(true);
        }

        setBios(candidateBio && candidateBio.length !== 0 ? candidateBio : cvBioEncrypted);
        setVisions(candidateVision && candidateVision.length !== 0 ? candidateVision : cvVision);

        // Safety check for candidatelanguages
        let val = (candidatelanguages || []).filter(element => element !== undefined && element !== '');
        setLangs(candidatelanguages && candidatelanguages.length !== 0 ? candidatelanguages : candidateKnownLanguages);

        setStateSkills(candidateSkills && candidateSkills.length !== 0 ? candidateSkills : skills);
        setShowProgressBar(true);
        saveNewUserInfo();
    }, []);

    const saveNewUserInfo = async () => {
        try {
            if (getCookie('candidateId')) {
                console.log(getCookie("candidateId"));
                const dubprofileData = JSON.parse(JSON.stringify(userData));
                const reqObj = Object.assign(dubprofileData, {});
                localStorage.setItem("userData", JSON.stringify(reqObj));
                if (reqObj._id) {
                    delete reqObj._id;
                }
                let params = {
                    id: getCookie("candidateId")
                };
                let reqParams = await constructParams(reqObj);
                const { data: { data, message } } = await candidateOnBoard(reqParams, params);
                var now = new Date();
                now.setTime(now.getTime() + 8 * 3600 * 1000); // expire time 8 hours
                let cookieOptions = {
                    expires: now // seconds to expire
                };
                setCookie("token", data.refreshToken, cookieOptions);
                reqParams._id = getCookie('candidateId');
                localStorage.setItem("userData", JSON.stringify(reqParams));
                console.log('aftersubmit', reqParams);
                clearCookie("candidateId");
                clearCookie("newUserMobileNo");
            }
        } catch (e) {
            console.log("AspireError------>", e);
        }
    }

    const submitHandler = async () => {
        try {
            showLoader();
            const submitObj = {
                cvVision: candidateVision,
                cvBioEncrypted: candidateBio,
                language: getCookie("lang") === 'en' ? 'English' : 'Hindi',
                candidateKnownLanguages: candidatelanguages,
                skills: candidateSkills
            };
            const dubprofileData = JSON.parse(JSON.stringify(userData));
            const reqObj = Object.assign(dubprofileData, submitObj);
            localStorage.setItem("userData", JSON.stringify(reqObj));
            if (reqObj._id) {
                delete reqObj._id;
            }

            dispatch(setProfileData(submitObj));
            if (!getCookie("newUserMobileNo")) {
                await callcandidateProfileUpdate(reqObj);
            }
            hideLoader();
            setOpenCongrats(true);
        } catch (e) {
            hideLoader();
        }
    }

    const openPopup = (val) => {
        switch (val) {
            case "bio":
                setOpenBio(true);
                break;
            case "vision":
                setOpenVision(true);
                break;
            case "lang":
                setOpenLanguage(true);
                break;
            case "skills":
                setOpenSkills(true);
                break;
            default:
        }
    }

    return (
        <div className="page slide-page">
            {openBio && <AspireBioPopup isOpen={openBio} isClose={() => setOpenBio(false)} setBios={setBios} />}
            {openSkills && <AspireSkillsPopup isOpen={openSkills} isClose={() => setOpenSkills(false)} setStateSkills={setStateSkills} />}
            {openVision && <AspireVisionPopup isOpen={openVision} isClose={() => setOpenVision(false)} setVisionss={setVisions} />}
            {openLanguage && <AspireLanguagePopup isOpen={openLanguage} isClose={() => setOpenLanguage(false)} setLangs={setLangs} />}
            {openCongrats && <AspireCongratsPopup isOpen={openCongrats} isClose={() => setOpenCongrats(false)} />}
            <div className={styles.TitleHead}>
                <h3 dangerouslySetInnerHTML={{ __html: t('aspirePreview.title') }}></h3>
            </div>

            <div className={styles.aspirFill}>
                <Tooltip title="Click here to add or remove your skills" placement="top">
                    <div className={styles.yourLang} onClick={() => openPopup('skills')}>
                        <label>{t('aspiration.yourSkills')}</label>
                        {(stateskills && stateskills.length !== 0) && (
                            <div className={styles.spaciList}>
                                {stateskills.map((result, index) => <span key={`lang${index}`}>{result}</span>)}
                            </div>
                        )}
                    </div>
                </Tooltip>
                <Tooltip title="Click here to add or remove your bio" placement="top">
                    <div className={styles.yourBio} onClick={() => openPopup('bio')}>
                        <label>{t('aspiration.yourBio')}</label>
                        {(bios && bios.length !== 0) && (
                            <div className={styles.spaciList}>
                                {bios.map((result, index) => <span key={`bio${index}`}>{result}</span>)}
                            </div>
                        )}
                    </div>
                </Tooltip>
                <Tooltip title="Click here to add or remove your vision" placement="top">
                    <div className={styles.yourVisi} onClick={() => openPopup('vision')}>
                        <label>{t('aspiration.yourVision')}</label>
                        {(visions && visions.length !== 0) && (
                            <div className={styles.spaciList}>
                                {visions.map((result, index) => <span key={`vision${index}`}>{result}</span>)}
                            </div>
                        )}
                    </div>
                </Tooltip>
                <Tooltip title="Click here to add or remove your language" placement="top">
                    <div className={styles.yourLang} onClick={() => openPopup('lang')}>
                        <label>{t('aspiration.yourLanguage')}</label>
                        {(langs && langs.length !== 0) && (
                            <div className={styles.spaciList}>
                                {langs.map((result, index) => <span key={`lang${index}`}>{result}</span>)}
                            </div>
                        )}
                    </div>
                </Tooltip>
                <div className={styles.faceBtnActive} style={{ display: "none" }}>
                    <button className={styles.facebookBtnActive}><img alt='facebook' src={faceBookIconActive} />Login with Facebook</button>
                </div>
            </div>

            <div className={styles.saveNext}>
                <button className={styles.next} onClick={() => submitHandler()}>{t('aspirePreview.Submit')} </button>
                <button className={styles.skipButton} onClick={() => submitHandler()}>{t('common.skip')} </button>
            </div>
            <div className={styles.Back}>
                <button className={styles.next} onClick={() => navigate('/resumeedit')}>{t('common.back')}</button>
            </div>
            <div className={styles.newHelp}>
                <div className={styles.helpCall}>
                    <a href="tel:8595141564">
                        <img src={cellHelp} />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default AspirationFill;
