import styles from './Aspiration.module.scss';
import Popup from '../Popup';
import closeJob from '../../../assets/images/closeOrg.svg';
import { useEffect, useState } from 'react';
import { getBioTagsLists } from '../../../util/common';
import { selectedCandidateBios } from '../../../store/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { showLoader, hideLoader } from "../../../util/helper";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const AspireBioPopup = (props) => {
    const { t } = useTranslation('common');
    const { isOpen, isClose, children, setBios } = props;

    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    const [bios, setBioss] = useState([]);
    const [biosDup, setBiosDup] = useState([]);
    const [isupdate, setIsupdate] = useState(false);
    const dispatch = useDispatch();
    const { candidateBio, profileData } = useSelector(state => state)
    const { cvBioEncrypted } = userData || profileData;
    const [selectedBio, setSelectedBio] = useState([]);
    const [useEffectCalled, setuseEffectcalled] = useState(false);

    const getBioList = async () => {
        try {
            showLoader();
            const response = await getBioTagsLists();
            let bioArray = response.map(result => result.Bio_tags);
            setBioss(bioArray);
            setBiosDup(bioArray);
            hideLoader();
        } catch (e) {
            hideLoader();
            setBioss([]);
            setBiosDup([]);
        }
    }

    useEffect(() => {
        try {
            if (!useEffectCalled) {
                let getBios = JSON.parse(JSON.stringify(selectedBio));

                if (candidateBio && candidateBio.length !== 0) {
                    getBios.push(...candidateBio);
                } else if (cvBioEncrypted) {
                    getBios.push(...cvBioEncrypted);
                }
                setSelectedBio(getBios);
                selectedCandidateBios(getBios);
                setuseEffectcalled(true);
            }

            if (bios.length === 0) {
                getBioList();
            }
        } catch (e) {
            console.log("E-------->", e);
        }
    }, [isupdate]);

    const selectBio = (val) => {
        let getBios = JSON.parse(JSON.stringify(selectedBio));

        // Check if the limit of 5 bios is reached
        if (getBios.length < 5) {
            getBios.push(val);
            setSelectedBio([...new Set(getBios)]);
        } else {
            // Display a message or handle the case when the limit is reached
           
            toast.error(`You can only add maximum  5 bios..`);
        }
    }

    const doneBio = () => {
        dispatch(selectedCandidateBios(selectedBio));
        setBios(selectedBio);
        isClose();
    }

    const deleteBio = (item) => {
        let getBio = JSON.parse(JSON.stringify(selectedBio));
        var index = getBio.indexOf(item);
        if (index !== -1) {
            getBio.splice(index, 1);
        }
        dispatch(selectedCandidateBios(getBio));
        setBios(getBio);
        setSelectedBio(getBio);
        setIsupdate(!isupdate);
    }

    const searchFilter = (value) => {
        if (value.length !== 0) {
            const filterArray = JSON.parse(JSON.stringify(biosDup));

            let searchArray = [];
            filterArray.map((ele) => {
                if (ele.toLowerCase().includes(value.toLowerCase())) {
                    searchArray.push(ele);
                }
            });
            setBioss(searchArray);
        } else {
            setBioss(biosDup);
        }
    }

    const cancelpopup = () => {
        dispatch(selectedCandidateBios(cvBioEncrypted));
        isClose();
    }

    return (
        <Popup isOpen={isOpen} isClose={isClose} children={children}>
            <div className={styles.aspirFill}>
                <h3>{t('aspireBioPopup.title')}<span></span></h3>
                <img src={closeJob} onClick={() => cancelpopup()} alt='CloseImg' />

                <div className={styles.FormGroup}>
                    <input className={styles.formControl} type="text" placeholder="Search…" onChange={e => searchFilter(e.target.value)} />
                </div>

                <div className={styles.yourBio}>
                    <div className={styles.spaciList}>
                        {bios.map((result, index) => {
                            if (selectedBio.includes(result)) {
                                return <span key={`bio${index}`} onClick={() => deleteBio(result)} style={{ border: "3px solid #ff6600" }}>{result}</span>
                            } else {
                                return <span key={`bio${index}`} onClick={() => selectBio(result)}>{result}</span>
                            }
                        })}
                    </div>
                    <button className={styles.doneButton} onClick={() => doneBio()}>{t('aspireBioPopup.done')}</button>
                </div>
            </div>
        </Popup>
    );
}

export default AspireBioPopup;
