import styles from './InvitePopup.module.scss';
import Popup from '../Popup';
import closeJob from '../../../assets/images/closeOrg.svg';
import commnet from '../../../assets/images/cmnt.png';
import whatsapp from '../../../assets/images/whatsApp.png';
import email from '../../../assets/images/mail.png';
import messanger from '../../../assets/images/msngr.png';
import facebook from '../../../assets/images/fcb.png';
import instagram from '../../../assets/images/insta.png';
import telegram from '../../../assets/images/tlgrm.png';
import linkedIn from '../../../assets/images/lnkd.png';
import linkcopy from '../../../assets/images/iconLinked.png';
import iconCopy from '../../../assets/images/copyIcon.png';
// import {
//     EmailShareButton,
//     FacebookShareButton,
//     WhatsappShareButton,
//     FacebookMessengerShareButton,
//     InstapaperShareButton,
//     TelegramShareButton,
//     LinkedinShareButton,
// } from "react-share";
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

const InvitePopup = (props) => {
    const { t } = useTranslation('common');
    const { isOpen, isClose, children, resumeUrl } = props;

    const [selectedShare, setSelectedShare] = useState("")
    const onclickHandler = (name) => {
        setSelectedShare(name)
    }

    const shareNow = () => {
        const message = encodeURIComponent(resumeUrl);
        let smsUrl = "";
        switch (selectedShare) {
            case "message":
                smsUrl = `sms:${""}?body=${message}`;
                window.location.href = smsUrl;
                break;
            case "whatsapp":
                smsUrl = `whatsapp://send?text=${message}`;
                window.location.href = smsUrl;
                break;
            case "email":
                smsUrl = `mailto:%7Bemail_address%7D?subject=&body=${message}`;
                window.location.href = smsUrl;
                break;
            case "messenger":
                smsUrl = `fb-messenger://share/?link=${message}`;
                window.location.href = smsUrl;
                break;
            case "facebook":
                smsUrl = `https://www.facebook.com/sharer/sharer.php?u=${message}`;
                window.location.href = smsUrl;
                break;
            case "instagram":
                smsUrl = `instagram://share?url=${message}`;
                window.location.href = smsUrl;
                break;
            case "telegram":
                smsUrl = `https://telegram.me/share/url?url=http://${window.location.host}&text=${message}`;
                window.location.href = smsUrl;
                break;
            case "linkedin":
                smsUrl = `https://www.linkedin.com/shareArticle?mini=true&text=${message}`;
                window.location.href = smsUrl;
                break;

            default:
                break;
        }
    }
    return <Popup isOpen={isOpen} isClose={isClose} children={children}>
        <div className={styles.aspirFill}>

            <h3>{t('invitePopup.title')}<span></span></h3>
            <img src={closeJob} onClick={isClose} alt='CloseIcon' />
            <div className={styles.copyLink}>
                <div className={styles.linkBox}>
                    <img src={linkcopy} alt="" /><p>{resumeUrl}</p>
                </div>
                <input id="myInput" type="text" value={resumeUrl} style={{ display: "none" }} />

                {/* <button className={styles.copyLinkBtn} text={resumeUrl}><img alt='CopyIcon' src={iconCopy} />Copy</button> */}
                <CopyToClipboard text={resumeUrl}>
                    <button className={styles.copyLinkBtn}><img alt='CopyIcon' src={iconCopy} />{t('sharePopup.copy')}</button>
                </CopyToClipboard>
            </div>

            <div className={styles.shareList}>
                <div className={styles.shareOption}>
                    <h3>{t('invitePopup.shareon')}</h3>
                    <ul>
                        <li onClick={() => onclickHandler("message")} style={{ border: selectedShare === "message" ? "1px solid #ff6600" : "" }}>
                            <div className={styles.shareBox}>
                                <img src={commnet} alt='Comment' />
                                <span>{t('invitePopup.messages')}</span>
                            </div>
                        </li>
                        <li onClick={() => onclickHandler("whatsapp")} style={{ border: selectedShare === "whatsapp" ? "1px solid #ff6600" : "" }}>
                            <div className={styles.shareBox}>
                                <img src={whatsapp} alt='Whatsapp' />
                                <span>{t('invitePopup.whatsapp')}</span>
                            </div>
                        </li>
                        <li onClick={() => onclickHandler("email")} style={{ border: selectedShare === "email" ? "1px solid #ff6600" : "" }}>
                            <div className={styles.shareBox}>
                                <img src={email} alt='Email' />
                                <span>{t('invitePopup.mail')}</span>
                            </div>
                        </li>
                        <li onClick={() => onclickHandler("messenger")} style={{ border: selectedShare === "messenger" ? "1px solid #ff6600" : "" }}>
                            <div className={styles.shareBox}>
                                <img src={messanger} alt='Messenger' />
                                <span>{t('invitePopup.messenger')}</span>
                            </div>
                        </li>
                        <li onClick={() => onclickHandler("facebook")} style={{ border: selectedShare === "facebook" ? "1px solid #ff6600" : "" }}>
                            <div className={styles.shareBox}>
                                <img src={facebook} alt='Facebook' />
                                <span>{t('invitePopup.facebook')}</span>
                            </div>
                        </li>
                        <li onClick={() => onclickHandler("instagram")} style={{ border: selectedShare === "instagram" ? "1px solid #ff6600" : "" }}>
                            <div className={styles.shareBox}>
                                <img src={instagram} alt='Instagram' />
                                <span>{t('invitePopup.instagram')}</span>
                            </div>
                        </li>
                        <li onClick={() => onclickHandler("telegram")} style={{ border: selectedShare === "telegram" ? "1px solid #ff6600" : "" }}>
                            <div className={styles.shareBox}>
                                <img src={telegram} alt='Telegram' />
                                <span>{t('invitePopup.telegram')}</span>
                            </div>
                        </li>
                        <li onClick={() => onclickHandler("linkedin")} style={{ border: selectedShare === "linkedin" ? "1px solid #ff6600" : "" }}>
                            <div className={styles.shareBox}>
                                <img src={linkedIn} alt='LinkedIn' />
                                <span>{t('invitePopup.linkedin')}</span>
                            </div>
                        </li>

                    </ul>
                </div>
                <button className={styles.doneButton} onClick={() => shareNow()}>{t('invitePopup.shareNow')}</button>
            </div>
        </div>
    </Popup>
}

export default InvitePopup;