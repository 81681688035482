import styles from './Aspiration.module.scss';
import Popup from '../Popup';
import closeJob from '../../../assets/images/closeOrg.svg';
import { candidateLanguages } from '../../../util/util';
import { useEffect, useState } from 'react';
import { selectedCandidatelanguage } from '../../../store/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const AspireLanguagePopup = (props) => {
    const { t } = useTranslation('common')
    const { candidatelanguages, profileData } = useSelector(state => state)
    const { isOpen, isClose, children, setLangs } = props;

    const [isupdate, setIsupdate] = useState(false)
    const dispatch = useDispatch();
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    const { candidateKnownLanguages } = userData || profileData;
    // let langSplit = [];
    // if (language && language.includes(",")) {
    //     langSplit = language.split(",");
    // }
    const [selectedLanguages, setSelectedlanguages] = useState(candidateKnownLanguages && candidateKnownLanguages.length !== 0 ? candidateKnownLanguages : [])
    const [avilableLangs, setAvailableLangs] = useState([]);
    const [callOnce, setCallOnce] = useState(false);
    useEffect(() => {
        if (!callOnce) {
            let getLanguages = selectedLanguages;
            if (candidatelanguages && candidatelanguages.length !== 0) {
                getLanguages.push(...candidatelanguages);
            }
            setSelectedlanguages(getLanguages)
            setCallOnce(true)
        }
        setAvailableLangs(candidateLanguages);
    }, [isupdate])

    const selectlanguages = (val) => {
        let getLanguages = selectedLanguages;
        getLanguages.push(val);

        setSelectedlanguages([...new Set(getLanguages)])
    }

    const doneLanguage = () => {
        var intersections = selectedLanguages.filter(e => avilableLangs.indexOf(e) !== -1);
        dispatch(selectedCandidatelanguage(intersections));
        setLangs(intersections);
        isClose();
    }

    const deleteLanguage = (item) => {
        let getLanguages = JSON.parse(JSON.stringify(selectedLanguages));
        var index = getLanguages.indexOf(item);
        if (index !== -1) {
            getLanguages.splice(index, 1);
        }
        setSelectedlanguages([...new Set(getLanguages)])
        setLangs([...new Set(getLanguages)]);
        setIsupdate(!isupdate)
    }

    const searchFilter = (value) => {
        if (value.length !== 0) {
            const filterArray = JSON.parse(JSON.stringify(candidateLanguages));

            let searchArray = [];
            filterArray.map((ele) => {
                if (ele.toLowerCase().includes(value.toLowerCase())) {
                    searchArray.push(ele)
                }
            });
            setAvailableLangs(searchArray);
        } else {
            setAvailableLangs(candidateLanguages);
        }
    }
    return <Popup isOpen={isOpen} isClose={isClose} children={children}>
        <div className={styles.aspirFill}>
            <h3>{t('aspireLangPopup.title')} <span></span></h3>
            <img src={closeJob} onClick={isClose} alt='CloseImg' />

            <div className={styles.FormGroup}>
                <input className={styles.formControl} type="text" placeholder="Search…" onChange={e => searchFilter(e.target.value)} />
            </div>

            <div className={styles.yourBio}>
                {/* <lable>Your Bio</lable> */}
                <div className={styles.spaciList}>
                    {avilableLangs.map((result, index) => {
                        if (selectedLanguages.includes(result)) {
                            return <span key={`language${index}`} onClick={() => deleteLanguage(result)} style={{ border: "3px solid #ff6600" }}>{result}</span>
                        } else {
                            return <span key={`language${index}`} onClick={() => selectlanguages(result)}>{result}</span>
                        }

                    })}
                    {/* <span>Hindi</span>
                    <span>English</span>
                    <span>Bengali</span>
                    <span>Marathi</span>
                    <span>Odia</span>
                    <span>Telugu</span>
                    <span>Tamil</span>
                    <span>Gujarati</span>
                    <span>Marwari</span>
                    <span>Kannada</span>
                    <span>Malayalam</span>
                    <span>Punjabi</span>
                    <span>Assamese</span>
                    <span>Maithili</span>
                    <span>Dogri</span>
                    <span>Konkani</span>
                    <span>Garo</span> */}

                </div>
                <button className={styles.doneButton} onClick={() => doneLanguage()}>{t('aspireLangPopup.done')}</button>
            </div>
        </div>
    </Popup>
}

export default AspireLanguagePopup;