import styles from './Aspiration.module.scss';
import Popup from '../Popup';
import closeJob from '../../../assets/images/closeOrg.svg';
import { useEffect, useState } from 'react';
import { selectedCandidateSkills } from '../../../store/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { showLoader, hideLoader } from "../../../util/helper";
import { getSkillsList } from '../../../services/Api/candidate';
import { useTranslation } from 'react-i18next';

const AspireSkillsPopup = (props) => {
    const { t } = useTranslation('common');
    const { candidateSkills, profileData } = useSelector(state => state)
    const { isOpen, isClose, children, setStateSkills } = props;

    const [isupdate, setIsupdate] = useState(false)
    const dispatch = useDispatch();
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    const { skills } = userData || profileData;

    const [selectedSkills, setSelectedSkills] = useState([])
    const [avilableSkills, setAvailableSkills] = useState([]);
    const [avilableDupSkills, setAvailableDupSkills] = useState([]);
    const [callOnce, setCallOnce] = useState(false);
    useEffect(() => {
        if (candidateSkills && candidateSkills.length !== 0 && !callOnce) {
            let getSkillsArrray = selectedSkills;
            getSkillsArrray.push(...candidateSkills);
            setSelectedSkills(candidateSkills);

            setCallOnce(true)
        }
        getSkills();
        // setAvailableSkills(selectedSkills);

    }, [isupdate])

    const getSkills = async () => {
        const { data: { data } } = await getSkillsList();
        showLoader();
        try {
            let skillArray = [];
            data.map((result) => {
                skillArray.push(result.skill)
            })
            setAvailableSkills(skillArray);
            setAvailableDupSkills(skillArray);
            hideLoader();
        } catch (e) {
            setAvailableSkills([]);
            setAvailableDupSkills([]);
            hideLoader();
        }
    }

    const selectlanguages = (val) => {
        let getSkills = JSON.parse(JSON.stringify(selectedSkills));
        getSkills.push(val);
        setSelectedSkills([...new Set(getSkills)])
    }

    const doneSkills = () => {
        var intersections = selectedSkills.filter(e => avilableSkills.indexOf(e) !== -1);
        dispatch(selectedCandidateSkills(intersections));
        setStateSkills(intersections);
        isClose();
    }

    const deleteSkills = (item) => {
        let getSkills = JSON.parse(JSON.stringify(selectedSkills));
        var index = getSkills.indexOf(item);
        if (index !== -1) {
            getSkills.splice(index, 1);
        }
        setSelectedSkills(getSkills);
        setStateSkills(getSkills);
        setIsupdate(!isupdate)
    }

    const searchFilter = (value) => {
        if (value.length !== 0) {
            const filterArray = JSON.parse(JSON.stringify(avilableDupSkills));

            let searchArray = [];
            filterArray.map((ele) => {
                if (ele.toLowerCase().includes(value.toLowerCase())) {
                    searchArray.push(ele)
                }
            });
            setAvailableSkills(searchArray);
        } else {
            setAvailableSkills(avilableDupSkills);
        }
    }

    const cancelpopup = () => {
        dispatch(selectedCandidateSkills(skills));
        isClose();
    }

    return <Popup isOpen={isOpen} isClose={isClose} children={children}>
        <div className={styles.aspirFill}>
            <h3>{t('aspireSkillsPopup.title')} <span></span></h3>
            <img src={closeJob} onClick={() => cancelpopup()} alt='CloseImg' />

            <div className={styles.FormGroup}>
                <input className={styles.formControl} type="text" placeholder="Search…" onChange={e => searchFilter(e.target.value)} />
            </div>

            <div className={styles.yourBio}>
                {/* <lable>Your Bio</lable> */}
                <div className={styles.spaciList}>
                    {avilableSkills.map((result, index) => {
                        if (selectedSkills.includes(result)) {
                            return <span key={`language${index}`} onClick={() => deleteSkills(result)} style={{ border: "3px solid #ff6600" }}>{result}</span>
                        } else {
                            return <span key={`language${index}`} onClick={() => selectlanguages(result)}>{result}</span>
                        }

                    })}

                </div>
                <button className={styles.doneButton} onClick={() => doneSkills()}>{t('aspireSkillsPopup.done')}</button>
            </div>
        </div>
    </Popup>
}

export default AspireSkillsPopup;