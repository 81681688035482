import { getSignedUrl, fileUpload, candidateUpdateProfile, getSpecializationList, getBioTags, getVisionTags } from '../services/Api/candidate'
import { showLoader, hideLoader } from './helper';

export const callSignedAPi = async (params) => {
    let {
        data: {
            appStatus
        }
    } = await getSignedUrl(params);
    return appStatus;
}

export const constructParams = async (params) => {
    const picked = (({
        fullName, aadhaarMobile, city, state, candidateEducationSort, educationType, educationDegree, educationBranch, birthDate, gender, profilePicture, email, desiredDesignation,
        specialization, bikeOwner, drivingLicense, yearsOfExperience, carOwner, aadhaarProof, panProof, voterProof, drivingLicenseProof, personalVideo,
        desiredSalary, candidateExperience, experienceDetails, fullAddress, GPSCoordinates, matchJobBy, resumeDocument, currentJobPosition, styleView, maskCalls,
        achievementDetails, projectDetails, cvVision, cvBioEncrypted, language, candidateKnownLanguages, skills, isStudent, campaignSourceURL,invitedBy, designationId, designationUid, knownLanguage
    }) => ({
        fullName, aadhaarMobile, city, state, candidateEducationSort, educationType, educationDegree, educationBranch, birthDate, gender, profilePicture, email, desiredDesignation,
        specialization, bikeOwner, drivingLicense, yearsOfExperience, carOwner, aadhaarProof, panProof, voterProof, drivingLicenseProof, personalVideo,
        desiredSalary, candidateExperience, experienceDetails, fullAddress, GPSCoordinates, matchJobBy, resumeDocument, currentJobPosition, styleView, maskCalls,
        achievementDetails, projectDetails, cvVision, cvBioEncrypted, language, candidateKnownLanguages, skills, isStudent, campaignSourceURL,invitedBy, designationId, designationUid, knownLanguage
    }))(params);
    return picked;
}

export const callcandidateProfileUpdate = async (params) => {
    showLoader();

    let {
        data: {
            appStatus
        }
    } = await candidateUpdateProfile(await constructParams(params));
    console.log(params)
    hideLoader();
    return appStatus;
}


export const callUploadApi = async (image) => {
    let content = new FormData();
    content.append('fileKey', image);
    const {
        data: {
            data
        } } = await fileUpload(content);
    //  setValue(type, data?.fileKey);
    return data;
}

export const getSpecializationLists = async (positionId) => {
    try {
        let params = {
            positionId
        }
        const {
            data: {
                data
            }
        } = await getSpecializationList(params);
        return data;
    } catch (error) {
        return {};
    }
}

export const getBioTagsLists = async (positionId) => {
    try {
        let params = {
            positionId
        }
        const {
            data: {
                data
            }
        } = await getBioTags(params);
        return data;
    } catch (error) {
        return {};
    }
}

export const getVisionTagsLists = async (positionId) => {
    try {
        let params = {
            positionId
        }
        const {
            data: {
                data
            }
        } = await getVisionTags(params);
        return data;
    } catch (error) {
        return {};
    }
}

export const customStyles = {
    control: (provided, state) => ({
        ...provided,
        boxShadow: "none",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        borderBottom: "1px solid #DBDBDB",
        fontSize: "14px"

    }),
    dropdownIndicator: base => ({
        ...base,
        color: "black",// Custom colour,
        size: "10px",
        padding: "0px",
        height: "15px",
        width: "15px"
    }),
    // placeholder: (base) => ({
    //     ...base,
    //     // backgroundColor: "black",
    //     fontSize: "1em",
    //     color: "#DBDBDB",
    //     fontWeight: 200
    // })
    placeholder: (defaultStyles) => ({
        ...defaultStyles,
        color: '#ffffff',
    }),
};
