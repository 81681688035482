import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Education.module.scss';
import { getEducationList, getProfile } from '../../services/Api/candidate';
import { setProfileData } from '../../store/reducer';
import { callcandidateProfileUpdate } from '../../util/common';
import { useNavigate } from 'react-router-dom';
import { showLoader, hideLoader, getCookie } from '../../util/helper';
import { useTranslation } from 'react-i18next'
import Select from 'react-select';
import { customStyles } from "../../util/common";
import cellHelp from '../../assets/images/needHelp.png';

const Education = (props) => {
    const { t, i18n } = useTranslation('common');
    const { setProgressCount, setShowProgressBar } = props;
    const { profileData, newUserMobileNo, campignSource, invitedBy } = useSelector(state => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    const { candidateEducationSort, educationType, educationDegree, educationBranch, drivingLicense, carOwner, bikeOwner } = userData || profileData
    const validationEduSort = [1, 2, 3, 4]
    const [educationData, setEducationData] = useState([]);
    const [degreeData, setDegreeData] = useState([]);
    const [branchData, setBranchData] = useState([]);
    const [educationList, seteducationList] = useState([])
    const [educationSortIds, seteducationSortIds] = useState([]);
    const [showDegree, setShowDegree] = useState(educationDegree ? true : false)
    const [showbranch, setShowbranch] = useState(educationBranch ? true : false);
    const [selectedEducationSort, setselectedEducationsort] = useState(candidateEducationSort ? candidateEducationSort : 0);
    const [selectedEducationType, setselectedEducationType] = useState(educationType ? educationType : "")
    const [selectedEducationDegree, setselectedEducationDegree] = useState(educationDegree ? educationDegree : "")
    const [selectedEducationBranch, setselectedEducationBranch] = useState(educationBranch ? educationBranch : "")
    const [haveDrivingLicense, sethaveDrivingLicense] = useState(false);
    const [haveCar, sethaveCar] = useState(false);
    const [haveBike, sethaveBike] = useState(false);

    const [educationError, setEducationError] = useState("");
    const [degreeError, setDegreeError] = useState("");
    const [branchError, setBranchError] = useState("");
    const [callOnce, setcallOnce] = useState(false)

    const totalProgress = 100;
    const totalFields = 9;
    const calculateProgress = Math.round(totalProgress / totalFields);

    useEffect(() => {
        if (!callOnce) {
            getEducation();
            sethaveDrivingLicense(drivingLicense ? true : false);
            sethaveBike(bikeOwner ? true : false);
            sethaveCar(carOwner ? true : false);
            setcallOnce(true)
        }
        let progressbarSize = 0;
        if (educationType) {
            progressbarSize = progressbarSize + calculateProgress;
        }
        if (educationDegree) {
            progressbarSize = progressbarSize + calculateProgress;
        }
        if (educationBranch) {
            progressbarSize = progressbarSize + calculateProgress;
        }
        if (drivingLicense) {
            progressbarSize = progressbarSize + calculateProgress;
        }
        if (carOwner) {
            progressbarSize = progressbarSize + calculateProgress;
        }
        if (bikeOwner) {
            progressbarSize = progressbarSize + calculateProgress;
        }
        setProgressCount(15)
        setShowProgressBar(true);
        // setselectedEducationsort(candidateEducationSort ? candidateEducationSort : selectedEducationSort);
        // if (validationEduSort.includes(candidateEducationSort)) {
        //     setShowDegree(true);
        //     setShowbranch(true);
        // } else {
        //     setShowDegree(false);
        //     setShowbranch(false);
        // }
        //setShowDegree(educationDegree ? true : false)
        // document.getElementById("ele_education").setAttribute("value", educationType)


    }, [educationData])
    const getEducation = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getEducationList();
            // data.filter(obj => {})
            let eduCateIds = data.map(obj => obj.educationSort);
            const educationIds = [...new Set(eduCateIds)];
            seteducationSortIds(educationIds)
            let eduCation = data.map(obj => obj.education);
            setEducationData([...new Set(eduCation)]);
            seteducationList(data)
            const filterdegree = data.filter(obj => obj.educationSort === selectedEducationSort && obj.hasOwnProperty("degree"))
            const filterbranch = data.filter(obj => obj.educationSort === selectedEducationSort && obj.degree === selectedEducationDegree)
            if (selectedEducationSort !== 0) {
                let degrees = filterdegree.map(obj => { return { label: obj.degree, value: obj.degree } });
                setDegreeData(degrees)
            }
            if (selectedEducationBranch) {
                let branches = filterbranch.map(obj => obj.branch);
                let branchArray = branches[0] && branches[0].map(result => {
                    return { label: result, value: result }
                })
                setBranchData(branchArray)
            }

        } catch (e) {
            console.log("E--------->", e)
        }
    }

    const selectEducation = (e, from) => {
        try {
            if (from === "education") {
                setEducationError("")
                setBranchError("")
                setselectedEducationsort(parseInt(e.target.value) + 1);
                //  if (validationEduSort.includes(parseInt(e.target.value) + 1)) {
                setselectedEducationDegree("");
                setselectedEducationBranch("");
                //    }
                let res = educationList.filter(obj => obj.educationSort === parseInt(e.target.value) + 1 && obj.hasOwnProperty("degree"))
                if (res.length !== 0) {
                    setShowDegree(true);
                    setShowbranch(true)
                    let degrees = res.map(obj => { return { label: obj.degree, value: obj.degree } });
                    setDegreeData(degrees)
                    setselectedEducationType(res[0].education)
                } else {
                    let res = educationList.filter(obj => obj.educationSort === parseInt(e.target.value) + 1)
                    setShowDegree(false);
                    setShowbranch(false)
                    if (res && res[0] && res[0].education) {
                        setselectedEducationType(res[0].education);
                    }
                }
            } else if (from === "degree") {
                setselectedEducationDegree(e.value)
                setDegreeError("");
                const res = educationList.filter(obj => obj.educationSort === selectedEducationSort && obj.degree === e.value)
                if (res.length !== 0) {
                    setShowbranch(true);
                    let branches = res.map(obj => obj.branch);
                    let branchArray = branches[0] && branches[0].map(result => {
                        return { label: result, value: result }
                    })
                    setBranchData(branchArray)
                } else {
                    setShowbranch(false)
                }
            } else if (from === 'branch') {
                setBranchError("")
                setselectedEducationBranch(e.value)
            }
        } catch (e) {
            console.log("E-------------->", e)
        }
    }

    const getProfileData = async () => {
        try {
            showLoader();
            let {
                data: {
                    data
                }
            } = await getProfile();

            hideLoader();
            return data;

        } catch (error) {
            hideLoader();
        }
    }
    const saveNext = async () => {
        try {
            if (!validationEduSort.includes(selectedEducationSort)) {
                if (selectedEducationType === "") {
                    return setEducationError("Please select Education")
                }
                if (selectedEducationDegree === "") {
                    return setDegreeError("Please select Degree")
                }
                if (selectedEducationBranch === "") {
                    return setBranchError("Please select branch")
                }
            }
            let candidateId = "";

            showLoader();
            if (getCookie("newUserMobileNo")) {
                const userObj = {
                    language: getCookie('lang') === 'hi' ? "Hindi" : "English",
                    // fullName: candidateName.trim(),
                    // whatsappMobile: candidateAlterNo,
                    // gender: candidateGender
                }
                // let {
                //     data: {
                //         data
                //     }
                // } = await createUser(userObj);
                candidateId = getCookie("candidateId");
                //setCookie("candidateId", data._id);
                // if (data && !data._id) {
                //     toast.error("Please try again later");
                //     return false;
                // }
            }
            //  if (selectedEducationType !== "" && selectedEducationDegree !== "" && selectedEducationBranch !== "") {
            const submitObj = {
                candidateEducationSort: selectedEducationSort,
                educationType: selectedEducationType,
                educationDegree: selectedEducationDegree,
                educationBranch: selectedEducationBranch,
                drivingLicense: haveDrivingLicense,
                carOwner: haveCar,
                bikeOwner: haveBike,
                campaignSourceURL: campignSource ? campignSource : "myCv",
                invitedBy: invitedBy ? invitedBy : "",
            }
         
            const dubprofileData = userData ? JSON.parse(JSON.stringify(userData)) : {};
            const reqObj = Object.assign(dubprofileData, submitObj);
            localStorage.setItem("userData", JSON.stringify(reqObj));
            
            if (reqObj._id) {
                delete reqObj._id;
            }
            
            dispatch(setProfileData(submitObj));
            if (!getCookie("newUserMobileNo")) {
                await callcandidateProfileUpdate(reqObj);
            }
            hideLoader();
            navigate('/desiredjob')
            //     }
        } catch (e) {

        }
    }

    return <>
        <form onSubmit={e => e.preventDefault()}>
            <div className="page slide-page">

                <div className={styles.TitleHead}>
                    <h3 dangerouslySetInnerHTML={{ __html: t('Education.title') }}></h3>
                </div>

                <div className={styles.formInputAre}>
                    <div className={styles.FormGroup}>
                    <label>{t('Education.educationLebl')}</label>
                        <select id="ele_education" className={styles.formControl} onChange={(e) => selectEducation(e, "education")} defaultValue={selectedEducationType} required>
                            <option value="">{t('Education.educationLebl')}</option>
                            {educationData.map((result, index) => {
                                if (result === selectedEducationType) {
                                    return <option key={"education" + index} style={{ backgroundColor: "#ff6600" }} selected value={index}>{result}</option>
                                } else {
                                    return <option key={"education" + index} value={index}>{result}</option>
                                }
                            })}
                        </select>
                        {educationError && <span className="errorMessage">{educationError}</span>}
                    </div>
                    {showDegree && <div className={styles.FormGroup}>
                        <label>{t('Education.degreeLebl')}</label>
                        {/* <select className={styles.formControl} onChange={(e) => selectEducation(e, "degree")} defaultValue={selectedEducationDegree} required>
                            <option value="">Choose your degree</option>
                            {degreeData.map((result, index) => {
                                if (result === selectedEducationDegree) {
                                    return <option key={"degree" + index} style={{ backgroundColor: "#ff6600" }} selected value={result}>{result}</option>
                                } else {
                                    return <option key={"degree" + index} value={result}>{result}</option>
                                }
                            }
                            )}
                        </select> */}
                        <Select value={{ label: selectedEducationDegree ? selectedEducationDegree : t('Education.chooseDegree'), value: selectedEducationDegree ? selectedEducationDegree : "" }}
                            styles={customStyles}
                            onChange={(e) => selectEducation(e, "degree")}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            options={degreeData} />
                        {degreeError && <span className="errorMessage">{degreeError}</span>}
                    </div>}
                    {showbranch && <div className={styles.FormGroup}>
                        <label>{t('Education.branchLebl')}</label>
                        {/* <select className={styles.formControl} onChange={(e) => selectEducation(e, "branch")} defaultValue={selectedEducationBranch} required>
                            <option value="">Choose your stream</option>
                            {branchData.length !== 0 && branchData[0].map((result, index) => {
                                if (result === selectedEducationBranch) {
                                    return <option key={"branch" + index} style={{ backgroundColor: "#ff6600" }} selected value={result}>{result}</option>
                                } else {
                                    return <option key={"branch" + index} value={result}>{result}</option>
                                }
                            }
                            )}
                        </select> */}
                        <Select value={{ label: selectedEducationBranch ? selectedEducationBranch : t('Education.chooseStream'), value: selectedEducationBranch ? selectedEducationBranch : "" }}
                            styles={customStyles}
                            onChange={(e) => selectEducation(e, "branch")}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            options={branchData} />
                        {branchError && <span className="errorMessage">{branchError}</span>}
                    </div>}
                    <div className={styles.checkBoxGroup}>
                        <div className={styles.FormGroup}>
                            <h3 >{t('Education.selctionTitle')}</h3>
                            <div className={styles.newSelect}>
                                <ul>
                                    <li>
                                        <label>{t('Education.drivingLicense')}</label>
                                        <label className={styles.checkbox}>
                                            <input className={styles.checkboxInput} type="checkbox" checked={haveDrivingLicense} name="dl" onChange={() => sethaveDrivingLicense(!haveDrivingLicense)} />
                                            <span className={styles.checkboxInner}></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label>{t('Education.bike')}</label>
                                        <label className={styles.checkbox}>
                                            <input className={styles.checkboxInput} type="checkbox" name="dl" checked={haveBike} onChange={() => sethaveBike(!haveBike)} />
                                            <span className={styles.checkboxInner}></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label>{t('Education.car')}</label>
                                        <label className={styles.checkbox}>
                                            <input className={styles.checkboxInput} type="checkbox" name="dl" checked={haveCar} onChange={() => sethaveCar(!haveCar)} />
                                            <span className={styles.checkboxInner}></span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.saveNext}>
                    <button className={styles.next} onClick={() => saveNext()}>{t("common.save")} </button>
                </div>
                <div className={styles.Back}>
                    <button className={styles.next} onClick={() => navigate('/resumeedit')}>{t('common.back')}</button>
                </div>
            </div>

            <div className={styles.newHelp}>
                    <div className={styles.helpCall}>
                    <a href="tel:8595141564">
                        <img  src={cellHelp}/>
                    </a>
                    </div>
                </div>

        </form>
    </>
}

export default Education;