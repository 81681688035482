import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store/index';
import { createBrowserHistory } from 'history';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_hi from "./translations/hi/common.json";
import common_en from "./translations/en/common.json";
import { getCookie } from './util/helper';

const history = createBrowserHistory();
i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: getCookie('lang') ? getCookie('lang') : 'en',                              // language to use
  resources: {
    en: {
      common: common_en               // 'common' is our custom namespace
    },
    hi: {
      common: common_hi
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <App history={history} />
    </Provider>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
