import styles from './Achieve.module.scss'
import plusIcon from '../../assets/images/plusIcon.png';
import edit from '../../assets/images/edit.png';
import deleteImg from "../../assets/images/delet.png"
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setAchieveMentDetails, selectedAchieveIndex, setProfileData } from '../../store/reducer';
import { useNavigate } from 'react-router-dom';
import { callcandidateProfileUpdate } from '../../util/common';
import { useTranslation } from 'react-i18next';
import { getCookie } from '../../util/helper';
import cellHelp from '../../assets/images/needHelp.png';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const AddAchievements = (props) => {
    const { t } = useTranslation('common');
    const { setProgressCount, setShowProgressBar } = props;
    const { achieveMentDetails, profileData } = useSelector(state => state)
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    console.log(userData)
    const { achievementDetails, isStudent, yearsOfExperience } = userData || profileData;
    const [achieveDetails, setAchieveDetails] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [callOnce, setCallOnce] = useState(false);

    useEffect(() => {
        if (!callOnce) {
            if (achievementDetails && achievementDetails.length !== 0) {
                setAchieveDetails(achievementDetails);
                const reqObj = { achieveDetails: achievementDetails, update: false }
                dispatch(setAchieveMentDetails(reqObj))
            }

            if (achieveMentDetails && achieveMentDetails.length !== 0) {
                setAchieveDetails(achieveMentDetails);
                const reqObj = { achieveDetails: achieveMentDetails, update: false }
                dispatch(setAchieveMentDetails(reqObj))
            }
            //  else {
            //     setAchieveDetails(achievementDetails);
            //     const reqObj = { achieveDetails: achievementDetails, update: false }
            //     dispatch(setAchieveMentDetails(reqObj))
            // }
            setCallOnce(true)
        }

        // if (achieveDetails && achieveDetails.length === 0) {
        //     setProgressCount(0);
        // } else {
        //     setProgressCount(100)
        // }
        setProgressCount(75);
        setShowProgressBar(true);

    }, [achieveDetails])

    const editExperiance = (index) => {
        try {
            const maxAchievementsAllowed = yearsOfExperience === "0" ? 1 : 4;
    
            if (achieveDetails.length < maxAchievementsAllowed) {
                dispatch(selectedAchieveIndex(index.toString()));
                navigate('/updateAchievement');
            } else {
                toast.error(`You can only add maximum of ${maxAchievementsAllowed} achievements.`);
            }
        } catch (e) {
            console.log("EE------------->", e);
        }
    }
    const deleteExperiance = (index) => {
        let removeExp = JSON.parse(JSON.stringify(achieveDetails));
        removeExp.splice(index, 1);
        setAchieveDetails(removeExp);
        const reqObj = { expDetails: removeExp, update: false }
        dispatch(setAchieveMentDetails(reqObj));
    }

    const submitHandler = async () => {
        try {
            const submitObj = {
                achievementDetails: achieveDetails
            }
            const dubprofileData = JSON.parse(JSON.stringify(userData));
            const reqObj = Object.assign(dubprofileData, submitObj);
            localStorage.setItem("userData", JSON.stringify(reqObj));
            if (reqObj._id) {
                delete reqObj._id;
            }

            dispatch(setProfileData(submitObj));
            if (!getCookie("newUserMobileNo")) {
                await callcandidateProfileUpdate(reqObj);
            }
            navigate('/projects')
        } catch (e) {

        }
    }

    return (
        <div>
            <form onSubmit={e => e.preventDefault()}>
                <div className="page slide-page">

                    <div className={styles.TitleHead}>
                        <h3 dangerouslySetInnerHTML={{ __html: t('Achievements.title') }}></h3>
                    </div>
                    <div className={styles.formInputAre}>
                        <div className={styles.studentExp}>
                            <div className={styles.ExpStu}>
                                <div className={styles.expBlok}>

                                    {achieveDetails && achieveDetails.length !== 0 && achieveDetails.map((result, index) => {
                                        const { title, description, date } = result;
                                        return <div className={styles.listExp} key={`achieveDetails${index}`}>
                                            <span className={styles.bullet}></span>
                                            <div className={styles.ExpWork}>
                                                <span>{date}</span>
                                                <ul>
                                                    <li style={{ marginRight: "4px" }}><img alt='deleteImage' src={deleteImg} onClick={() => deleteExperiance(index)} /></li>
                                                    <li><img alt='edit' style={{ margin: "2px" }} src={edit} onClick={() => editExperiance(index)} /></li>
                                                </ul>
                                                <h3>{title}</h3>
                                                <p>{description}</p>
                                            </div>
                                        </div>
                                    })}

                                </div>

                                <button onClick={() => editExperiance("")} className={styles.addExp}>{t('Achievements.addAchieve')} <img alt='plus' src={plusIcon} /></button>
                            </div>
                        </div>
                    </div>

                    <div className={styles.saveNext}>
                        <button className={styles.next} style={{ width: achieveDetails && achieveDetails.length === 0 ? "70%" : "100%" }} onClick={() => submitHandler()}>{t("common.save")} </button>
                        {achieveDetails && achieveDetails.length === 0 && <button onClick={() => navigate('/projects')} className={styles.skipButton} >{t('common.skip')} </button>}
                    </div>
                    <div className={styles.Back}>
                        <button className={styles.next} onClick={() => navigate('/resumeedit')}>{t('common.back')}</button>
                    </div>
                </div>
                <div className={styles.newHelp}>
                    <div className={styles.helpCall}>
                    <a href="tel:8595141564">
                        <img  src={cellHelp}/>
                    </a>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddAchievements;