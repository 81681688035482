import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addTodo, loginData, setProfileData, newUserPhoneNo, setCampignSource, setInvitedBy } from '../../store/reducer';
import { candidateLogin,candidateRegister, requestOtpForLogin, verifyOtpForLogin, getProfile, updateCampignSource } from '../../services/Api/candidate';
import { toast } from 'react-toastify';
import logoImage from '../../assets/images/logoColor.svg'
import { showLoader, hideLoader } from '../../util/helper';
import OtpInput from "otp-input-react";
import styles from './Login.module.scss';
import { setCookie, clearCookie, getCookie } from '../../util/helper';
import { useLocation, useNavigate } from 'react-router-dom';
import checkNew from '../../assets/images/newCheck.svg';
import engImage from '../../assets/images/englishImg.svg'
import hindImage from '../../assets/images/india.svg';
import { useTranslation } from 'react-i18next'


const Login = (props) => {
    const { t, i18n } = useTranslation('common');
    const { userData } = useSelector(state => state);
    //const { loginPage } = loginReducer;
    const dispatch = useDispatch();
    const [umobileError, setUmobileError] = useState(false)
    const [whatsappval, setWhatsappval] = useState("");
    const [text, setText] = useState("");
    const [whatsappError, setWhatsappError] = useState("");
    const [otpTiming, setOtpTiming] = useState(180);
    const [intervalId, setIntervalId] = useState(0);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [otpNumber, setOtpNumber] = useState();
    const [otpError, setOtpError] = useState(false);
    const [callOnce, setCallOnce] = useState(false);
    const [selectedLang, setSelectedLang] = useState("en");
    let message = {
        required: 'Whatsapp number is required',
        pattern: 'Name should contain only numbers',
        maxLength: "Maximum allowed numbers 10"
    }

    const navigate = useNavigate();

    const useQuery = () => {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();
    let source = query.get("source");
    source = source ? window.atob(source) : "myCv";
    let invitedBy = query.get("invitedBy");
    invitedBy = invitedBy ? invitedBy : "";

    useEffect(() => {
        if (intervalId && otpTiming <= 0) {
            clearInterval(intervalId);
            setOtpTiming(180);
            setIntervalId(0);
            setIsOtpSent(false)
        }
        if (!callOnce) {
            updateSource(source)
            setCookie("lang", "en");
            localStorage.removeItem("userData");
            clearCookie("token");
            clearCookie("IsloggedIn");
            clearCookie("newUserMobileNo");
            clearCookie("IsFirstTime");
            setCallOnce(true)
        }
        if (getCookie("lang")) {
            i18n.changeLanguage(getCookie("lang"))
            setSelectedLang(getCookie("lang"))
        }

        if (window.location.pathname.toLowerCase().includes('hindi')) {
            changeLanguage('hi')
        }
        if (window.location.pathname.toLowerCase().includes('english')) {
            changeLanguage('en')
        }

        dispatch(setCampignSource(source))
        dispatch(setInvitedBy(invitedBy))
    }, [otpTiming]);


    const updateSource = async (source) => {
        try {
            await updateCampignSource({ openPage: source })
        } catch (e) {

        }
    }

    
    const onChangeHandler = e => {
        setUmobileError(false)
        setWhatsappError("")
        setWhatsappval(e.target.value)
    }

    const onKeyUpHandler = e => {
        if (e.key === 'Enter') {
            onSubmitHandler()
        }
    }

    const addTodoHandler = (event) => {
        event.preventDefault();
        // update the state here using addTodo action
        // action only receive one parameter, which is payload
        dispatch(addTodo(text));
        setText('');
    };

    const handleOtpTiming = async () => {
        let interval = setInterval(() => {
            setOtpTiming(otpTiming => otpTiming - 1);
        }, 1000);
        setIntervalId(interval);
    }

    const candidateSignup = async (params) =>{
        let candidateId = "";
        try {
            showLoader();
            let {
                data: {
                    data, status
                }
            } = await candidateRegister(params);
            console.log(data)
            if (status === 200 ) {
                const requestParam = { userId: data._id, type: "candidate", whatsappMobile: params.whatsappMobile };
                dispatch(loginData({
                    type: "candidate",
                    whatsappMobile: params.whatsappMobile,
                    userId: data._id
                }))
                candidateId = data._id;
                setCookie("candidateId", data._id);
                sendOtp(requestParam)
            } 
            
        } catch (errors) {
            const {
                data: {
                    message
                }
            } = errors;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }

    const sendOtp = async (data) => {
        try {
            showLoader();
            let {
                data: {
                    message, status
                }
            } = await requestOtpForLogin(data);
            setIsOtpSent(true);
            handleOtpTiming();
            hideLoader();
            toast.success(message ? message : "Otp has sent successfully");
        } catch (errors) {
            const {
                data: {
                    message
                }
            } = errors;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }

    const onSubmitHandler = async () => {
        if (!whatsappval || !/^[6-9]\d{9}$/.test(whatsappval)) {
            setUmobileError(true)
            setWhatsappError("Please enter your WhatsApp number")
        } else {
            if (/^[6-9]\d{9}$/.test(whatsappval)) {
                try {
                    const {
                        data: {
                            data, status
                        }
                    } = await candidateLogin({ whatsappMobile: whatsappval });
                    if (status === 200 && data.userType === "employer") {
                        toast.info("You already exist as employer or HR, please use a different phone number to create your CV");
                    } else if (status === 200 && data.userType === "candidate") {
                        const requestParam = { userId: data._id, type: data.userType, whatsappMobile: data.whatsappMobile };
                        dispatch(loginData({
                            type: data.userType,
                            whatsappMobile: data.whatsappMobile,
                            userId: data._id
                        }))
                        sendOtp(requestParam)
                    }
                } catch (e) {
                    const { data } = e || {};
                    toast.error((data && data.message) ? data.message : "We are sorry for inconvenience");
                    if (data && (data.appStatus === 811 || data.appStatus === 812)) {
                        setTimeout(() => {
                            dispatch(newUserPhoneNo(whatsappval))
                            var now = new Date();
                            now.setTime(now.getTime() + 8 * 3600 * 1000); //expire time 8 hours
                            let cookieOptions = {
                                expires: now //seconds to expire
                            }
                            setCookie("IsloggedIn", true, cookieOptions);
                            setCookie("newUserMobileNo", whatsappval, cookieOptions);
                            setCookie("IsFirstTime", true, cookieOptions);
                            const requestParam = { language: "English", fullName: 'Unknown', whatsappMobile: whatsappval,gender:"Male" };
                            candidateSignup(requestParam)
                            //window.location.href = "https://www.jobsgaar.com/job"
                            //navigate("/profiles");
                            
                        }, 1000);

                    } else {
                        navigate("/login");
                    }
                }
            } else {
                setUmobileError(true)
                if (whatsappval.length > 10) {
                    setWhatsappError("WhatsApp 10-digit number is required to connect with you");
                } else {
                    setWhatsappError("Please enter valid phone number");
                }
            }
        }
    }

    const handleOtpChange = (data) => {
        setOtpNumber(data);
        data ? setOtpError(false) : setOtpError(true);
    }

    const getProfileData = async () => {
        try {
            showLoader();
            let {
                data: {
                    data
                }
            } = await getProfile();

            hideLoader();
            return data;

        } catch (error) {
            hideLoader();
        }
    }

    const otpSubmit = async () => {
        if (!otpNumber) {
            setOtpError(true);
            return;
        }
        try {
            const submitData = Object.assign({}, userData);

            submitData.otp = otpNumber;
            submitData.deviceToken = "myCVPortal";
            showLoader();
            let {
                data: {
                    data
                }
            } = await verifyOtpForLogin(submitData);
            if (data.refreshToken) {
                hideLoader();
                toast.success(message ? message : "SignIn Successfull");
                var now = new Date();
                now.setTime(now.getTime() + 8 * 3600 * 1000); //expire time 8 hours
                let cookieOptions = {
                    expires: now //seconds to expire
                }
                const isFirstTimeCookie = getCookie("IsFirstTime");
                if (isFirstTimeCookie === "true") {
                    navigate("/education");
                }
                else{
                setCookie("token", data.refreshToken, cookieOptions);
                setCookie("id", data._id, cookieOptions);
                setCookie("IsloggedIn", true, cookieOptions);
                let candidateData = await getProfileData();
                dispatch(setProfileData(candidateData))
                localStorage.setItem("userData", JSON.stringify(candidateData));
                clearInterval(intervalId);
                setOtpTiming(180);
                setIntervalId(0);
                //window.location.href = '/profiles'
                // history.push("/profiles")
                navigate('/digitalcv')
            }
            }
        } catch (error) {
            const { data: { message } } = error;
            hideLoader();
            toast.error(message ? message : "Failed, Try again");
        }
    }

    const changeLanguage = (lang) => {
        setCookie("lang", lang);
        i18n.changeLanguage(lang)
        setSelectedLang(lang)
    }

    return (<div>
        <div className={styles.formOuter}>
            <form className={styles.formLo} onSubmit={e => e.preventDefault()} >
                {!isOtpSent ? <div className={styles.mobileIn} style={{ display: isOtpSent ? "none" : "block" }}>
                    <div className={styles.headSec}>
                        <img alt="jobgsaar" src={logoImage} />
                        <button className={styles.cntntBtn}>Contact Us</button>
                    </div>
                    <div className={styles.otpHead}>
                        <img alt="SendOtp" src="../../assets/images/sendOtp.png" />
                        <h3>Create a Professional <br /><span>Digital Resume</span></h3>
                        <p dangerouslySetInnerHTML={{ __html: t("LoginPage.bannerText") }}></p>
                        <div className={styles.languageSelect}>
                            <ul>
                                <li onClick={() => changeLanguage('en')}>
                                    {selectedLang === 'en' && <img className={styles.checkImage} src={checkNew} />}
                                    <button className={styles.buttonLang}>
                                        <img src={engImage} />
                                        <h3>English</h3>
                                    </button>
                                </li>
                                <li onClick={() => changeLanguage('hi')}>
                                    {selectedLang === 'hi' && <img className={styles.checkImage} src={checkNew} />}
                                    <button className={styles.buttonLang}>
                                        <img src={hindImage} />
                                        <h3>हिंदी</h3>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.otpFill}>
                        <div className={styles.mobileNo}>
                            <div className={styles.formGroup} >
                                <span>+91</span>
                                <input type="number" maxLength="10" className={styles.formControl} onChange={e => onChangeHandler(e)} onKeyUp={(e) => otpNumber ? undefined : onKeyUpHandler(e)}
                                    value={whatsappval} placeholder="Enter your mobile number" name="" />
                            </div>
                            {umobileError && <span className="errorMessage">{whatsappError}</span>}
                        </div>
                    </div>

                    <div className={`${styles.formGroup} ${styles.saveNext}`} style={{ marginTop: "15px" }}>
                        <div className={styles.saveNext}>
                            <button className={styles.next} onClick={() => onSubmitHandler()}>{t("LoginPage.otpButton")}</button>
                        </div>
                    </div>
                </div> :

                    <div className={styles.otpIn} style={{ display: !isOtpSent ? "none" : "block" }}>
                        <div className={styles.headSec}>
                            <img alt="jobgsaar" src={logoImage} />
                            <button className={styles.cntntBtn}>Contact Us</button>
                        </div>
                        <div className={styles.otpHead}>
                            <img alt="VerifyOtp" src="../../assets/images/verifyOtp.png" />
                            <h3>OTP for<br /><span>Verification.</span></h3>
                            <p>{t("LoginPage.otpVerficationSent")}<span>+91 {whatsappval}</span> {t("LoginPage.otpVerficationSentAfter")}</p>
                            <p>{t("LoginPage.incorrectText")} <a href=''>{t("LoginPage.clickHere")}</a></p>
                        </div>
                        <div className={styles.otpScreen}>
                            <div className={styles.formGroup}>
                                <div className={styles.fillIn}>
                                    <OtpInput
                                        value={otpNumber}
                                        onChange={handleOtpChange}
                                        autoFocus
                                        OTPLength={6}
                                        otpType="number"
                                        disabled={false}
                                        placeholder="------"
                                    />
                                    {otpError && <span className='errorMessage mt-3'>Otp numer is required</span>}
                                </div>
                                <h3><span>{otpTiming}</span>seconds remaining </h3>
                            </div>
                        </div>
                        <div className={styles.formGroup}>
                            <div className={styles.saveNext}>
                                <button type='submit' className={styles.next} onClick={() => otpSubmit()} >Verify</button>
                            </div>
                        </div>
                    </div>}
            </form>
        </div>



    </div >)
}

export default Login;