import styles from './Confirmation.module.scss';
import Popup from '../Popup';
import closeJob from '../../../assets/images/closeOrg.svg';
import removeimage from '../../../assets/images/removeImage.svg'

const Confirmation = (props) => {
    const { isOpen, isClose, children, deleteUploadedFile, selectedFileName } = props;
    return <Popup isOpen={isOpen} isClose={isClose} children={children}>
        <div className={styles.aspirFill}>
            <img src={closeJob} onClick={isClose} alt='CloseIcon' />
            <div className={styles.cancelPopup}>
                <h3>Remove uploaded file <span></span></h3>
                <img src={removeimage} />
                <p>Are you sure you want to delete?</p>
                <button className={styles.btnCancel} onClick={() => isClose()}>Cancel</button>
                <button className={styles.btnOk} onClick={() => { deleteUploadedFile(selectedFileName); isClose() }}>Yes</button>
            </div>
        </div>
    </Popup>
}

export default Confirmation;