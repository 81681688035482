import styles from './EmploymentStatus.module.scss';
import plusIcon from '../../assets/images/plusIcon.png';
import edit from '../../assets/images/edit.png';
import deleteImg from "../../assets/images/delet.png"
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setExperianceDetails, selectedEmpDetails, setProfileData } from '../../store/reducer'
import { useDispatch, useSelector } from 'react-redux';
import { callcandidateProfileUpdate } from '../../util/common';
import { useTranslation } from 'react-i18next';
import { getCookie } from '../../util/helper';
import cellHelp from '../../assets/images/needHelp.png';

const EmploymentStatus = (props) => {
    const { t } = useTranslation('common');
    const { setProgressCount, setShowProgressBar } = props;
    const { expDetails, profileData } = useSelector(state => state)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    const { experienceDetails, isStudent } = userData || profileData;
    const [empDetails, setEmpDetails] = useState([]);
    const [callOnce, setCallOnce] = useState(false);
    // const [issStudent, setIsStudent] = useState(false);

    const totalProgress = 100;
    const totalFields = 6;
    const calculateProgress = Math.round(totalProgress / totalFields);
    //  const studentRef = useRef();

    useEffect(() => {
        if (!callOnce) {
            if (experienceDetails && experienceDetails.length !== 0) {
                setEmpDetails(experienceDetails);
                const reqObj = { expDetails: experienceDetails, update: false }
                dispatch(setExperianceDetails(reqObj))
            }
            if (expDetails && expDetails.length !== 0) {
                setEmpDetails(expDetails);
                const reqObj = { expDetails, update: false }
                dispatch(setExperianceDetails(reqObj))
            }
            setCallOnce(true)
        }


        let progressbarSize = 0;
        expDetails.map((result) => {
            for (const property in result) {
                if (property) {
                    progressbarSize = progressbarSize + calculateProgress;
                }
            }

        })
        setProgressCount(45);
        setShowProgressBar(true);
        

    }, [empDetails, isStudent])
    const [errorMessage, setErrorMessage] = useState(null);

    const displayErrorMessage = (message) => {
        setErrorMessage(message);
    
        // Clear the message after a certain duration (e.g., 3 seconds)
        setTimeout(() => {
            setErrorMessage(null);
        }, 3000);
    };
    
    const editExperiance = (index) => {
        if (empDetails.length < 7) {
            dispatch(selectedEmpDetails(index.toString()));
            navigate('/updateEmployee');
        } else {
            displayErrorMessage("Only 7 past experiences are allowed.");
        }
    }

    const deleteExperiance = (index) => {
        let removeExp = JSON.parse(JSON.stringify(empDetails));
        removeExp.splice(index, 1);
        setEmpDetails(removeExp);
        const reqObj = { expDetails: removeExp, update: false }
        dispatch(setExperianceDetails(reqObj));
    }

    const submitHandler = async () => {
        try {
            //if (empDetails !== expDetails) {
            const submitObj = {
                experienceDetails: empDetails,
                //isStudent: issStudent
            }
            const dubprofileData = JSON.parse(JSON.stringify(userData));
            const reqObj = Object.assign(dubprofileData, submitObj);
            localStorage.setItem("userData", JSON.stringify(reqObj));
            if (reqObj._id) {
                delete reqObj._id;
            }

            dispatch(setProfileData(submitObj));
            if (!getCookie("newUserMobileNo")) {
                await callcandidateProfileUpdate(reqObj);
            }
            //}
            navigate('/profiles')
        } catch (e) {
            console.log("E----------->", e)
        }
    }

    return (
        <div>
            <form onSubmit={e => e.preventDefault()}>
                <div className="page slide-page">
                    <div className={styles.TitleHead}>
                        <h3 dangerouslySetInnerHTML={{ __html: t('EmploymentStatus.title') }}></h3>
                    </div>
                    <div className={styles.formInputAre}>
                        <div className={styles.studentExp}>
                            <div className={styles.ExpStu}>
                                {/* <ul>
                                    <li>
                                        <label>I’m just a Student</label>
                                        <label className={styles.checkbox}>
                                            <input className={styles.checkboxInput} type="checkbox" name="dl" onClick={e => setIsStudent(!issStudent)} ref={studentRef} />
                                            <span className={styles.checkboxInner}></span>
                                        </label>
                                    </li>
                                </ul> */}

                                {empDetails && empDetails.length !== 0 && <div className={styles.expBlok}>
                                    {empDetails.map((item, index) => <div className={styles.listExp} key={`emp${index}`}>
                                        <span className={styles.bullet}></span>
                                        <div className={styles.ExpWork}>
                                            <span>{item.fromMonth} - {item.toMonth === "" ? "Currently Working" : item.toMonth}</span>
                                            <ul>
                                                <li style={{ marginRight: "4px" }}><img alt="DeleteImg" src={deleteImg} onClick={() => deleteExperiance(index)} /></li>
                                                <li><img alt="EditImg" style={{ margin: "2px" }} src={edit} onClick={() => editExperiance(index)} /></li>
                                            </ul>
                                            <h3>{item.lastCompany}</h3>
                                            <p>{item.lastPosition}<span>₹ {item.lastSalary}</span></p>
                                        </div>
                                    </div>)}
                                </div>}

                                <button className={styles.addExp} onClick={() => editExperiance("")}>{t('EmploymentStatus.addExperience')} <img alt="PlusIcon" src={plusIcon} /></button>
                            </div>
                        </div>
                    </div>

                    <div className={styles.saveNext}>
                    {errorMessage && (
                        <div className={styles.errorMessage}>
                            <p>{errorMessage}</p>
                        </div>
                    )}
                        <button className={styles.next} onClick={() => submitHandler()}>{t("common.save")} </button>
                    </div>
                    <div className={styles.Back}>
                        <button className={styles.next} onClick={() => navigate('/resumeedit')}>{t('common.back')}</button>
                    </div>
                </div>
                <div className={styles.newHelp}>
                    <div className={styles.helpCall}>
                    <a href="tel:8595141564">
                        <img  src={cellHelp}/>
                    </a>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default EmploymentStatus;