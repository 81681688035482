import styles from './Project.module.scss';
import plusIcon from '../../assets/images/plusIcon.png';
import edit from '../../assets/images/edit.png';
import deleteImg from "../../assets/images/delet.png";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCandidateProjects, selectedCandidateProjectIndex, setProfileData } from '../../store/reducer';
import { callcandidateProfileUpdate } from '../../util/common';
import { useTranslation } from 'react-i18next';
import { getCookie } from '../../util/helper';
import cellHelp from '../../assets/images/needHelp.png';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import isEqual from 'lodash/isEqual';

const AddProjects = (props) => {
    const { t } = useTranslation('common');
    const { setProgressCount, setShowProgressBar } = props;
    const { candidateProjectDetails, profileData } = useSelector(state => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    const { projectDetails = [], yearsOfExperience } = userData || profileData;
    const [userProjectDetails, setUserProjectDetails] = useState(projectDetails);

    const deleteProject = (index) => {
        // Create a copy of userProjectDetails without mutating the original array
        const updatedProjectDetails = userProjectDetails.filter((_, i) => i !== index);
        // Update local state
        setUserProjectDetails(updatedProjectDetails);
        
        // Dispatch action to update Redux store
        const reqObj = { projectDetails: updatedProjectDetails, update: false };
        dispatch(setCandidateProjects(reqObj));
    };
    useEffect(() => {
        // Ensure not to update state unnecessarily
        if (userProjectDetails.length !== 0 && !isEqual(userProjectDetails, projectDetails)) {
            dispatch(setCandidateProjects({ projectDetails: userProjectDetails, update: false }));
        } else if (candidateProjectDetails.length !== 0 && !isEqual(candidateProjectDetails, userProjectDetails)) {
            setUserProjectDetails(candidateProjectDetails);
        }
        
        setProgressCount(90);
        setShowProgressBar(true);
    }, [userProjectDetails, projectDetails, candidateProjectDetails, dispatch, setProgressCount, setShowProgressBar]);
    const [errorMessage, setErrorMessage] = useState(null);

    const displayErrorMessage = (message) => {
        setErrorMessage(message);
        setTimeout(() => {
            setErrorMessage(null);
        }, 3000);
    };

    const editProject = (index) => {
        const maxProjectsAllowed = yearsOfExperience === '0' ? 1 : 4;

        if (userProjectDetails.length < maxProjectsAllowed) {
            dispatch(selectedCandidateProjectIndex(index.toString()));
            navigate('/updateProject');
        } else {
            toast.error(`You can only add a maximum of ${maxProjectsAllowed} projects.`);
        }
    };


    const submitHandler = async () => {
        try {
            const updatedProjectDetails = userProjectDetails.length ? userProjectDetails : projectDetails;
            if (updatedProjectDetails !== projectDetails) {
                const submitObj = { projectDetails: updatedProjectDetails };
                const dubprofileData = JSON.parse(JSON.stringify(userData));
                const reqObj = Object.assign(dubprofileData, submitObj);
                localStorage.setItem("userData", JSON.stringify(reqObj));
                if (reqObj._id) {
                    delete reqObj._id;
                }

                dispatch(setProfileData(submitObj));
                if (!getCookie("newUserMobileNo")) {
                    await callcandidateProfileUpdate(reqObj);
                }
            }
            navigate('/aspirationFill');
        } catch (e) {
            console.error('Error submitting project details:', e);
        }
    };

    return (
        <div>
            <form onSubmit={e => e.preventDefault()}>
                <div className="page slide-page">
                    <div className={styles.TitleHead}>
                        <h3 dangerouslySetInnerHTML={{ __html: t('projects.title') }}></h3>
                    </div>

                    <div className={styles.formInputAre}>
                        <div className={styles.studentExp}>
                            <div className={styles.ExpStu}>
                                <div className={styles.expBlok}>
                                    {userProjectDetails.length !== 0 && userProjectDetails.map((result, index) => (
                                        <div key={`projects${index}`} className={styles.listExp}>
                                            <span className={styles.bullet}></span>
                                            <div className={styles.ExpWork}>
                                                <span>{result.startDate} - {result.endDate}</span>
                                                <ul>
                                                    <li style={{ marginRight: "4px" }}>
                                                        <img alt='DeleteIcon' src={deleteImg} onClick={() => deleteProject(index)} />
                                                    </li>
                                                    <li>
                                                        <img alt='EditIcon' style={{ margin: "2px" }} src={edit} onClick={() => editProject(index)} />
                                                    </li>
                                                </ul>
                                                <h3>{result.projectName}</h3>
                                                <p>{result.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <button className={styles.addExp} onClick={() => editProject("")}>{t('projects.addProject')} <img alt='PlusIcon' src={plusIcon} /></button>
                            </div>
                        </div>
                    </div>

                    <div className={styles.saveNext}>
                        <button style={{ width: userProjectDetails.length === 0 ? "70%" : "100%" }} className={styles.next} onClick={() => submitHandler()}>{t("common.save")}</button>
                        {userProjectDetails.length === 0 && <button onClick={() => navigate('/aspirationFill')} className={styles.skipButton}>{t('common.skip')}</button>}
                    </div>
                    <div className={styles.Back}>
                        <button className={styles.next} onClick={() => navigate('/resumeedit')}>{t('common.back')}</button>
                    </div>
                </div>
                <div className={styles.newHelp}>
                    <div className={styles.helpCall}>
                        <a href="tel:8595141564">
                            <img src={cellHelp} />
                        </a>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default AddProjects;
