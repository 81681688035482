import style from './ResumeFirst.module.scss';
import { useEffect } from 'react';


const ResumeSecond = () => {
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    const { language, projectDetails, achievementDetails } = userData || {};
    let langSplit = [language];
    if (language && language.includes(",")) {
        langSplit = language.split(",");
    }

    useEffect(() => {
    }, [])

    return (
        <div>

            <section className={style.resume}>
                <div className={style.resume_bottom}>
                    <div className={style.rbottom_left}>

                    </div>
                    <div className={style.rbottom_right}>
                        <div className={style.r_education}>
                            <h2>PROJECTS</h2>
                            <ul>
                                {projectDetails.map((results) => {
                                    const { projectName, description, startDate, endDate } = results;
                                    return <li>
                                        <div className={style.r_ed_left}>
                                            <p>{projectName} <span>{startDate}-{endDate}</span></p>
                                        </div>
                                        <div className={style.r_ed_right}>
                                            <p>{description}</p>
                                        </div>
                                    </li>
                                })}

                            </ul>
                        </div>
                        <div className={style.r_education}>
                            <h2>ACHIEVEMENT</h2>
                            <ul>
                                {achievementDetails.map((results) => {
                                    const { title, description, date } = results;
                                    return <li>
                                        <div className={style.r_ed_left}>
                                            <p>{title} <span>{date}</span></p>
                                        </div>
                                        <div className={style.r_ed_right}>
                                            <p>{description}</p>
                                        </div>
                                    </li>
                                })}

                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ResumeSecond;