import styles from '../SignOut/SignOut.module.scss';
import Popup from '../Popup';
import closeJob from '../../../assets/images/closeOrg.svg';
import signOut from '../../../assets/images/exitImage.svg';
import { useTranslation } from 'react-i18next';

const SignOut = (props) => {
    const { t } = useTranslation('common');
    const { isOpen, isClose, children } = props;

    return <Popup isOpen={isOpen} isClose={isClose} children={children}>
        <div className={styles.aspirFill}>
            <h3>{t('SignoutPopup.title')}<span></span></h3>
            <img src={closeJob} onClick={isClose} alt='CloseIcon' />
            <div className={styles.signOutPop}>
                <img src={signOut} alt="" />
                <h3>{t('SignoutPopup.question')}</h3>
                <button className={styles.signOutButton} onClick={() => window.location.href = '/login'}>{t('SignoutPopup.yes')}</button>
                <button className={styles.signOutCancel} onClick={() => isClose()}>{t('SignoutPopup.cancel')}</button>
            </div>
        </div>
    </Popup>
}

export default SignOut;