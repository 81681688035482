import axios from "axios";
import { toast } from "react-toastify";
import { getCookie, clearCookie } from "../../util/helper";

export const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(
    (config) => {
        config.headers.accept = "application/json";
        const token = getCookie("token");

        // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MjAyMzI1MDBhYTZlMmUzM2Q1NjdkYzgiLCJ3aGF0c2FwcE1vYmlsZSI6Ijk5OTg4ODc2NTQiLCJ0b2tlblR5cGUiOiJyZWZyZXNoVG9rZW4iLCJ1c2VyVHlwZSI6ImVuZFVzZXIiLCJpYXQiOjE2NTA2MTg3NjYsImV4cCI6MTY4MjE1NDc2Nn0.7NgfEfq-C5AfmfF21MGfuOlU2VWpvd4j3TDVosAb77Y";
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error?.response?.status === 401) {
            clearCookie("token");
            localStorage.removeItem('userData');
            window.location.href = "/";
            toast.error("Unauthorized");
        }
        else if (error?.response?.status === 400 && error?.response?.data?.message === 'Invalid token') {
            clearCookie("token");
            localStorage.removeItem('userData');
            window.location.href = "/";
            toast.error("Session timeout");
        }
        return Promise.reject(error.response);
    }
);