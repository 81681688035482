import React, { useEffect, useState } from "react";
import styles from "./DigitalResumeFirst.module.scss";
import contectRound from '../../assets/images/contect.svg'
import emailIcon from '../../assets/images/emlBlk.svg'
import mobileIcon from '../../assets/images/conBlk.svg'
import logoImg from '../../assets/images/logoColor.svg';
import dobIcon from '../../assets/images/dobBlk.svg'
import bothIcon from '../../assets/images/genBlk.svg'
import locIcon from '../../assets/images/locBlk.svg'
import facIcon from '../../assets/images/faceblk.svg'
import bioVision from '../../assets/images/bioVisop.svg'
import desiJob from '../../assets/images/desijobop.svg'
import eduIcon from '../../assets/images/eduIop.svg'
import workExp from '../../assets/images/workop.svg'
import proIcon from '../../assets/images/proIcon.svg'
import achiIcon from '../../assets/images/achiIcon.svg'
import geneSkill from '../../assets/images/genSkils.svg'
import languIcon from '../../assets/images/langIcon.svg'
import dirveIcon from '../../assets/images/drivIcon.svg'

import { showLoader, hideLoader } from '../../util/helper'
import { callSignedAPi } from "../../util/common";
import moment from "moment";
import { useSelector } from "react-redux";



const DigitalResumeFirst = () => {
    const { profileData } = useSelector(state => state);
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    console.log(userData)
    const { fullName, desiredDesignation, profilePicture, email, whatsappMobile, aadhaarMobile, gender, projectDetails, achievementDetails,
        birthDate, candidateKnownLanguages, bikeOwner, drivingLicense, carOwner, cvBioEncrypted, cvVision, cvFacebookLink, educationType, educationBranch, educationDegree,
        desiredSalary, specialization, educationDetails, experienceDetails,candidateEducationSort, yearsOfExperience, city, state, skills, candidateExperience } = userData || profileData;
    console.log(userData);
    const [profilePicURL, setProfilePicURL] = useState("");
    // let langSplit = language ? [language] : [];
    // if (language && language.includes(",")) {
    //     langSplit = language.split(",");
    // }
    const [isupdate, setIsUpdate] = useState(false)

    useEffect(() => {
        getSignedApi();
        if (profilePicURL === "") {

            setIsUpdate(true);
        }
    }, [isupdate])

    const getSignedApi = async () => {
        try {
            showLoader();
            let params = {
                id: profilePicture
            }
            const result = await callSignedAPi(params);
            if (result) {
                setProfilePicURL(result)
            }
            hideLoader();
        } catch (e) {
            setProfilePicURL('')
            hideLoader();
        }
    }

    const detectMob = () => {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    }
    const getExperience = () => {
        const string = candidateExperience.split(' ');
        const yearsString = string[0];
    
        if (yearsString.includes('-')) {
            // For cases like "1 - 2 Years," take the second number
            const yearsRange = yearsString.split('-');
            return yearsRange.length > 1 ? yearsRange[1] : yearsRange[0];
        } else if (yearsString.includes('+')) {
            // For cases like "10+ Years," remove the '+' sign
            return yearsString.replace('+', '');
        } else {
            // If it's just a number, return it
            return yearsString;
        }
    }
    const firstTwoExperiences = experienceDetails.slice(0, 2);
    const calculateNumAdditionalDivs = () => {
        let additionalDivs = 0;
        const projectCount = projectDetails?.length || 0;
        const achievementCount = achievementDetails?.length || 0;
        const totalWorkExperience = experienceDetails?.length || 0;
    
        if (candidateEducationSort < 5 && experienceDetails?.length === 0) {
            console.log(candidateEducationSort);
            additionalDivs += 8; // Add 4 divs when remaining work experience is 0 and education is greater than 4
        }

        if (candidateEducationSort < 5 && experienceDetails?.length === 1) {
            console.log(candidateEducationSort);
            additionalDivs += 6; // Add 4 divs when remaining work experience is 0 and education is greater than 4
        }
        if (candidateEducationSort > 4 && experienceDetails?.length === 1) {
            console.log(candidateEducationSort);
            additionalDivs += 4; // Add 4 divs when remaining work experience is 0 and education is greater than 4
        }
        if (candidateEducationSort > 4 && experienceDetails?.length === 0) {
            console.log(candidateEducationSort);
            additionalDivs += 8; // Add 4 divs when remaining work experience is 0 and education is greater than 4
        }
        if (candidateEducationSort > 4 && experienceDetails?.length === 2) {
            console.log(candidateEducationSort);
            additionalDivs += 2; // Add 4 divs when remaining work experience is 0 and education is greater than 4
        }
    
        return additionalDivs;
    };
     // Function to generate additional divs
     const generateAdditionalDivs = () => {
        const numAdditionalDivs = calculateNumAdditionalDivs();
        const additionalDivs = [];
    
        for (let i = 0; i < numAdditionalDivs; i++) {
            additionalDivs.push(
                <div key={`additionalDiv${i}`} className={styles.achiveWork}>
                    <div className={styles.detailContent}>
                        <div className={styles.timelineBlock}>
                            {/* Placeholder content */}
                        </div>
                    </div>
                </div>
            );
        }
    
        return additionalDivs;
    };
    
    return (
        <div className={styles.dashbardData} id="page1-content">
            <div className={styles.dashboardNav}>
                <div className={styles.resumeData}>
                    <div className={styles.resumeContent}>
                        <div className={styles.tableData}>
                            <div className={styles.profileData}>
                                <div className={styles.imageName}>
                                    <h3>{fullName}<span>{desiredDesignation}</span></h3>
                                    <img id="" alt="ProfileImg" src={profilePicURL} style={{ height: "80px", width: "80px" }} crossOrigin="anonymous" />
                                </div>
                                <div className={styles.employeeDetail}>
                                    <div className={styles.leftDetail}>
                                        <div className={styles.contectDetail}>
                                            <h3><img src={contectRound} alt="" />Contact</h3>
                                            <ul>
                                                <li><img src={emailIcon} alt="" /> {email}</li>
                                                <li><img src={mobileIcon} alt="" /> {whatsappMobile ? whatsappMobile : aadhaarMobile}</li>
                                                <li><img src={bothIcon} alt="" /> {gender}</li>
                                                <li><img src={dobIcon} alt="" /> {moment(birthDate).format("YYYY-MM-DD")}</li>
                                                <li><img src={locIcon} alt="" /> {city} ({state})</li>
                                                {cvFacebookLink && <li><img src={facIcon} alt="" /> {cvFacebookLink}</li>}
                                            </ul>
                                        </div>

                                        {(skills && skills.length !== 0) && <div className={styles.skillsUpdate}>
                                            <h3><img src={geneSkill} alt="" />general skills</h3>
                                            <ul>
                                                {skills.map((result) => {
                                                    return <li>{result}</li>
                                                })}
                                            </ul>
                                        </div>}

                                        {(candidateKnownLanguages && candidateKnownLanguages.length !== 0) && <div className={styles.langOption}>
                                            <h3><img src={languIcon} alt="" />Languages</h3>
                                            <ul>
                                                {candidateKnownLanguages.map((result) => {
                                                    return <li>{result}</li>
                                                })}
                                            </ul>
                                        </div>}

                                        {(drivingLicense || carOwner || bikeOwner) && <div className={styles.drivOption}>
                                            <h3><img src={dirveIcon} alt="" />Driving information</h3>
                                            <ul>
                                                {drivingLicense && <li>I have Driving License</li>}
                                                {carOwner && <li>I have a Car</li>}
                                                {bikeOwner && <li>I have a Bike/Scooty</li>}
                                            </ul>
                                        </div>}

                                    </div>
                                    <div className={styles.rightDetail}>
                                        {((cvBioEncrypted && cvBioEncrypted.length !== 0) || (cvVision && cvVision.length !== 0)) && <div className={styles.bioVision}>
                                            <h3><img src={bioVision} alt="" />Bio & Vision</h3>
                                            {cvBioEncrypted && <p>{cvBioEncrypted.toString().split(',').join(', ')}</p>}
                                            {cvVision && <p>{cvVision.toString().split(',').join(', ')}</p>}
                                        </div>}
                                        <div className={styles.desiJobs}>
                                            <h3><img src={desiJob} alt="" />Desired job</h3>
                                            <div className={styles.posiList}>
                                                <p>Position</p>
                                                <ul>
                                                    <li>{desiredDesignation}</li>
                                                </ul>
                                            </div>
                                            {(specialization && specialization.length !== 0) && <div className={styles.posiList}>
                                                <p>Specialization</p>
                                                <ul>
                                                    {specialization.map((result) => {
                                                        return <li>{result}</li>
                                                    })}
                                                </ul>
                                            </div>}
                                            {/* <div className={styles.posiList}>
                                                <p>Desired Salary<span>(By Month)</span> </p>
                                                <ul>
                                                    <li>₹ {desiredSalary}</li>
                                                </ul>
                                            </div> */}

                                        </div>
                                       
                                         <div className={styles.eduSec}>
                                            <h3><img src={eduIcon} alt="" />Education</h3>

                                            <div className={styles.posiList}>
                                                <p>Studied</p>
                                                <ul>
                                                    <li>{educationType}</li>
                                                </ul>
                                                {educationBranch && <p>Degree</p>}
                                                {educationBranch && <ul>
                                                    <li>{educationBranch}</li>
                                                </ul>}
                                                {educationDegree && <p>Branch</p>}
                                                {educationDegree && <ul>
                                                    <li>{educationDegree}</li>
                                                </ul>}
                                            </div>
                                        </div>
                                        
                                        {/* Work Experience, Projects, Achievements */}
                                        {((experienceDetails && experienceDetails.length !== 0) && yearsOfExperience != 0) ? (
                                            <div className={styles.workExp}>
                                                <h3><img src={workExp} alt="" />Work Experience ({getExperience()} Years)</h3>
                                                {firstTwoExperiences.map((result, index) => {
                                                    const { lastCompany, industry, lastPosition, lastSalary, fromMonth, toMonth } = result;
                                                    return (
                                                        <div className={styles.detailContent} key={index}>
                                                            <div className={styles.timelineBlock}>
                                                                <h1>{lastPosition}</h1>
                                                                <time>{fromMonth} - {toMonth}</time>
                                                                <p>{lastCompany}</p>
                                                                <p>{lastSalary}</p>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ) : (
                                            <div className={styles.workExp}>
                                                <h3><img src={workExp} alt="" />Work Experience (Fresher)</h3>
                                                
                                            </div>
                                        )}
                                                {/* Check if there are projects and work experience is not Fresher */}
                                                {(projectDetails && projectDetails.length !== 0 && yearsOfExperience == 0 ) && (
                                                <div className={styles.proWork}>
                                                <h3><img src={proIcon} alt="" />Projects</h3>
                                                <div className={styles.detailContent}>
                                                    <div className={styles.timelineBlock}>
                                                        <h1>{projectDetails[0].projectName}</h1>
                                                        <time>{projectDetails[0].startDate} - {projectDetails[0].endDate}</time>
                                                        <p>{projectDetails[0].description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            )}

                                            {/* Display achievements only in the absence of projects and if work experience is not Fresher */}
                                            {(achievementDetails && achievementDetails.length !== 0 && yearsOfExperience == 0) && (
                                                <div className={styles.achiveWork}>
                                                <h3><img src={achiIcon} alt="" />Achievement</h3>
                                                <div className={styles.detailContent}>
                                                    <div className={styles.timelineBlock}>
                                                        <h1>{achievementDetails[0].title}</h1>
                                                        <time>{achievementDetails[0].date}</time>
                                                        <p>{achievementDetails[0].description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            )}
                                              {generateAdditionalDivs()}  
                                    </div>
                                   
                                    <div className={styles.poweredBy}>
                                        <h3>CV Powered by <img src={logoImg} alt="" /></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DigitalResumeFirst;