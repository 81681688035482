import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useParams } from 'react-router-dom';
import ImgWatch from "../assets/images/watch.png";
import { getCookie } from '../util/helper';

import Login from '../components/Login/Login';
import Profiles from '../components/Profiles/Profiles';
import Education from '../components/Education/Education'
import DesiredJob from '../components/DesiredJob/DesiredJob';
import EmploymentStatus from '../components/EmploymentStatus/EmploymentStatus';
import EmploymentDetails from '../components/EmploymentDetails/EmploymentDetails';
import AddAchievements from '../components/Achievements/AddAchievements';
import Achievements from '../components/Achievements/Achievements';
import Project from '../components/Projects/Project';
import AddProject from '../components/Projects/AddProject';
import Aspiration from '../components/Aspiration/Aspiration';
import AspirationFill from '../components/Aspiration/AspirationFill';
import CandidateResume from '../components/CandidateResume/CandidateResume';
import EditResume from '../components/EditResume/EditResume';



const Private = ({ Component, setProgressCount, setShowProgressBar }) => {
    const token = getCookie("IsloggedIn");
    return token ? <Component setProgressCount={setProgressCount} setShowProgressBar={setShowProgressBar} /> : <Navigate to="/login" />
}

const RouterComponent = (props) => {
    const [progressCount, setProgressCount] = useState({});
    const [progressTime, setProgressTime] = useState('5');
    const [showProgressbar, setShowProgressBar] = useState(false)
    useEffect(() => {
        switch (progressCount) {
            case 15:
                setProgressTime("5")
                break;
            case 30:
                setProgressTime("4")
                break;
            case 45:
                setProgressTime("4")
                break;
            case 60:
                setProgressTime("3")
                break;
            case 75:
                setProgressTime("3")
                break;
            case 90:
                setProgressTime("2")
                break;
            case 100:
                setProgressTime("0")
                break;
            default:
                setProgressTime("")
                break;
        }

        //  if (!isupdate) {

        //  }
    }, [progressCount, showProgressbar])

    return (<div className={(window.location.pathname === "/digitalcv" && window.innerWidth > 400) ? "container" : "container postRel"}>
        {(showProgressbar && getCookie("IsFirstTime")) && <div className="row">
            <div className="col-md-12">
                <div className="form-outer">
                    {/* <div className="arrow_skip">
                        <a href="javascript:void(0)" onClick={() => history.back()}><img src={leftArrow} /></a>
                        <a href="javascript:void(0)" onClick={() => SkipNext()}><span>Skip</span></a>
                    </div> */}
                    <div className="headingProg">
                        <h3><img alt='' src={ImgWatch} /> {progressTime === '0' ? "" : progressTime + " min."}  {progressTime === '0' ? <span>Almost done</span> : <span>getting a job in your city </span>}</h3>
                        <div className="progress-bar" style={{ width: progressCount + "%" }}>
                            <div className="step">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>}
        <Router history={props.history}>
            <Routes>
                <Route path='/' element={<Navigate to="/login" />} />
                <Route path='/:lang' element={<Login {...props} />} />
                <Route index path='/login' element={<Login {...props} />} />
                <Route index path='/:lang/login' element={<Login {...props} />} />
                <Route path='/digitalcv' element={<CandidateResume {...props} setShowProgressBar={setShowProgressBar} />} />
                <Route path='/profiles' element={<Private Component={Profiles} setProgressCount={setProgressCount} setShowProgressBar={setShowProgressBar} />} />
                <Route path='/education' element={<Private Component={Education} setProgressCount={setProgressCount} setShowProgressBar={setShowProgressBar} />} />
                <Route path='/desiredjob' element={<Private Component={DesiredJob} setProgressCount={setProgressCount} setShowProgressBar={setShowProgressBar} />} />
                <Route path='/employmentStatus' element={<Private Component={EmploymentStatus} setProgressCount={setProgressCount} setShowProgressBar={setShowProgressBar} />} />
                <Route path='/updateEmployee' element={<Private Component={EmploymentDetails} setProgressCount={setProgressCount} setShowProgressBar={setShowProgressBar} />} />
                <Route path='/achievements' element={<Private Component={AddAchievements} setProgressCount={setProgressCount} setShowProgressBar={setShowProgressBar} />} />
                <Route path='/updateAchievement' element={<Private Component={Achievements} setProgressCount={setProgressCount} setShowProgressBar={setShowProgressBar} />} />
                <Route path='/projects' element={<Private Component={AddProject} setProgressCount={setProgressCount} setShowProgressBar={setShowProgressBar} />} />
                <Route path='/updateProject' element={<Private Component={Project} setProgressCount={setProgressCount} setShowProgressBar={setShowProgressBar} />} />
                <Route path='/aspiration' element={<Private Component={Aspiration} setProgressCount={setProgressCount} setShowProgressBar={setShowProgressBar} />} />
                <Route path='/aspirationFill' element={<Private Component={AspirationFill} setProgressCount={setProgressCount} setShowProgressBar={setShowProgressBar} />} />
                {/* <Route path='/dashboard' element={<Private Component={EditResume} setShowProgressBar={setShowProgressBar} />} /> */}
                {/* <Route path='/digitalResumeFirst' element={<Private Component={DigitalResumeFirst} />} />
                <Route path='/digitalResumeSecond' element={<Private Component={DigitalResumeSecond} />} /> */}
                <Route path='/resumeedit' element={<Private Component={EditResume} setShowProgressBar={setShowProgressBar} />} />

                {/* <Route path='/degree' element={<Degree />} />
                <Route path='/branchstream' element={<BranchandStream />} />
                <Route path='/desiredjob' element={<Private Component={DesiredJob} />} />
                <Route path='/desiredjobpos' element={<DesiredJobPos />} />
                <Route path='/jobcate' element={<JobCate />} />
                <Route path='/JobSpecailzation' element={<JobSpecailzation />} />
                <Route path='/addExp' element={<AddExp />} /> */}
            </Routes>
        </Router>


    </div>
    );
}

export default RouterComponent;