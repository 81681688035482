const baseURL = "https://api.jobsgaar.com/v1";
const externalURL = "https://apiexternal.jobsgaar.com/api/v1";
const sepapiURL = "https://sepapi1.jobsgaar.com/v1/";
const employerURL = `${baseURL}/employer`;
const commonURL = `${baseURL}/common`;
const otpURL = `${baseURL}/otp`;
const fileUploadURL = `${baseURL}/upload`;
const interviewURL = `${externalURL}/interview`;
const sepapiCommonURL = `${sepapiURL}/common`;
const candidateURL = `${baseURL}/candidate`;

export const endPoints = {
    employer: {
        getJobs: `${employerURL}/get/all/jobs/{status}`,
        getProfile: `${employerURL}/get/my/profile`,
        createJob: `${employerURL}/create/new/job`,
        viewJob: `${employerURL}/view/job/summary/{id}`,
        editJob: `${employerURL}/update/job/{jobId}`,
        createInterview: `${employerURL}/create/interview`,
        updateInterviewFeedback: `${employerURL}/update/interview/feedback`,
        viewCandidate: `${employerURL}/view/candidate`,
        createJobOffer: `${employerURL}/create/job/offer`,
        register: `${employerURL}/register`,
        onBoard: `${employerURL}/onboard/{id}`,
        updateProfile: `${employerURL}/update/profile`,
        deactivateAccount: `${employerURL}/deactivate`,
        getDashboard: `${employerURL}/dashboard/{id}`
    },
    common: {
        login: `${commonURL}/signIn`,
        getCandidateByStatus: `${commonURL}/job/status/list`,
        updateCandidateJobStatus: `${commonURL}/update/job/match/action`,
        cityListByState: `${commonURL}/get/statewise/city/list`,
        getExperienceList: `${commonURL}/get/experience/list`,
        getEducationList: `${commonURL}/get/education/list`,
        getPositionList: `${commonURL}/get/position/list`,
        getSpecializationList: `${commonURL}/get/specialization/list`,
        rejectJob: `${commonURL}/reject/job`,
        getAllInterview: `${commonURL}/view/all/interview`,
        rescheduleInterview: `${commonURL}/approve/reschedule/interview`,
        getIndustryList: `${commonURL}/get/industry/list`,
        updatePreferences: `${commonURL}/update/preferences`,
        fetchNewCareerTips: `${sepapiCommonURL}/fetchNewCareerTips`,
        updateCareerTips: `${sepapiCommonURL}/updateCareerTipsPerUser`,
        getSignedUrl: `${commonURL}/get/signedUrl`,
        updateJobStatus: `${commonURL}/update/job/status`,
        checkExistingCandidate: `${sepapiCommonURL}/checkExistingCandidate`,
        createJobMatch: `${commonURL}/create/new/jobMatch`,
        getProfilesCount: `${commonURL}/job/status/count`,
        getBioTags: `${commonURL}/view/biotags/list`,
        getVisionTages: `${commonURL}/view/visiontags/list`,
        getSkills: `${commonURL}/get/skill/list`,
        tempOnboard: `${commonURL}/update/tempOnboard`,
    },
    otp: {
        requestOtpforLogin: `${otpURL}/requestOtpForLogin`,
        verifyOtpForLogin: `${otpURL}/verifyOtpForLogin`,
        requestOtpforSignUp: `${otpURL}/requestOtpForSignUp`,
        verifyOtpForSignUp: `${otpURL}/verifyOtpForSignUp`,
        requestOtpEmailPhoneforPreference: `${otpURL}/update/emailOrMobile`,
        verifyOtpEmailPhoneforPreference: `${otpURL}/update/verifyOtpForMobileEmail`,
    },
    fileUpload: {
        upload: `${fileUploadURL}/file`
    },
    interview: {
        normalCall: `${interviewURL}/call/candidate`,
        quickCall: `${interviewURL}/quick/call/candidate`,
        normalCallPassThru: `${interviewURL}/call/passthru`,
        quickCallPassThru: `${interviewURL}/call/passthru/immediate/interview`
    },
    candidate: {
        register: `${candidateURL}/register`,
        onBoard: `${candidateURL}/onboard/{id}`,
        getProfile: `${candidateURL}/get/my/profile`,
        updateProfile: `${candidateURL}/update/profile`
    },
    subscription: {
        createEmployerSubscription: `${externalURL}/subscription/create/employer/subscription`,
        subscriptionTransactionEnd: `${externalURL}/subscription/transaction/end`
    }
}