// FixedPDFLayout.js
import React, { useEffect, useRef, useState } from "react";
import styles from "./FixedPDFLayout.module.scss";
import DigitalResumeFirst from "./DigitalResumeFirst";
import DigitalResumeSecond from "./DigitalResumeSecond";
import { jsPDF } from 'jspdf';
import { useDispatch, useSelector } from "react-redux";

const FixedPDFLayout = ({ data }) => {
    const { profileData } = useSelector(state => state);
    const userData = JSON.parse(localStorage.getItem('userData')) || profileData;

    const { experienceDetails, profilePicture, fullName, refreshToken, projectDetails, achievementDetails,
        cvBioEncrypted, cvVision, desiredDesignation, educationDetails, aadhaarMobile, whatsappMobile, yearsOfExperience } = userData;

    const [showResumeFirst, setShowResumeFirst] = useState(true);
    const [showResumeSecond, setShowResumeSecond] = useState(false);
    const [isupdate, setIsupdate] = useState(false);
    const [showSecondPage, setShowSecondPage] = useState(0);
    const [callOnce, setCallOnce] = useState(false);

    const pdfRef = useRef();
    const dispatch = useDispatch();
    const doc = new jsPDF('p', 'mm');
    const docMobile = new jsPDF('p', 'mm', [297, 210]);

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        
        if (!callOnce) {
            let IsSecondPage = showSecondPage;
            if ((cvBioEncrypted && cvBioEncrypted.length !== 0) || (cvVision && cvVision.length !== 0)) {
                IsSecondPage += 1;
            }
            if (desiredDesignation) {
                IsSecondPage += 1;
            }
            if (educationDetails && educationDetails.length !== 0 && educationDetails.length >= 1) {
                IsSecondPage += 1;
            }
            if (experienceDetails && experienceDetails.length > 2 && yearsOfExperience > 0) {
                IsSecondPage += 1;
            }
            if (achievementDetails && achievementDetails.length !== 0 && achievementDetails.length >= 1  && yearsOfExperience > "0") {
                IsSecondPage += 1;
            }
   
            if (projectDetails && projectDetails.length !== 0 && projectDetails.length >= 1 && yearsOfExperience > "0") {
                IsSecondPage += 1;
            }
            setShowSecondPage(IsSecondPage);
            setCallOnce(true)
        }
    
        
    }, [isupdate]);


    return (
        <div id="divToPrint" className={styles.newBook} ref={pdfRef}>
            <div style={{ display: showResumeFirst ? "block" : "none" }} id="resumeFirst">
                <DigitalResumeFirst />
            </div>
            {showSecondPage >= 4 && <div style={{ display: showResumeSecond ? "block" : "none" }} id="resumeSecond">
                <DigitalResumeSecond />
            </div>}
        </div>
    );
};

export default FixedPDFLayout;