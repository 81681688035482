import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Achieve.module.scss';
import moment from 'moment';
import { setAchieveMentDetails } from '../../store/reducer';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
// import HelpCall from '../HelpCall/HelpCall';
import cellHelp from '../../assets/images/needHelp.png';

const Achievements = (props) => {
    const { t } = useTranslation('common');
    const { setProgressCount, setShowProgressBar } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { achieveMentDetails, selectedAchieveIndex } = useSelector(state => state);
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    const [stateTitle, setStateTitle] = useState("");
    const [startDate, setstartDate] = useState("");
    const [stateDescription, setStateDescription] = useState("");
    const { title, description, date } = selectedAchieveIndex === "" ? {} : achieveMentDetails[parseInt(selectedAchieveIndex)];
    const [callOnce, setcallOnce] = useState(false)

    const totalProgress = 100;
    const totalFields = 3;
    const calculateProgress = Math.round(totalProgress / totalFields);
    let progressbarSize = 0;

    const achieveDateRef = useRef();

    useEffect(() => {
        if (!callOnce) {
            if (title) {
                progressbarSize = progressbarSize + calculateProgress;
                setStateTitle(title)
            }
            if (description) {
                progressbarSize = progressbarSize + calculateProgress;
                setStateDescription(description)
            }
            if (date) {
                progressbarSize = progressbarSize + calculateProgress;
                setstartDate(date)
            }
            setcallOnce(true)
        }
        setProgressCount(75);
        setShowProgressBar(true);
    }, [])

    const submitHandler = () => {
        if (title !== stateTitle || description !== stateDescription || date !== startDate) {
            if (stateTitle.trim() !== "" && stateDescription.trim() !== "" && startDate.trim() !== "") {
                const submitObj = {
                    title: stateTitle,
                    description: stateDescription,
                    date: startDate,
                    update: true
                }
                dispatch(setAchieveMentDetails(submitObj));
                navigate('/achievements')
            } else {
                toast.error("Please fill all the fields")
            }
        } else {
            navigate('/achievements')
        }
    }

    return (
        <div className="achiveAdd">
            <form onSubmit={e => e.preventDefault()}>
                <div className="page slide-page">
                    <div className={styles.TitleHead}>
                        <h3 dangerouslySetInnerHTML={{ __html: t('AddAchieve.title') }}></h3>
                    </div>

                    <div className={styles.formInputAre}>

                        <div className={styles.FormGroup}>
                            <label>{t('AddAchieve.achieveTitle')}</label>
                            <input className={styles.formControl} type="text" placeholder="Your achievement title" value={stateTitle} onChange={e => setStateTitle(e.target.value)} />
                        </div>
                        <div className={styles.FormGroup}>
                            <label>{t('AddAchieve.achieveDate')}</label>
                            <input className={styles.formControl} type="date" placeholder="Your achievement date" value={moment(startDate).format("YYYY-MM-DD")}
                                onChange={e => setstartDate(e.target.value)} ref={achieveDateRef} onClick={() => achieveDateRef.current.showPicker()} />
                        </div>
                        <div className={styles.FormGroup}>
                            <label>{t('AddAchieve.achieveDesc')}</label>
                            <textarea className={styles.formControl} type="text" placeholder="Your achievement’s specification" value={stateDescription} onChange={e => setStateDescription(e.target.value)}></textarea>

                        </div>
                    </div>


                    <div className={styles.saveNext} style={{ marginTop: "50px" }}>
                        <button style={{ width: "100%" }} className={styles.next} onClick={() => submitHandler()}>{t('Achievements.addAchieve')} </button>
                    </div>
                    <div className={styles.Back}>
                        <button className={styles.next} onClick={() => navigate('/achievements')}>{t('common.back')}</button>
                    </div>

                </div>
                <div className={styles.newHelp}>
                    <div className={styles.helpCall}>
                    <a href="tel:8595141564">
                        <img  src={cellHelp}/>
                    </a>
                    </div>
                </div>

            </form >
            
        </div >
    )
}

export default Achievements;