
import { useDispatch, useSelector } from "react-redux";
//import { selectedEmpDetails, setExperianceDetails } from "../../store/reducer";
import { useEffect, useRef, useState } from "react";
import React from 'react';
import styles from './Employment.module.scss'
import { getPositionList, getIndustryList } from '../../services/Api/candidate';
import { setExperianceDetails } from '../../store/reducer';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { customStyles } from "../../util/common";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import calendar from "../../assets/images/calendar.png";
import { IconButton, TextField } from "@mui/material";
import cellHelp from '../../assets/images/needHelp.png';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         marginTop: "0 !important"
//     },
//     removeElement: {
//         display: "none !important"
//     },
//     header: {
//         paddingBottom: "8px",
//         background: "white !important"
//     },
//     month: {
//         padding: "8px 0"
//     },
//     selectedMonth: {
//         background: "gray !important",
//         padding: "8px 0"
//     },
//     inputBox: {
//         // background: 'white !important',
//         border: "none"
//     }
// }));

const EmploymentDetails = (props) => {
    const { t } = useTranslation('common');
    const { setProgressCount, setShowProgressBar } = props;
    const { expDetails, selectedEmpDetails, profileData } = useSelector(state => state);
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    //const { fullName } = userData || profileData;
    const [selectedDetails, setSelectedDetails] = useState([]);
    const [positionData, setPositionData] = useState([]);
    const [businessData, setBusinessData] = useState([]);
    const [salaryData, setSalaryData] = useState([]);
    const [selectedJoinDate, setSelectedJoinDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState("");
    const [stillWorking, setStillWorking] = useState(false);
    const [selectedIndustry, setSelectedIndustry] = useState("");
    const [selectedPosition, setSelectedPosition] = useState("");
    const [salaryVal, setSalaryVal] = useState();
    const [selectedCompany, setSelectedComapny] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [nameError, setNameError] = useState("");
    const [industryError, setIndustryError] = useState("");
    const [positionError, setPositionError] = useState("")
    const [workStartError, setWorkStartError] = useState("");
    const [workEndError, setWorkEndError] = useState("");
    const [salaryError, setSalaryError] = useState("");
    const [callOnce, setCallOnce] = useState(false);
    const [showStillWork, setshowStillWork] = useState(true);

    const totalProgress = 100;
    const totalFields = 6;
    const calculateProgress = Math.round(totalProgress / totalFields);
    let progressbarSize = 0;
    const [localProgress, setlocalProgress] = useState(progressbarSize);
    const startMonthRef = useRef();
    const endMonthRef = useRef();

    useEffect(() => {
        getJobPosition();
        getIndustries();
        getSalaryData();

        if (selectedEmpDetails !== "" && !callOnce) {
            const { lastCompany, industry, lastPosition, fromMonth, toMonth, lastSalary, stillWork } = expDetails[parseInt(selectedEmpDetails)];
            if (lastCompany) {
                progressbarSize = progressbarSize + calculateProgress;
                setSelectedComapny(lastCompany);
            }
            if (industry) {
                progressbarSize = progressbarSize + calculateProgress;
                setSelectedIndustry(industry)
            }
            if (lastPosition) {
                progressbarSize = progressbarSize + calculateProgress;
                setSelectedPosition(lastPosition)
            }
            if (fromMonth) {
                progressbarSize = progressbarSize + calculateProgress;
                setSelectedJoinDate(fromMonth)
            }
            if (toMonth) {
                progressbarSize = progressbarSize + calculateProgress;
                //  setSelectedEndDate(toMonth)
            }
            if (fromMonth && toMonth && toMonth !== "still working") {
                setshowStillWork(false)
            } else {
                setshowStillWork(true)
            }
            if (lastSalary) {
                progressbarSize = progressbarSize + calculateProgress;
                setSalaryVal(lastSalary)
            }
            if (stillWork) {
                setStillWorking(stillWork)
            } else {
                setStillWorking(false)
                setSelectedEndDate(toMonth)
            }
            setSelectedDetails(expDetails[parseInt(selectedEmpDetails)]);
            setProgressCount(60);
            setShowProgressBar(true);
            setCallOnce(true)
        } else {
            setProgressCount(60);
            setShowProgressBar(true);
            setSelectedDetails([]);
        }

    }, [stillWorking])

    const detectMob = () => {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    }
    const getSalaryData = () => {
        let data = [];
        let startingSalary = 5000;
        for (let i = startingSalary; i <= 150000; i += 500) {
            let obj = {
                label: i,
                value: i
            }
            data.push(obj);
        }
        setSalaryData(data);

    }
    const getJobPosition = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getPositionList();
            setPositionData(
                data && data.length > 0 && data.map(({ position, positionId, positionUid }) => {
                    return {
                        label: position,
                        value: position,
                        positionUid
                    }
                })
            );
        } catch (error) {
        }
    }

    /**
   * API->https://api.jobsgaar.com/v1/common/get/industry/list
   * This method is used get list of industries/business type
   */
    const getIndustries = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getIndustryList();
            setBusinessData(
                data && data.length > 0 && data.map(({ industryName }) => {
                    return { label: industryName, value: industryName }
                }
                )
            );
        } catch (error) {
        }
    }

    const setJoiningDate = (e) => {
        setWorkStartError("")
        setSelectedJoinDate(e)
    }

    const validateErrors = () => {
        if (!selectedCompany) {
            return setNameError("Please enter your employer name")
        }
        if (!selectedIndustry) {
            return setIndustryError("Please select your industry")
        }
        if (!selectedPosition) {
            return setPositionError("Please select job position")
        }
        if (!selectedJoinDate) {
            return setWorkStartError("Please select joining date")
        }
        if (!selectedEndDate && !stillWorking) {
            return setWorkEndError("Please select your end date")
        }
        if (!salaryVal) {
            return setSalaryError("Please select your salary")
        }
        return true;
    }

    const submitHandler = () => {
        if (validateErrors()) {
            const submitObj = {
                lastCompany: selectedCompany,
                industry: selectedIndustry,
                lastPosition: selectedPosition,
                fromMonth: moment(selectedJoinDate).format('MMM-YYYY'),
                toMonth: stillWorking ? "still working" : moment(selectedEndDate).format('MMM-YYYY'),
                lastSalary: salaryVal,
                description: "",
                isAddOn: false,
                update: true,
                stillWork: stillWorking
            }
            dispatch(setExperianceDetails(submitObj))

            navigate('/employmentStatus')
        }
    }

    const companyHandler = (e) => {
        progressbarSize = progressbarSize + calculateProgress;
        setlocalProgress(progressbarSize);
        setProgressCount(progressbarSize)
        setNameError("")
        setSelectedComapny(e.target.value)
    }

    const industryHandler = (e) => {
        setSelectedIndustry(e.value);
        setIndustryError("");
        progressbarSize = localProgress + calculateProgress;
        setlocalProgress(progressbarSize);
        setProgressCount(progressbarSize)
    }

    const positionHanler = (e) => {
        setPositionError("");
        setSelectedPosition(e.value);
        progressbarSize = localProgress + calculateProgress;
        setlocalProgress(progressbarSize);
        setProgressCount(progressbarSize)
    }
    const dateStartHandler = (e) => {
        setWorkStartError("")
        setshowStillWork(true);
        // setSelectedEndDate("")
        setSelectedJoinDate(e);
        //  startMonthRef.current.setOpen(false);
    }

    const dateEndHandler = (e) => {
        setWorkEndError("");

        if (e) {
            setshowStillWork(false);
        } else {
            setshowStillWork(true);
        }
        setSelectedEndDate(e);
        //endMonthRef.current.setOpen(false);
        progressbarSize = localProgress + calculateProgress;
        setlocalProgress(progressbarSize);
        setProgressCount(progressbarSize);
    }

    const salaryHandler = (e) => {
        setSalaryVal(e.value);
        progressbarSize = localProgress + calculateProgress;
        setlocalProgress(progressbarSize);
        setProgressCount(progressbarSize);
    }

    const onChangeHandler = () => {
        setWorkEndError("");
        setStillWorking(!stillWorking)
    }
    return (
        <div>
            <form onSubmit={e => e.preventDefault()}>
                <div className="page slide-page">
                    <div className={styles.formInputAre}>
                        <div className={styles.TitleHead}>
                            <h3 dangerouslySetInnerHTML={{ __html: t('AddEmployment.title') }}></h3>
                        </div>
                        <div className={styles.FormGroup}>
                            <label>{t('AddEmployment.employerNamelbl')}</label>
                            <input className={styles.formControl} type="text" placeholder={t('AddEmployment.lastCompanyname')} aria-describedby="button-addon3" onChange={(e) => companyHandler(e)} value={selectedCompany} />
                            {nameError && <span className="errorMessage">{nameError}</span>}
                        </div>
                        <div className={styles.FormGroup}>
                            <label>{t('AddEmployment.industryLabel')}</label>
                            {/* <select className={styles.formControl} onChange={(e) => { industryHandler(e) }}>
                                <option value="">{t('AddEmployment.industrChoose')}</option>
                                {businessData.map((result, index) => {
                                    if (result.value === selectedDetails.industry) {
                                        return <option key={`business${index}`} selected value={result.value} >{result.label}</option>
                                    } else {
                                        return <option key={`business${index}`} value={result.value} >{result.label}</option>
                                    }
                                })}

                            </select> */}
                            <Select value={{ label: selectedIndustry ? selectedIndustry : t('AddEmployment.industrChoose'), value: selectedIndustry ? selectedIndustry : "" }}
                                styles={customStyles}
                                onChange={(e) => industryHandler(e)}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                options={businessData} />
                            {industryError && <span className="errorMessage">{industryError}</span>}
                        </div>
                        <div className={styles.FormGroup}>
                            <label>{t('AddEmployment.positionLabel')}</label>
                            {/* <select className={styles.formControl} onChange={e => { positionHanler(e) }}>
                                <option value="">{t('AddEmployment.positionChoose')}</option>
                                {positionData.map((result, index) => {
                                    if (result.label === selectedPosition) {
                                        return <option key={`position${index}`} style={{ backgroundColor: "#ff6600" }} selected value={result.label} >{result.label}</option>
                                    } else {
                                        return <option key={`position${index}`} value={result.label} >{result.label}</option>
                                    }
                                }
                                )}
                            </select> */}
                            <Select value={{ label: selectedPosition ? selectedPosition : t('AddEmployment.positionChoose'), value: selectedPosition ? selectedPosition : "" }}
                                styles={customStyles}
                                onChange={(e) => positionHanler(e)}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                options={positionData} />
                            {positionError && <span className="errorMessage">{positionError}</span>}
                        </div>
                        <div className={styles.genderWidth} style={{ width: stillWorking ? "100%" : "48%" }}>
                            <label style={{ display: "block", width: stillWorking ? "100%" : "48%" }}>{t('AddEmployment.experienceLabel')}</label>
                            <label htmlFor="dewey">{t('AddEmployment.joinMonth')}</label>
                            {/* <input className={styles.formControl} type="month" placeholder="Start Month" aria-describedby="button-addon3" onChange={(e) => dateStartHandler(e)} value={selectedJoinDate} ref={startMonthRef} onClick={() => startMonthRef.current.showPicker()} /> */}
                            {/* <DatePicker
                                className={styles.formControl}
                                placeholderText="Start Month"
                                value={selectedJoinDate}
                                selected={selectedJoinDate ? new Date(selectedJoinDate) : null}
                                onChange={(date) => dateStartHandler(date)}
                                dateFormat="MMM y"
                                showMonthYearPicker
                                portalId="root-portal"
                                // inline
                                ref={startMonthRef}
                                isClearable
                                clearButtonClassName={styles.clearbtn}
                            /> */}

                            <DatePicker
                                portalId="root-portal"
                                selected={selectedJoinDate ? new Date(selectedJoinDate) : null}
                                onChange={(date) => dateStartHandler(date)}
                                className={styles.formControl}
                                showMonthYearPicker
                                placeholderText="Start Month"
                                dateFormat="MMM y"
                                renderInput={(params) => (
                                    <div style={{ position: "relative", display: "inline-block" }} >
                                        <TextField {...params} />
                                    </div>
                                )}
                            />

                            <img src={calendar} onClick={() => startMonthRef.current.setOpen(true)} style={{ width: stillWorking ? "3%" : "6%", marginLeft: "-11px" }} />

                        </div>
                        <div className={styles.dobN} style={{ display: stillWorking ? "none" : "" }}>
                            <label htmlFor="dewey">{t('AddEmployment.LastWorking')}</label>
                            {/* <input className={styles.formControl} type="month" placeholder="End Month" aria-describedby="button-addon3" onChange={(e) => { dateEndHandler(e) }} value={selectedEndDate} ref={endMonthRef} onClick={() => endMonthRef.current.showPicker()} /> */}
                            {/* <DatePicker
                                portalId="root-portal"
                                className={styles.formControl}
                                placeholderText="End Month"
                                value={selectedEndDate}
                                selected={selectedEndDate ? new Date(selectedEndDate) : null}
                                onChange={(date) => dateEndHandler(date)}
                                dateFormat="MMM y"
                                showMonthYearPicker
                                // inline
                                ref={endMonthRef}
                                isClearable
                                clearButtonClassName={styles.clearbtn}
                            /> */}
                            <DatePicker
                                portalId="root-portal"
                                selected={selectedEndDate ? new Date(selectedEndDate) : null}
                                onChange={(date) => dateEndHandler(date)}
                                className={styles.formControl}
                                showMonthYearPicker
                                placeholderText="End Month"
                                dateFormat="MMM y"
                                renderInput={(params) => (
                                    <div style={{ position: "relative", display: "inline-block" }} >
                                        <TextField {...params} />
                                    </div>
                                )}
                            />
                            <img src={calendar} onClick={() => startMonthRef.current.setOpen(true)} style={{ width: stillWorking ? "3%" : "6%", marginLeft: "-11px" }} />

                        </div>
                        {workStartError && <span className="errorMessage">{workStartError}</span>}
                        {workEndError && <span className="errorMessage" style={{ marginLeft: "50%" }}>{workEndError}</span>}
                        <br></br>
                        {showStillWork && <div className={styles.dobN} style={{ marginLeft: "0px" }}>
                            <div className={styles.FormGroup}>
                                <input className={styles.radioButton} type="radio" checked={stillWorking} id="dewey" name="drone" value="dewey" onClick={() => onChangeHandler()} />
                                <label htmlFor="dewey">{t('AddEmployment.stillWorking')}</label>
                            </div>
                        </div>}
                        <div className={styles.FormGroup}>
                            <label>{t('AddEmployment.salary')}</label>
                            {/* <select className={styles.formControl} onChange={(e) => salaryHandler(e)}>
                                <option value="">{t('AddEmployment.salaryChoose')}</option>
                                {salaryData.map(({ value }, index) => {
                                    if (parseInt(salaryVal) === value) {
                                        return <option key={`salary${index}`} style={{ backgroundColor: "#ff6600" }} selected value={value}>₹ {value}</option>
                                    } else {
                                        return <option key={`salary${index}`} value={value}>₹ {value}</option>
                                    }
                                })}
                            </select> */}
                            <Select value={{ label: salaryVal ? salaryVal : t('AddEmployment.salaryChoose'), value: salaryVal ? salaryVal : "" }}
                                styles={customStyles}
                                onChange={(e) => salaryHandler(e)}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                options={salaryData} />
                            {salaryError && <span className="errorMessage">{salaryError}</span>}
                        </div>
                        <div className={styles.saveNext} style={{ marginTop: "10px" }}>
                            <button className={styles.next} onClick={() => submitHandler()}>{t('AddEmployment.addExperience')} </button>
                        </div>
                        <div className={styles.Back}>
                            <button className={styles.next} onClick={() => navigate('/employmentStatus')}>{t('common.back')}</button>
                        </div>
                    </div>
                </div>
                <div className={styles.newHelp}>
                    <div className={styles.helpCall}>
                    <a href="tel:8595141564">
                        <img  src={cellHelp}/>
                    </a>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default EmploymentDetails;